import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { TableComponent } from "../../components";
import { actions } from "./actions";
import { actions as departmentActions } from "../home/actions";
import {
  EMPTY_LIST_KYC,
  END_POINT_EXPORT,
  getCustomerTypeOptions,
  getDepartmentOptions,
  GET_DOCUMENT_TYPE_OPTIONS,
  PARAMS_FILTER_DEFAULT,
} from "../../common/constant";
import SearchComponent from "./search";
import { columns, renderDataKycRequest, renderStatusKYC, useStyles } from "./style";
import _ from "lodash";
import ModalKycRequestDetail from "./ModalKycRequest/Detail";
import { changeTable, emptyFilter, dateNowNoSpecial, formatDate } from "../../utils";
import { withRouter } from "react-router";
import { AuthenticatedLink } from "../../components/AuthenticatedLink";

function KYCRequestManagement(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const kycRequests = useSelector((state) => state.kyc.requests);
  const departments = useSelector((state) => state.customer.departments);
  const kycDetail = useSelector((state) => state.kyc.kycDetail);

  const [listDepartment, setListDepartment] = useState([]);
  const [visibelModalEditDetail, setVisibelModalEditDetail] = useState(false);
  const [requestKycDetail, setRequestKycDetail] = useState({});
  const [countInit, setCountInit] = useState(0);

  const [filters, setFilters] = useState(PARAMS_FILTER_DEFAULT);

  //close or open modal kyc detail
  const toggleModalEditKycDetail = useCallback(() => {
    setVisibelModalEditDetail(!visibelModalEditDetail);
  }, [visibelModalEditDetail]);

  //when-click view kyc detail
  const viewKYCDetails = useCallback(
    (requestDetail) => () => {
      dispatch(actions.getDetailKYCStart(requestDetail.id));
    },
    [dispatch]
  );

  useEffect(() => {
    const { requestId } = props.match.params;
    if (requestId) {
      dispatch(actions.getDetailKYCStart(requestId));
    }
  }, []);

  //when confirm success re-render kyc-detail
  useEffect(() => {
    if (kycDetail?.id) {
      setRequestKycDetail(renderRequestDetailView(kycDetail));
      if (!visibelModalEditDetail) {
        setVisibelModalEditDetail(true);
      }
    } else {
      setVisibelModalEditDetail(false);
    }
  }, [kycDetail]);

  //common render object DOM for kyc detail
  const renderRequestDetailView = (requestDetail) => ({
    ...requestDetail,
    customer_types: requestDetail?.customer_types.map((item) => {
      return (
        <p className="value-arr" key={item}>
          {_.find(getCustomerTypeOptions(t), { key: item?.toUpperCase() }).value}
        </p>
      );
    }),
    department_names: requestDetail?.department_names.map((item) => (
      <p className="value-arr" key={item}>
        {getDepartmentOptions(item?.toUpperCase(), t)}
      </p>
    )),
    created_at: formatDate(requestDetail?.created_at),
    idoc_type: _.find(GET_DOCUMENT_TYPE_OPTIONS(t), { key: requestDetail?.idoc_type.toUpperCase() })?.value,
    status_dom: renderStatusKYC(classes, requestDetail?.status, t),
    reviewed_at: formatDate(requestDetail?.reviewed_at),
  });

  //init departerment when complete component
  useEffect(() => {
    dispatch(departmentActions.getDepartmentCompanyStart());
  }, [dispatch]);

  useEffect(() => {
    if (departments?.length) {
      const newData = departments.map((item) => ({
        key: item.id + "",
        value: item.name,
        code: item.code,
      }));
      setListDepartment(newData);
    }
  }, [departments]);

  //listener change filter
  useEffect(() => {
    setCountInit(countInit + 1);
    const { order, orderBy, page, pageSize } = filters;
    if (emptyFilter(filters, EMPTY_LIST_KYC) && countInit) {
      dispatch(actions.changeListEmptyStart());
    } else if (countInit > 1) {
      dispatch(
        actions.getListRequestKYCStart({
          ...filters,
          page: page + 1,
          page_size: pageSize,
          order_type: order,
          order_by: orderBy,
        })
      );
    }
  }, [filters]);

  //when click confirm detail kyc request
  const confirmKycDetailRequest = useCallback(
    (values) => {
      dispatch(actions.putConfirmKYCStart(values, requestKycDetail.id));
    },
    [dispatch, requestKycDetail]
  );

  const renderButtonExport = useMemo(() => {
    return (
      <AuthenticatedLink
        url={END_POINT_EXPORT.KYC_EDIT_REQUEST}
        filename={`${t("file_name.kyc_edit_request")}_${dateNowNoSpecial()}.csv`}
        children={
          <IconButton className={classes.buttonSave}>
            <SaveAltIcon />
          </IconButton>
        }
      />
    );
  }, [t]);

  return (
    <Box className={`${classes.root} list-kyc`}>
      <Grid container>
        <Grid className="layout__container-filter-right" item xs={9} md={9} lg={10}>
          <Box py={4} className={classes.boxTop}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant="h5">{t("list_kyc.title")}</Typography>
              </Grid>
              <Grid item md={6} className={classes.right}>
                {renderButtonExport}
              </Grid>
            </Grid>
          </Box>
          <TableComponent
            headCells={columns(t)}
            rows={renderDataKycRequest(kycRequests?.data, filters, classes, t, viewKYCDetails)}
            currentPage={filters.page}
            rowPerPage={filters.pageSize}
            order={filters.order}
            orderBy={filters.orderBy}
            rowLength={kycRequests.total}
            handleFilters={changeTable(setFilters, filters).handleFilters}
            onChangePage={changeTable(setFilters, filters).handleChangePage}
            onChangeRowsPerPage={changeTable(setFilters, filters).handleChangeRowsPerPage}
          />
        </Grid>
        <Grid item xs={3} md={3} lg={2}>
          <Paper elevation={0} className={`${classes.searchContainer} search-container`}>
            <SearchComponent
              onSearch={changeTable(setFilters, filters).handleSearch}
              listDepartments={listDepartment}
            />
          </Paper>
        </Grid>
      </Grid>
      <ModalKycRequestDetail
        toggleModal={toggleModalEditKycDetail}
        callbackConfirm={confirmKycDetailRequest}
        visible={visibelModalEditDetail}
        requestKycDetail={requestKycDetail}
        classes={classes}
        t={t}
      />
    </Box>
  );
}
export default withRouter(KYCRequestManagement);
