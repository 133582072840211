import moment from "moment";
import { isArray, isEmpty, intersection } from "lodash-es";
import { ROUTE_HIDE_LAYOUT } from "../Routes";
import countries from "./countries";
import { ACCEPT_IMAGE_FORMATS, GENDER_TYPE, IMAGE_SIZE } from "../common/constant";
import notify from "../components/Toast";
import i18n from "../i18n/i18n";

export function formatDate(date, type = "") {
  return moment(date).format(type ? type : "YYYY/MM/DD");
}

export function dateNowNoSpecial() {
  return moment(new Date()).format("YYYYMMDDHHmmss");
}

export const getDurationSecondFromNow = (timeUnix) => {
  const now = moment(new Date()); //now
  const b = moment(timeUnix);
  return now.diff(b, "seconds");
};

export const changeTable = (setFilters, filters) => ({
  handleChangePage: (page) => {
    setFilters({ ...filters, page });
  },
  handleChangeRowsPerPage: (pageSize) => {
    setFilters({
      ...filters,
      page: 0,
      pageSize,
    });
  },
  handleFilters: (data) => {
    setFilters({
      ...filters,
      ...data,
    });
  },
  handleSearch: (data) => {
    setFilters({
      ...filters,
      ...data,
    });
  },
});

export const compareShallowObject = (object1, object2) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export const checkLocationHideLayout = () => {
  return !ROUTE_HIDE_LAYOUT.every((path) => window.location.pathname.indexOf(path) === -1);
};

export function formatCountries(nationality = "") {
  return countries.find((item) => item.key === nationality)?.value;
}

export function formatGender(type) {
  return GENDER_TYPE[type];
}

export const isStatusVerifying = (field) => {
  return field === "VERIFYING";
};

export const isStatusApproved = (field) => {
  return field === "APPROVED";
};

export const isStatusRequesting = (field) => {
  return field === "REQUESTING";
};

export const isStatusRejected = (field) => {
  return field === "REJECTED";
};

export const checkSearchTermEmpty = (searchTerm, customers) => {
  for (const key in searchTerm) {
    if (isArray(searchTerm[key]) && isEmpty(searchTerm[key])) {
      return [];
    }
  }

  return customers;
};

export const validateFile = (file) => {
  if (!file) {
    return false;
  }
  if (!ACCEPT_IMAGE_FORMATS.includes(file.type)) {
    notify.error(i18n.t("message.MSG_18"));
    return false;
  }
  if (file.size > IMAGE_SIZE) {
    notify.error(i18n.t("message.MSG_19"));
    return false;
  }
  return true;
};

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function startTimer(countTimer, duration, display, callback = () => {}) {
  var timer = duration,
    minutes,
    seconds;
  countTimer = setInterval(function () {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    display.textContent = minutes + ":" + seconds;

    if (--timer < 0) {
      callback();
      window.clearInterval(countTimer);
    }
  }, 1000);
  return countTimer;
}

export const randomString = (length) => {
  var result = [];
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join("");
};

export const camelCaseToUnderscore = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const redirectToProfile = (history) => () => {
  return history.push("/profile");
};

export const isRoleEmployee = (role) => {
  return role === "EMPLOYEE";
};

export const isFile = (input) => "File" in window && input instanceof File;

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes, role) {
  return routes.filter(({ permission }) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return intersection(permission, [role]).length;
  });
}

export function formatPIC(code, userCode, t = { i18n }) {
  return code === userCode && t("common.text_is_me");
}

export function isCurrentUser(code, userCode) {
  return code === userCode;
}

export const getSuffixCSVFile = () => {
  return moment().format("YYYYMMDDHHmmss");
};

export function formatANumber(d) {
  return d < 10 ? "0" + d.toString() : d.toString();
}

export const isNumeric = (str) => {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

export const emptyFilter = (filter, arrParams = []) => {
  return !arrParams.every((key) => {
    return !isEmpty(filter[key]) || filter[key]?.length;
  });
};

export const setTitlePage = (title = "Glocaly Admin") => {
  document.title = `${title} | Glocaly Admin`;
};

export const renderForTypeDoc = (idoc_type, renderByType = []) => {
  return renderByType === "all" ? true : renderByType.includes(idoc_type);
};

export const isPresentative = (customer) => {
  return (
    customer?.business_card_pic &&
    customer?.business_card_company &&
    customer?.business_card_company_addr &&
    customer?.business_card_phone
  );
};

export const isPresentativeByCard = (customer) => {
  return customer?.legal_entity_company || customer?.business_card_pic;
};

export const isPresentativeByIdocType = (customer) => {
  return customer?.add_doc_types?.length;
};
