export const ActionTypes = {
  GET_USER_DETAIL_START: "GET_USER_DETAIL_START",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_ERROR: "GET_USER_DETAIL_ERROR",
  UPDATE_PROFILE_START: "UPDATE_PROFILE_START",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",
  CREATE_VERIFY_CODE_START: "CREATE_VERIFY_CODE_START",
  CREATE_VERIFY_CODE_SUCCESS: "CREATE_VERIFY_CODE_SUCCESS",
  CREATE_VERIFY_CODE_ERROR: "CREATE_VERIFY_CODE_ERROR",
  CONFIRM_VERIFY_CODE_START: "CONFIRM_VERIFY_CODE_START",
  CONFIRM_VERIFY_CODE_SUCCESS: "CONFIRM_VERIFY_CODE_SUCCESS",
  CONFIRM_VERIFY_CODE_ERROR: "CONFIRM_VERIFY_CODE_ERROR",
  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  RESET_FOLLOW_CHANGE_PASSWORD: "RESET_FOLLOW_CHANGE_PASSWORD"
};

export const actions = {
  resetFollowChangePassword: function () {
    return {
      type: ActionTypes.RESET_FOLLOW_CHANGE_PASSWORD,
    };
  },
  changePasswordStart: function (body) {
    return {
      type: ActionTypes.CHANGE_PASSWORD_START,
      body,
    };
  },
  changePasswordSuccess: function (data) {
    return {
      type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
    };
  },
  changePasswordError: function (error) {
    return {
      type: ActionTypes.CHANGE_PASSWORD_ERROR,
      payload: error,
    };
  },
  confirmVerifyCodeStart: function (body) {
    return {
      type: ActionTypes.CONFIRM_VERIFY_CODE_START,
      body,
    };
  },
  confirmVerifyCodeSuccess: function (data) {
    return {
      type: ActionTypes.CONFIRM_VERIFY_CODE_SUCCESS,
      payload: data,
    };
  },
  confirmVerifyCodeError: function (error) {
    return {
      type: ActionTypes.CONFIRM_VERIFY_CODE_ERROR,
      payload: error,
    };
  },
  createVerifyCodeStart: function (body) {
    return {
      type: ActionTypes.CREATE_VERIFY_CODE_START,
      body,
    };
  },
  createVerifyCodeSuccess: function (data) {
    return {
      type: ActionTypes.CREATE_VERIFY_CODE_SUCCESS,
      payload: data,
    };
  },
  createVerifyCodeError: function (error) {
    return {
      type: ActionTypes.CREATE_VERIFY_CODE_ERROR,
      payload: error,
    };
  },
  getUserDetailStart: function (params) {
    return {
      type: ActionTypes.GET_USER_DETAIL_START,
      params,
    };
  },
  getUserDetailSuccess: function (data) {
    return {
      type: ActionTypes.GET_USER_DETAIL_SUCCESS,
      payload: data,
    };
  },
  getUserDetailError: function (error) {
    return {
      type: ActionTypes.GET_USER_DETAIL_ERROR,
      payload: error,
    };
  },
  updateProfileStart: function (body) {
    return {
      type: ActionTypes.UPDATE_PROFILE_START,
      body,
    };
  },
  updateProfileSuccess: function (data) {
    return {
      type: ActionTypes.UPDATE_PROFILE_SUCCESS,
      payload: data,
    };
  },
  updateProfileError: function (error) {
    return {
      type: ActionTypes.UPDATE_PROFILE_ERROR,
      payload: error,
    };
  },
};
