import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";

import { EMPLOYEE_ROLES, getDepartmentOptions, MAX_LENGTH_INPUT } from "../../../common/constant";
import { DatePickerComponent, InputComponent, SelectComponent } from "../../../components";
import { isRoleEmployee } from "../../../utils";
import { actions as customerActions } from "../../home/actions";
import { actions } from "../actions";
import WarningModal from "./WarningModal";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    maxWidth: 900,
  },
  boxAction: {
    display: "flex",

    "& > .button": {
      minWidth: 150,
    },

    "& > .button:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
}));

function EditFormComponent(props) {
  const { initialValues, backToEmployeeDetails, onSubmit } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isVisibleWarningModal, setIsVisibleWarningModal] = useState(false);

  const listEmployeeRole = EMPLOYEE_ROLES(t);

  const departments = useSelector((state) => state.customer.departments);
  const responseRequest = useSelector((state) => state.employee.type);

  const [listDepartment, setListDepartment] = useState([]);

  const validationSchema = Yup.object({}).shape({
    fullName: Yup.string()
      .trim()
      .required(t("message.MSG_4", { fieldName: t("employee.text_full_name") })),
    email: Yup.string()
      .trim()
      .email(t("message.MSG_2"))
      .required(t("message.MSG_4", { fieldName: t("employee.label_email") })),
    role: Yup.string().required(t("message.MSG_4", { fieldName: t("employee.label_role") })),
    department: Yup.string().when("role", {
      is: "EMPLOYEE",
      then: Yup.string().required(t("message.MSG_4", { fieldName: t("employee.label_department") })),
    }),
  });

  const handleDatePickerChange = (setFieldValue) => (date) => {
    setFieldValue("dateOfBirth", moment(date).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (departments?.length) {
      const newData = departments.map((item) => ({
        key: item.id + "",
        value: getDepartmentOptions(item.code, t),
        code: item.code,
      }));
      setListDepartment(newData);
    } else {
      dispatch(customerActions.getDepartmentCompanyStart());
    }
  }, [departments, t]);

  const onChangeRole = (setFieldValue) => (e) => {
    const { value, name } = e.target;

    setFieldValue("department", "");
    setFieldValue(name, value);
  };

  const toggleWarningModal = useCallback(() => {
    setIsVisibleWarningModal((prevState) => !prevState);
  }, [isVisibleWarningModal]);

  useEffect(() => {
    if (responseRequest?.message === "PIC_RESTRICT_ERROR") {
      toggleWarningModal();
    }
  }, [dispatch, responseRequest]);

  useEffect(() => {
    if (!isVisibleWarningModal) {
      dispatch(actions.resetTypeOfAction());
    }
  }, [isVisibleWarningModal]);

  const memoizedModalWarning = useMemo(
    () => (
      <WarningModal
        isVisible={isVisibleWarningModal}
        toggleModal={toggleWarningModal}
        data={responseRequest}
        employeeName={initialValues.fullName}
      />
    ),
    [isVisibleWarningModal]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize validationSchema={validationSchema}>
      {({ values, setFieldValue }) => (
        <Form className={classes.form}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Field
                name="employeeID"
                label={t("employee.label_employee_id")}
                fullWidth
                disabled
                component={InputComponent}
              />
            </Grid>
            <Grid item md={6}>
              <Field
                name="createdBy"
                label={t("employee.text_created_by")}
                fullWidth
                disabled
                component={InputComponent}
              />
            </Grid>
            <Grid item md={6}>
              <Field
                name="fullName"
                label={t("employee.text_full_name")}
                placeholder={t("employee.placeholder_full_name")}
                maxLength={MAX_LENGTH_INPUT}
                fullWidth
                component={InputComponent}
              />
            </Grid>
            <Grid item md={6}>
              <Field
                name="dateOfBirth"
                label={t("employee.text_birthday")}
                placeholder="YYYY/MM/DD"
                value={values.dateOfBirth}
                maxDate={new Date()}
                format="yyyy/MM/dd"
                onChange={handleDatePickerChange(setFieldValue)}
                fullWidth
                component={DatePickerComponent}
              />
            </Grid>
            <Grid item md={6}>
              <Field
                name="createdOn"
                label={t("employee.text_created_on")}
                format="yyyy/MM/dd"
                fullWidth
                disabled
                component={DatePickerComponent}
              />
            </Grid>
            <Grid item md={6}>
              <Field
                name="email"
                label={t("employee.label_email")}
                placeholder={t("employee.placeholder_email")}
                maxLength={MAX_LENGTH_INPUT}
                fullWidth
                disabled
                component={InputComponent}
              />
            </Grid>
            <Grid item md={6}>
              <Field
                name="department"
                label={t("customer_management.label_department")}
                labelField={t("customer_management.label_department")}
                options={listDepartment}
                value={values.department}
                disabled={!isRoleEmployee(values.role)}
                fullFieldWidth
                component={SelectComponent}
              />
            </Grid>
            <Grid item md={6}>
              <Field
                name="role"
                label={t("employee.placeholder_role")}
                labelField={t("employee.placeholder_role")}
                options={listEmployeeRole}
                value={values.role}
                onChange={onChangeRole(setFieldValue)}
                fullFieldWidth
                component={SelectComponent}
              />
            </Grid>
          </Grid>

          <Box mt={4} className={classes.boxAction}>
            <Button className="button button button-transparent-border" onClick={backToEmployeeDetails}>
              {t("component.button_cancel")}
            </Button>
            <Button type="submit" className="button button-gradient">
              {t("component.button_save")}
            </Button>
          </Box>
          {memoizedModalWarning}
        </Form>
      )}
    </Formik>
  );
}

export default EditFormComponent;
