import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import HeaderCommon from "../../components/Header";
import DrawerComponent from "../../components/Drawer";
import { getToken } from "../../services/api";
import { privateRoutes, publicRoutes, simpleRoutes } from "../../Routes";
import { BREAKPOINT, BREAKPOINT_SMALL } from "../../common/constant";
import { checkLocationHideLayout, getAllowedRoutes } from "../../utils";
import { actions as profileActions } from "../profile/actions";
import { useTranslation } from "react-i18next";
import PrivateRoute from "../../components/PrivateRoute";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
  },
}));

const useStyles2 = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.primary.main,
  },
}));

PublicLayout.propTypes = {
  routeProps: PropTypes.shape({
    location: PropTypes.object,
  }),
};

PublicLayout.defaultProps = {
  routeProps: {
    location: {},
  },
};

PrivateLayout.propTypes = {
  routeProps: PropTypes.shape({
    location: PropTypes.object,
  }),
};

PrivateLayout.defaultProps = {
  routeProps: {
    location: {},
  },
};

export function PublicLayout({ routeProps, ...props }) {
  const classes = useStyles2();

  const token = useSelector((state) => state.authentication.currentUser?.access_token);
  const isAuthentication = !!token;

  if (token) {
    getToken(token);
  }

  return !isAuthentication ? (
    <div className={classes.root}>
      <HeaderCommon isAuthentication={token} />
      <main className={classes.content}>
        <Switch>
          {publicRoutes.map((route) => (
            <Route path={route.path} key={route.path} exact={route.exact} render={() => <route.component />} />
          ))}
        </Switch>
      </main>
    </div>
  ) : (
    <Redirect
      to={{
        pathname: "/customers",
        state: { referer: routeProps.location },
      }}
    />
  );
}

function PrivateLayout({ routeProps }) {
  const hideLayout = checkLocationHideLayout();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isExpandedDrawer, setIsExpandedDrawer] = useState(true);
  const [enableExpandedDrawer, setEnableExpandedDrawer] = useState(false);
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const { t } = useTranslation();

  const auth = useSelector((state) => state.authentication.currentUser, shallowEqual);

  const isAuthenticated = auth.access_token;
  const role = auth.role;

  const handleOpeningDrawer = () => {
    setIsExpandedDrawer((state) => !state);
  };

  const handleResize = () => {
    if (window.innerWidth <= BREAKPOINT_SMALL) {
      setEnableExpandedDrawer(false);
    } else {
      setEnableExpandedDrawer(true);
    }
    if (window.innerWidth < BREAKPOINT) {
      setIsExpandedDrawer(false);
    } else {
      setIsExpandedDrawer(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(profileActions.getUserDetailStart());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setAllowedRoutes(getAllowedRoutes(privateRoutes(t), role));
  }, [role, t]);

  return (
    <div className={classes.root}>
      <HeaderCommon
        isExpandedDrawer={isExpandedDrawer}
        toggleDrawer={enableExpandedDrawer ? handleOpeningDrawer : () => {}}
        isAuthenticated={isAuthenticated}
      />
      <DrawerComponent
        isExpandedDrawer={isExpandedDrawer}
        toggleDrawer={handleOpeningDrawer}
        allowedRoutes={allowedRoutes}
      />
      <main className={classes.content}>
        <Switch>
          {privateRoutes(t).map((route) => {
            const { path, component, children, title, permission, ...rest } = route;

            return <PrivateRoute key={path} path={path} roles={permission} component={component} {...rest} />;
          })}
        </Switch>
      </main>
    </div>
  );
}
export default React.memo(withRouter(PrivateLayout));

export function SimpleLayout() {
  return (
    <div>
      <Switch>
        {simpleRoutes.map(({ path, exact, component }) => (
          <PrivateRoute path={path} key={path} exact={exact} component={component} />
        ))}
      </Switch>
    </div>
  );
}
