import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getListKYCHistoryAPI, getDetailKYCHistoryAPI } from '../../../services/KYCRequest';
import { RESPONSE_STATUS } from '../../../common/constant';
import { actions as appActions } from '../../../pages/layout/actions';

function* getListKYCHistory({ params, customerCode }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getListKYCHistoryAPI(params, customerCode);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListKYCHistorySuccess(response.data));
    } else {
      yield put(actions.getListKYCHistoryError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getListKYCHistoryError(e));
    yield put(appActions.hideLoading());
  }
}

function* getDetailKYCHistory({ requestId }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getDetailKYCHistoryAPI(requestId);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getDetailKYCHistorySuccess(response.data));
    } else {
      yield put(actions.getDetailKYCHistoryError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getDetailKYCHistoryError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchKYCHistory() {
  yield takeLatest(ActionTypes.GET_LIST_KYC_HISTORY_START, getListKYCHistory);
  yield takeLatest(ActionTypes.GET_DETAIL_KYC_HISTORY_START, getDetailKYCHistory);
}
