import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getDepartmentOptions, GET_DOCUMENT_TYPE_OPTIONS } from "../../common/constant";
import clsx from "clsx";
import { formatDate } from "../../utils";
import { themConfigModalOverflow, themeConfigButtonSave, themeStatusConfig } from "../../utils/themeConfig";
import _ from "lodash";
import { IdocRepresentative } from "../home/IdocRepresentative";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: `-${theme.spacing(2)}px`,
  },
  boxTop: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  right: {
    textAlign: "right",
  },
  buttonSend: {
    padding: 10,
    marginRight: theme.spacing(2),
  },
  ...themeConfigButtonSave(theme),
  ...themeStatusConfig(theme),
  link: {
    color: theme.overrides.colorAzureRadiance,

    "&:hover": {
      cursor: "pointer",
    },
  },
  searchContainer: {
    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.05)",
    padding: theme.spacing(5, 3),
  },
  infoKycModalWrap: {
    marginTop: "36px",
  },
  dividerInfo: {
    width: "268px",
    margin: theme.spacing(4, "auto"),
  },
  buttonKycDetail: {
    width: "274px",
    margin: theme.spacing(2, "auto"),
  },
  descriptionConfirmModalDetail: {
    margin: theme.spacing(3),
  },
  labelRejectReason: {
    color: theme.overrides.colorDustyGray,
  },
  boxModalKycRequest: {
    ...themConfigModalOverflow(),
  },
}));

//config colum
export const columns = (t) => {
  return [
    { dataIndex: "no", minWidth: 35, align: "center", label: t("customer_management.text_no"), isSorter: false },
    { dataIndex: "code", minWidth: 120, align: "left", label: t("list_kyc.label_request_id"), isSorter: true },
    { dataIndex: "created_at", minWidth: 140, align: "left", label: t("list_kyc.label_request_date"), isSorter: true },
    {
      dataIndex: "customer_name",
      ellipsis: true,
      width: 250,
      align: "left",
      label: t("list_kyc.label_customer_name"),
      isSorter: true,
    },
    { dataIndex: "department_names", align: "center", label: t("list_kyc.label_department"), isSorter: false },
    {
      dataIndex: "idoc_type",
      align: "center",
      label: t("list_kyc.label_identity_document"),
      isSorter: false,
    },
    { dataIndex: "status", align: "center", label: t("list_kyc.label_request_status"), isSorter: false },
    { dataIndex: "action", minWidth: 100, align: "center", label: t("list_kyc.label_action"), isSorter: false },
  ];
};

//render data to table
export const renderDataKycRequest = (listKyc, filters, classes, t, callbackRequestKyc) => {
  return listKyc?.map((item, index) => {
    const idocType = _.find(GET_DOCUMENT_TYPE_OPTIONS(t), { key: item?.idoc_type })?.value;
    const { page, pageSize } = filters;
    return {
      no: index + 1 + page * pageSize,
      code: item.code,
      created_at: formatDate(item.created_at),
      customer_name: item.customer_name,
      department_names: (
        <>
          {item.department_names.map((item, index) => (
            <div key={index}>{getDepartmentOptions(item.toUpperCase(), t)}</div>
          ))}
        </>
      ),
      idoc_type: <IdocRepresentative idocText={idocType} customer={item} />,
      status: renderStatusKYC(classes, item?.status, t),
      action: (
        <Link className={classes.link} to="#" onClick={callbackRequestKyc(item)}>
          {t("customer_management.button_view_detail")}
        </Link>
      ),
    };
  });
};

//render kyc status DOM
export const renderStatusKYC = (classes, status, t) => {
  let value = status === "REQUESTING" ? "PENDING" : status;
  return (
    value && (
      <span
        className={clsx(classes.status, {
          [classes.statusVerify]: status === "VERIFYING",
          [classes.statusApproved]: status === "APPROVED",
          [classes.statusRejected]: status === "REJECTED",
          [classes.statusRequesting]: status === "REQUESTING",
        })}
      >
        {t(`search.text_${value?.toLowerCase()}`)}
      </span>
    )
  );
};
