export default {
  field: {
    id: "customer_code",
    searchText: "keyword",
    customerName: "customer_name",
    departmentName: "department_names",
    departmentID: "department_ids",
    customerType: "customer_types",
    pic: "pic_name",
    picID: "pic_ids",
    phoneNumber: "phone",
    document: "idoc_type",
    documents: "idoc_types",
    status: "kyc_status",
    addDocTypes: "add_doc_types",
  },
};
