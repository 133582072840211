import { ActionTypes } from "./actions";

const DEFAULT_STATE = {
  loading: false,
  notifications: {
    data: [],
  },
  type: "",
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.GET_LIST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };

    case ActionTypes.READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: "",
      };
    default:
      return state;
  }
};
