import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.796 20.326a.398.398 0 01-.03-.207c.01-.055-.026-.102-.108-.137-.082-.036-.122-.064-.122-.084 0-.02-.06-.036-.177-.046l-.176-.015H4.408c-.673 0-1.25-.24-1.73-.72a2.358 2.358 0 01-.719-1.73V6.613c0-.673.24-1.25.72-1.73.479-.479 1.056-.719 1.73-.719h4.897a.462.462 0 00.314-.1.508.508 0 00.153-.298A2.576 2.576 0 009.81 3a7.045 7.045 0 01-.015-.306.471.471 0 00-.146-.345.471.471 0 00-.344-.145H4.408c-1.214 0-2.252.431-3.115 1.294C.431 4.36 0 5.398 0 6.612v10.776c0 1.214.431 2.252 1.293 3.114.863.863 1.9 1.294 3.115 1.294h4.898a.465.465 0 00.314-.1.509.509 0 00.153-.298 2.578 2.578 0 00.038-.765 6.877 6.877 0 01-.015-.306z"
        fill="#fff"
      />
      <path
        d="M23.71 11.311l-8.327-8.326a.942.942 0 00-.69-.291.943.943 0 00-.688.29.942.942 0 00-.29.69v4.408H6.856a.942.942 0 00-.689.29.942.942 0 00-.29.69v5.877c0 .265.096.495.29.689a.943.943 0 00.69.29h6.856v4.409a.94.94 0 00.291.688.942.942 0 00.689.291.941.941 0 00.689-.29l8.326-8.327A.942.942 0 0024 12a.942.942 0 00-.29-.689z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
