import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Typography, Backdrop, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > div": {
      outline: "none",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 4, 3),
  },
  fade: {
    "&:focus": {
      outline: "none",
    },
  },
  header: {
    lineHeight: `${theme.spacing(4)}px`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.overrides.colorBlack,
    color: theme.overrides.colorWhite,
    padding: theme.spacing(0, 4),
  },
  title: {
    fontSize: 14,
    lineHeight: `${theme.spacing(2)}px`,
  },
  closeButton: {
    color: theme.overrides.colorWhite,
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

SpringModal.propTypes = {
  children: PropTypes.element.isRequired,
  isOpeningModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  headerModal: PropTypes.object,
};

export default function SpringModal({
  children,
  isOpeningModal,
  toggleModal,
  className,
  classNamePaper,
  headerModal,
  ...props
}) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={`${classes.modal} ${className}`}
        open={isOpeningModal}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        {...props}
      >
        <Fade in={isOpeningModal} className={`${classes.fade} spring-modal`}>
          <>
            {!!headerModal && (
              <div className={classes.header}>
                <Typography variant="h4" component="h4" className={classes.title}>
                  {headerModal.title}
                </Typography>
                {!!headerModal.closeable && (
                  <IconButton onClick={toggleModal} className={classes.closeButton}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            )}
            <div className={`${classes.paper} ${classNamePaper}`}>{children}</div>
          </>
        </Fade>
      </Modal>
    </div>
  );
}

const useStylesSimple = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > div": {
      outline: "none",
    },
  },
  boxModal: {
    padding: theme.spacing(6),
    background: "#ffffff",
    position: "relative",
  },
  title: {
    textAlign: "center",
  },
  btnCloseBottom: {
    textAlign: "center",
    marginTop: theme.spacing(4),
    color: "#AE7D1F",
    cursor: "pointer",
    width: "max-content",
    fontWeight: 500,
    margin: "auto",
    "&:hover": {
      opacity: 0.7,
    },
  },
  btnCloseTop: {
    position: "absolute",
    top: "-43px",
    right: "-9px",
    fontSize: "41px",
    color: "#ffffff",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
}));

export function SimpleModal({
  isOpeningModal,
  toggleModal,
  children,
  title = "",
  closeBottom = false,
  className = "",
  closeHeader = false,
  width = 500,
  boxClass = "",
}) {
  const classes = useStylesSimple();
  const { t } = useTranslation();
  return (
    <Modal open={isOpeningModal} onClose={toggleModal} className={`${classes.modal} ${className}`}>
      <Box className={`${classes.boxModal} ${boxClass}`} style={{ width: `${width}px` }}>
        {title && (
          <Typography variant="h6" component="h6" className={classes.title}>
            {title}
          </Typography>
        )}
        {closeHeader && (
          <IconButton onClick={toggleModal} className={classes.btnCloseTop}>
            <CloseIcon />
          </IconButton>
        )}
        {children}
        {closeBottom && (
          <div onClick={toggleModal} className={classes.btnCloseBottom}>
            {t("common.text_close")}
          </div>
        )}
      </Box>
    </Modal>
  );
}
