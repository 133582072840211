import { Box, Button, Typography } from "@material-ui/core";
import { Field, Formik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { SimpleModal } from "../../../components/Modal";
import * as Yup from "yup";
import { isNumeric, randomString, startTimer } from "../../../utils";
import OTPInput from "react-otp-input";
import LogoComponent from "../../../resources/images/Logo";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import clsx from "clsx";

function ModalVerifySecurity({
  toggleModal,
  visible,
  t,
  classes,
  resetVerifyCount,
  request_id,
  otpTime = 119,
  onResetVerifyCode,
  callbackSubmitVerify,
}) {
  const [isCountTime, setIsCountTime] = useState(false);
  const [countVerify, setCountVerify] = useState(false);
  let stringIdCount = `time-counter-${randomString(10)}`;
  const classTextResend = clsx(`${classes.resendWrap} center-margin-block mt-16 mb-24`, {
    "disable-time-count": isCountTime,
  });
  const initialValues = {
    code: "",
    request_id,
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .min(6, t("message.MSG_7"))
      .required(t("message.MSG_4", { fieldName: "OTP" })),
  });

  const onSubmitEdit = (values) => {
    callbackSubmitVerify(values);
  };

  const handleChangeOTP = (setFieldValue) => (otp) => {
    if(isNumeric(otp)){
      setFieldValue("code", otp);
    }
  };

  useEffect(() => {
    const timeCounterElement = document.querySelector(`#${stringIdCount}`);
    if (visible) {
      let countTimer = 0;
      if (isCountTime && timeCounterElement || (timeCounterElement && resetVerifyCount > 1 && resetVerifyCount !== countVerify)) {
        startTimer(countTimer, Math.floor(otpTime), timeCounterElement, turnOffCountTime);
      }
      if(!timeCounterElement){
        turnOnCountTime();
      }
      return () => {
        clearInterval(countTimer);
      };
    }
  }, [isCountTime, visible, resetVerifyCount]);

  useEffect(()=>{
    setCountVerify(resetVerifyCount);
  },[resetVerifyCount]);

  const turnOnCountTime = () => {
    setIsCountTime(true);
  };

  const turnOffCountTime = () => {
    setIsCountTime(false);
  };

  const onReset = () => {
    if (!isCountTime) {
      onResetVerifyCode();
      setIsCountTime(true);
    }
  };

  return (
    <SimpleModal
      toggleModal={toggleModal}
      width={448}
      closeBottom={false}
      boxClass={classes.modalSetting}
      isOpeningModal={visible}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitEdit}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, values, isValid }) => (
          <Box className={classes.boxFormEditProfile}>
            <LogoComponent className="center-margin-block" />
            <Typography variant="h5" className="center-block mt-46 mb-16">
              {t("profile.title_security_verify")}
            </Typography>
            <Field
              name="code"
              numInputs={6}
              onChange={handleChangeOTP(setFieldValue)}
              value={values.code}
              shouldAutoFocus
              isInputNum
              containerStyle="otp-number"
              inputStyle="input-number"
              component={OTPInput}
            />
            <Typography variant="h3" id={stringIdCount} className="time-number mt-16">
              --:--
            </Typography>
            <div onClick={onReset} className={classTextResend}>
              <SyncRoundedIcon />
              <Typography variant="h3" className={`${classes.textResend} center-block`}>
                {t("profile.text_resend")}
              </Typography>
            </div>
            <div className={classes.btnConfirmWrap}>
              <Button
                className={`button button-transparent-border ${classes.btnCancelConfirm}`}
                fullWidth
                onClick={toggleModal}
              >
                {t("component.btn_back")}
              </Button>
              <Button
                disabled={!isValid || !values.code}
                type="submit"
                className={`button button-gradient ${classes.btnNextConfirm}`}
                onClick={handleSubmit}
                fullWidth
              >
                {t("component.btn_continue")}
              </Button>
            </div>
          </Box>
        )}
      </Formik>
    </SimpleModal>
  );
}

export default memo(ModalVerifySecurity);
