import { ActionTypes } from "./actions";

const DEFAULT_STATE = {
  customers: {
    data: [],
  },
  departments: [],
  type: "",
  customer: {},
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_KYC_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      };

    case ActionTypes.GET_DEPARTMENT_COMPANY_SUCCESS:
      return {
        ...state,
        departments: action.payload,
      };

    case ActionTypes.CREATE_KYC_REQUEST_SUCCESS:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.GET_CUSTOMER_INFORMATION_SUCCESS:
      return {
        ...state,
        customer: action.payload,
      };

    case ActionTypes.UPDATE_KYC_STATUS_SUCCESS:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.UPDATE_CUSTOMER_INFORMATION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.RESEND_REQUEST_KYC_SUCCESS:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: "",
      };
    default:
      return state;
  }
};
