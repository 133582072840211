import { put, takeLatest } from "redux-saga/effects";
import { actions, ActionTypes } from "./actions";
import { RESPONSE_STATUS } from "../../common/constant";
import { actions as appActions } from "../../pages/layout/actions";
import { getCompanyDetailAPI, updateCompanyAPI } from "../../services/SettingRequest";

function* getCompanyDetail({}) {
  try {
    yield put(appActions.showLoading());
    const response = yield getCompanyDetailAPI();
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getCompanyDetailSuccess(response.data));
    } else {
      yield put(actions.getCompanyDetailError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getCompanyDetailError(e));
    yield put(appActions.hideLoading());
  }
}

function* updateCompanySetting({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield updateCompanyAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.updateCompanySettingSuccess(response.data));
    } else {
      yield put(actions.updateCompanySettingError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.updateCompanySettingError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchSetting() {
  yield takeLatest(ActionTypes.GET_COMPANY_DETAIL_START, getCompanyDetail);
  yield takeLatest(ActionTypes.UPDATE_COMPANY_SETTING_START, updateCompanySetting);
}
