import React from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { themConfigModalOverflow, themeConfigButtonSave, themeConfigLink } from "../../../utils/themeConfig";
import {
  END_POINT_EXPORT,
  getCustomerTypeOptions,
  getDepartmentOptions,
  getLanguageOptions,
  PROFILE_TEXT_CHANGE,
} from "../../../common/constant";
import _ from "lodash";
import { AuthenticatedLink } from "../../../components/AuthenticatedLink";

export const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: "right",
  },
  ...themeConfigLink(theme),
  ...themeConfigButtonSave(theme, true),
  buttonSend: {
    padding: 10,
    marginRight: theme.spacing(2),
  },
  buttonCloseModalDetail: {
    margin: "auto",
    marginTop: theme.spacing(6),
    width: "274px",
  },
  boxModalKycHistory: {
    padding: theme.spacing(4),
    ...themConfigModalOverflow(),
  },
}));

//config colum
export const columns = (t, classes, customerCode) => {
  return [
    { dataIndex: "no", align: "center", label: t("customer_management.text_no"), isSorter: false },
    { dataIndex: "employee_code", align: "left", label: t("employee.label_employee_id"), isSorter: false },
    { dataIndex: "employee_name", align: "left", label: t("profile.label_edit_by"), isSorter: false },
    { dataIndex: "updated_date", align: "left", label: t("list_kyc_history.label_edited_date"), isSorter: true },
    { dataIndex: "content", width: 500, align: "center", label: t("profile.label_content"), isSorter: false },
    {
      align: "center",
      dataIndex: "action",
      label: (
        <AuthenticatedLink
          url={END_POINT_EXPORT.PROFILE_HISTORY(customerCode)}
          filename={`${t("file_name.profile_edit_history", { customerCode })}.csv`}
          children={
            <IconButton className={classes.buttonSave}>
              <SaveAltIcon />
              <Typography variant="button">{t("component.button_export_data").toUpperCase()}</Typography>
            </IconButton>
          }
        />
      ),
      isSorter: false,
    },
  ];
};

const renderTitleFullChange = (changeLogData, t) => {
  let text = "";
  changeLogData.forEach((item, index) => {
    text += `${index ? ", " : ""}${renderContentChangeLogs(item, t)}`;
  });
  return text.trim();
};

//render data to table
export const renderDataProfileHistory = (listProfileHistory, filters, classes, t, callbackViewDetail) => {
  return listProfileHistory?.map((item, index) => {
    const { page, pageSize } = filters;
    return {
      no: index + 1 + page * pageSize,
      employee_code: item.employee_code,
      employee_name: item.employee_name,
      updated_date: formatDate(item.updated_date),
      content: item.change_logs?.length ? (
        <p title={renderTitleFullChange(item.change_logs, t)} className="col-overflow">{`${renderContentChangeLogs(
          item.change_logs[0],
          t
        )} ${item.change_logs.length > 1 ? `...` : ""}`}</p>
      ) : (
        "---"
      ),
      action: (
        <Link className={classes.link} to="#" onClick={callbackViewDetail(item)}>
          {t("customer_management.button_view_detail")}
        </Link>
      ),
    };
  });
};

export const getValueType = (options, key) => {
  return _.find(options, { key }).value;
};

export const renderChangeArray = (array, options = []) => {
  return array
    .map((item) => (options?.length ? getValueType(options, item) : item))
    .toString()
    .replace(",", ", ");
};

export const renderContentChangeLogs = (changeItem, t) => {
  let value = "";
  switch (changeItem.key) {
    case "phone":
      value = changeItem.new_version_value;
      break;
    case "department_names":
      value = renderChangeArray(
        changeItem.new_version_value.map((item) => getDepartmentOptions(item.toUpperCase(), t))
      );
      break;
    case "customer_types":
      value = renderChangeArray(changeItem.new_version_value, getCustomerTypeOptions(t));
      break;
    case "pic":
      value = changeItem.new_version_value;
      break;
    case "lang":
      value = getValueType(getLanguageOptions(t), changeItem.new_version_value);
    default:
      break;
  }
  return t(PROFILE_TEXT_CHANGE[changeItem.key], { value });
};
