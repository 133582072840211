import { Box, Button, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { memo } from "react";
import { SimpleModal } from "../../../components/Modal";
import * as Yup from "yup";
import { InputPasswordComponent } from "../../../components";

function ModalChangePassword({ toggleModal, visible, t, classes, callbackSubmitChangePassword, request_id }) {
  const initialValues = {
    new_password: "",
    new_password_confirm: "",
    old_password: "",
    request_id,
  };

  const validationSchema = Yup.object().shape({
    old_password: Yup.string()
      .required(t("message.MSG_4", { fieldName: t("profile.label_current_pw") })),
    new_password: Yup.string()
      .min(8, t("message.MSG_8"))
      .max(16, t("message.MSG_8"))
      .required(t("message.MSG_4", { fieldName: t("profile.label_new_pw") }))
      .matches(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/, t("message.MSG_8")),
    new_password_confirm: Yup.string()
    .required(t("message.MSG_4", { fieldName: t("profile.label_confirm_new_pw") }))
    .oneOf([Yup.ref("new_password"), null], t("message.MSG_9")),
  });

  const onSubmitChangePassword = (values) => {
    callbackSubmitChangePassword(values);
  };

  return (
    <SimpleModal
      toggleModal={toggleModal}
      width={448}
      closeBottom={false}
      boxClass={classes.modalSetting}
      isOpeningModal={visible}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitChangePassword}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, values, isValid }) => (
          <Box className={classes.boxFormChangPassword}>
            <Typography variant="h5" className="center-block mb-16">
              {t("profile.title_modal_change_password")}
            </Typography>
            <Form className="mt-30 mb-12 full-width">
              <Field
                label={t("profile.label_current_pw")}
                maxLength={16}
                placeholder={t("profile.place_enter_current_pw")}
                name="old_password"
                component={InputPasswordComponent}
              />
            </Form>
            <Form className="mt-30 mb-12 full-width">
              <Field
                label={t("profile.label_new_pw")}
                maxLength={16}
                placeholder={t("profile.text_enter_new_pw")}
                name="new_password"
                component={InputPasswordComponent}
              />
            </Form>
            <Form className="mt-30 mb-46 full-width">
              <Field
                label={t("profile.label_confirm_new_pw")}
                maxLength={16}
                placeholder={t("profile.text_enter_new_pw")}
                name="new_password_confirm"
                component={InputPasswordComponent}
              />
            </Form>
            <div className={classes.btnConfirmWrap}>
              <Button
                className={`button button-transparent-border ${classes.btnCancelConfirm}`}
                fullWidth
                onClick={toggleModal}
              >
                {t("component.btn_back")}
              </Button>
              <Button
                type="submit"
                className={`button button-gradient ${classes.btnNextConfirm}`}
                onClick={handleSubmit}
                fullWidth
              >
                {t("component.button_save")}
              </Button>
            </div>
          </Box>
        )}
      </Formik>
    </SimpleModal>
  );
}

export default memo(ModalChangePassword);
