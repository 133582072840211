import { ActionTypes } from "./actions";

const DEFAULT_STATE = {
  listData: {
    data: [],
  },
  dataChange: null,
  countEditProfile: 0,
};

export default (state = DEFAULT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.UPDATE_CUSTOMER_INFORMATION_SUCCESS:
      return {
        ...state,
        countEditProfile: state.countEditProfile + 1,
      };
    case ActionTypes.GET_LIST_PROFILE_HISTORY_SUCCESS:
      return {
        ...state,
        listData: payload,
      };
    default:
      return state;
  }
};
