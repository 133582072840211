import _ from "lodash";
import { validateStatus } from "../utils/validateStatus";
import { HTTP_RESPONSE } from "../common/constant";
import { Toast } from "../components";
import { actions as authenticationActions } from "../pages/login/actions";
import { store } from "../redux/configStore";
import i18n from "../i18n/i18n";

const axios = require("axios");

export const HEADERS = {
  // "Authorization": "Basic " + base64.encode(Config.AUTH_USER_NAME + ":" + Config.AUTH_PASSWORD),
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
};

const HEADERS_MULTIPLE_PART = {
  ...HEADERS,
  "Content-Type": "multipart/form-data; boundary=something",
  Accept: "application/json",
};

export const getToken = (token) => {
  HEADERS["Authorization"] = `Bearer ${token}`;
  HEADERS_MULTIPLE_PART["Authorization"] = `Bearer ${token}`;
};

export const getFullUrl = (url) => {
  if (!url.startsWith("/")) {
    url = "/" + url;
  }
  return `${process.env.REACT_APP_API_URL}` + url;
};

const resetToLogin = () => {
  const promiseList = [];
  promiseList.push(store.dispatch(authenticationActions.userLogout()));
};

const throttledResetToLogin = _.throttle(resetToLogin, 500, {
  leading: false,
  trailing: true,
});

const checkErrorStatus = (response, showToast = true) => {
  if (response?.success === false && showToast) {
    const errorCode = response?.error?.code.split("_")[1];
    const message = i18n.t(`message.MSG_${errorCode}`);
    Toast.error(message || response?.error?.message);
  }
  return response;
};

const checkErrorNetwork = (err) => {
  if (err?.toJSON() && err.toJSON().message === "Network Error") {
    return Toast.error("Connection Error!");
  }
  return err;
};

const api = {
  post: (endpoint, params) => {
    return axios.default
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },

  postMultiplePart: (endpoint, params) => {
    return axios.default
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS_MULTIPLE_PART,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },

  get: (endpoint, params = {}) => {
    return axios.default
      .get(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },

  put: (
    endpoint,
    params,
    options = {
      showToast: true,
    }
  ) => {
    return axios.default
      .put(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          const { showToast } = options;
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data, showToast);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },

  delete: (endpoint, params) => {
    return axios.default
      .delete(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },
};

export { api };
