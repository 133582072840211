import { Box, Button, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { memo } from "react";
import * as Yup from "yup";
import LogoComponent from "../../../resources/images/Logo";
import { InputComponent } from "../../../components";

function ConfirmEmail({ visible, toggle, t, classes, callbackSubmitEmail }) {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t("message.MSG_2"))
      .required(t("message.MSG_4", { fieldName: t("login.text_email") })),
  });

  const onSubmitEmail = (values) => {
    callbackSubmitEmail(values);
  };

  return (
    visible && (
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmitEmail}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, values, isValid }) => (
          <Box className={classes.boxFormEditProfile}>
            <Form className="form">
              <LogoComponent className="center-margin-block" />
              <Typography variant="h5" className="center-block mt-46 mb-16">
                {t("login.title_forgot_password")}
              </Typography>
              <Field
                placeholder={t("login.text_enter_email")}
                maxLength={254}
                label={t("login.text_email")}
                name="email"
                className="full-width mt-56"
                component={InputComponent}
              />
              <div className={classes.btnConfirmWrap}>
                <Button
                  className={`button button-transparent-border ${classes.btnCancelConfirm}`}
                  fullWidth
                  onClick={toggle}
                >
                  {t("component.btn_back")}
                </Button>
                <Button
                  type="submit"
                  className={`button button-gradient ${classes.btnNextConfirm}`}
                  onClick={handleSubmit}
                  fullWidth
                >
                  {t("component.btn_continue")}
                </Button>
              </div>
            </Form>
          </Box>
        )}
      </Formik>
    )
  );
}

export default memo(ConfirmEmail);
