import { Box, Button, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { memo, useEffect, useState } from "react";
import * as Yup from "yup";
import { randomString, startTimer } from "../../../utils";
import OTPInput from "react-otp-input";
import LogoComponent from "../../../resources/images/Logo";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import clsx from "clsx";

function ConfirmCode({
  toggle,
  visible,
  t,
  classes,
  resetVerifyCount,
  request_id,
  otpTime = 119,
  onResetVerifyCode,
  callbackSubmitVerify,
}) {
  const [isCountTime, setIsCountTime] = useState(false);
  let stringIdCount = `time-counter-${randomString(10)}`;
  const classTextResend = clsx(`${classes.resendWrap} center-margin-block mt-16 mb-1`, {
    "disable-time-count": isCountTime,
  });
  const initialValues = {
    code: "",
    request_id,
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .min(6, t("message.MSG_7"))
      .required(t("message.MSG_4", { fieldName: "OTP" })),
  });

  const onSubmitEdit = (values) => {
    callbackSubmitVerify(values);
  };

  const handleChangeOTP = (setFieldValue) => (otp) => {
    setFieldValue("code", otp);
  };

  useEffect(() => {
    turnOnCountTime();
  }, [resetVerifyCount]);

  useEffect(() => {
    const timeCounterElement = document.querySelector(`#${stringIdCount}`);
    let countTimer = 0;
    if (visible) {
      if (isCountTime && timeCounterElement) {
        startTimer(countTimer, Math.floor(otpTime), timeCounterElement, turnOffCountTime);
      }
      return () => {
        clearInterval(countTimer);
      };
    }
  }, [isCountTime, visible, otpTime]);

  const turnOnCountTime = () => {
    setIsCountTime(true);
  };

  const turnOffCountTime = () => {
    setIsCountTime(false);
  };

  const onReset = () => {
    if (!isCountTime) {
      onResetVerifyCode();
    }
  };

  return (
    visible && (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitEdit}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, values, isValid }) => (
          <Box className={classes.boxFormEditProfile}>
            <Form className="form">
              <LogoComponent className="center-margin-block" />
              <Typography variant="h5" className="center-block mt-46 mb-16">
                {t("profile.title_security_verify")}
              </Typography>
              <Typography variant="body2" className="center-block mt-16 color-des-verify">
                {t("profile.text_check_verify")}
              </Typography>
              <Field
                name="code"
                numInputs={6}
                onChange={handleChangeOTP(setFieldValue)}
                value={values.code}
                shouldAutoFocus
                isInputNum
                containerStyle="otp-number"
                inputStyle="input-number"
                component={OTPInput}
              />
              <Typography variant="h3" id={stringIdCount} className="time-number mt-16">
                --:--
              </Typography>
              <div onClick={onReset} className={classTextResend}>
                <SyncRoundedIcon />
                <Typography variant="h3" className={`${classes.textResend} center-block`}>
                  {t("profile.text_resend")}
                </Typography>
              </div>
              <div className={classes.btnConfirmWrap}>
                <Button
                  className={`button button-transparent-border ${classes.btnCancelConfirm}`}
                  fullWidth
                  onClick={toggle}
                >
                  {t("component.btn_back")}
                </Button>
                <Button
                  disabled={!isValid || !values.code}
                  type="submit"
                  className={`button button-gradient ${classes.btnNextConfirm}`}
                  onClick={handleSubmit}
                  fullWidth
                >
                  {t("component.btn_continue")}
                </Button>
              </div>
            </Form>
          </Box>
        )}
      </Formik>
    )
  );
}

export default memo(ConfirmCode);
