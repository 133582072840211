import React, { useState } from "react";
import { TextField, makeStyles } from "@material-ui/core";

import PropTypes from "prop-types";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import clsx from "clsx";
import { IconButton, InputAdornment } from "@material-ui/core";

InputPasswordComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  props: PropTypes.object,
};

InputPasswordComponent.defaultProps = {
  field: null,
  form: {
    errors: null,
    touched: null,
  },
  props: {},
};

const useStylesInput = makeStyles({
  input: {
    "& textarea": {
      wordBreak: "break-word",
    },
  },
});

function InputPasswordComponent({
  field,
  form: { errors, touched },
  message,
  inputValue,
  maxLength,
  inputProps,
  ...props
}) {
  const classes = useStylesInput();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...field}
      {...props}
      error={Boolean(message) || (touched[field.name] && Boolean(errors[field.name]))}
      helperText={message || (touched[field.name] && errors[field.name])}
      variant="outlined"
      className={classes.input}
      type={clsx({ password: !showPassword, text: showPassword })}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        ...inputProps,
        maxLength: maxLength,
      }}
    />
  );
}

export default InputPasswordComponent;
