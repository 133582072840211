import * as React from 'react';

function LogoComponent(props) {
  return (
    <svg width={66} height={65} viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M65.421 32.503V65H0V0h65.421L54.627 10.725H10.788v43.55h43.84V32.503H65.42z"
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={32.708}
          y1={5.186}
          x2={32.708}
          y2={61.599}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BB9043" />
          <stop offset={0.469} stopColor="#E1C98F" />
          <stop offset={1} stopColor="#AE7D20" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LogoComponent;
