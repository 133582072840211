import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { CHART_EMPTY, KYC_INFO_MODAL, KYC_INFO_REQUEST_MODAL, KYC_REQUEST_MODE } from "../../../common/constant";
import { InputComponent } from "../../../components";
import { SimpleModal } from "../../../components/Modal";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { isPresentativeByCard, isPresentativeByIdocType } from "../../../utils";

function ModalKycRequestDetail({ toggleModal, visible, t, classes, requestKycDetail, callbackConfirm }) {
  const [closeButton, setCloseButton] = useState(true);
  const [mode, setMode] = useState(KYC_REQUEST_MODE.VIEW);
  const initialValues = { reason: "" };
  const userDetail = useSelector((state) => state.userDetail.userDetail);

  const showActionKYC = userDetail?.role === "COMPANY_ADMIN" || requestKycDetail?.pic_code === userDetail.code;

  const initModeViewDetail = () => {
    setMode(KYC_REQUEST_MODE.VIEW);
    setCloseButton(true);
  };

  // validate for reason
  const validationSchema = (t) =>
    Yup.object({
      reason: Yup.string().required(t("message.MSG_4", { fieldName: t("list_kyc.label_reject_reason") })),
    });

  // render title when
  const renderTitle = () => {
    switch (mode) {
      case KYC_REQUEST_MODE.APPROVE:
        return t("list_kyc.title_approve_kyc_modal");
      case KYC_REQUEST_MODE.REJECT:
        return t("list_kyc.title_reject_kyc_modal");
      default:
        return t("list_kyc.titlel_kyc_edit_detail");
    }
  };

  const showModalConfirm = (type) => () => {
    setMode(type === KYC_REQUEST_MODE.APPROVE ? type : KYC_REQUEST_MODE.REJECT);
    setCloseButton(false);
  };

  const backViewDetail = () => {
    initModeViewDetail();
  };

  const submitReject = (values) => {
    callbackConfirm({ ...values, is_approved: false });
  };

  const submitApprove = () => {
    callbackConfirm({ is_approved: true });
  };

  useEffect(() => {
    initModeViewDetail();
  }, [requestKycDetail]);

  const renderAddIdocTypeInfo = (item) => {
    return (
      <Grid key={item.label} item md={4}>
        <Typography variant="subtitle1">{item.label}</Typography>
        {isPresentativeByIdocType(requestKycDetail) ? (
          <Typography variant="body2">
            <p className="value-arr">{t("verification.text_business_card")}</p>
            <p className="value-arr">{t("verification.text_legal_entity_proof")}</p>
          </Typography>
        ) : (
          CHART_EMPTY
        )}
      </Grid>
    );
  };

  return (
    <SimpleModal
      title={renderTitle()}
      toggleModal={toggleModal}
      width={654}
      closeBottom={closeButton}
      boxClass={classes.boxModalKycRequest}
      isOpeningModal={visible}
    >
      {mode === KYC_REQUEST_MODE.REJECT && (
        <Formik
          initialValues={initialValues}
          onSubmit={submitReject}
          validationSchema={validationSchema(t)}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <>
              <Typography className={`center-block ${classes.descriptionConfirmModalDetail}`} variant="body2">
                {t("list_kyc.text_description_reject_kyc_modal", { code: requestKycDetail.code })}
              </Typography>
              <Box>
                <Typography className={classes.labelRejectReason} variant="subtitle2">
                  {t("list_kyc.label_reject_reason_input")}
                </Typography>
                <Form className="full-width mt-12 mb-24">
                  <Field multiline type="textarea" rows={7} maxLength={1000} name="reason" component={InputComponent} />
                </Form>
              </Box>
              <Button
                onClick={handleSubmit}
                type="submit"
                className={`button block button-gradient ${classes.buttonKycDetail}`}
              >
                {t("component.button_reject")}
              </Button>
              <Typography onClick={backViewDetail} className="bottom-close-modal mt-24" variant="button">
                {t("component.button_cancel")}
              </Typography>
            </>
          )}
        </Formik>
      )}
      {mode === KYC_REQUEST_MODE.VIEW && (
        <>
          <Grid className={classes.infoKycModalWrap} container spacing={2}>
            {KYC_INFO_MODAL(t).map((item) =>
              item.prop === "add_doc_types" ? (
                renderAddIdocTypeInfo(item)
              ) : (
                <Grid key={item.label} item md={4}>
                  <Typography variant="subtitle1">{item.label}</Typography>
                  <Typography variant="body2">{requestKycDetail[item.prop]}</Typography>
                </Grid>
              )
            )}
          </Grid>
          <Divider className={classes.dividerInfo} light />
          <Grid className={classes.infoKycModalWrap} container spacing={2}>
            {KYC_INFO_REQUEST_MODAL(t).map((item) => (
              <Grid key={item.label} item md={item?.col ? item.col : 4}>
                <Typography variant="subtitle1">{item.label}</Typography>
                <Typography className="description-pre-line" variant="body2">
                  {requestKycDetail[item.prop]}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {requestKycDetail.status === KYC_REQUEST_MODE.APPROVE ||
          requestKycDetail.status === KYC_REQUEST_MODE.REJECT ? (
            <>
              <Divider className={classes.dividerInfo} light />
              <Grid className={classes.infoKycModalWrap} container spacing={2}>
                <Grid item md={4}>
                  <Typography variant="subtitle1">
                    {t(`list_kyc.label_${requestKycDetail.status.toLowerCase()}_by`)}
                  </Typography>
                  <Typography variant="body2">{requestKycDetail.reviewed_by}</Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subtitle1">
                    {t(`list_kyc.label_${requestKycDetail.status.toLowerCase()}_date`)}
                  </Typography>
                  <Typography variant="body2">{requestKycDetail.reviewed_at}</Typography>
                </Grid>
                {requestKycDetail.status === KYC_REQUEST_MODE.REJECT && (
                  <Grid item md={12}>
                    <Typography variant="subtitle1">{t(`list_kyc.label_reject_reason`)}</Typography>
                    <Typography className="description-pre-line" variant="body2">
                      {requestKycDetail.rejected_reason}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            showActionKYC && (
              <>
                <Button
                  onClick={showModalConfirm(KYC_REQUEST_MODE.APPROVE)}
                  type="submit"
                  className={`button block button-gradient mt-60 ${classes.buttonKycDetail}`}
                >
                  {t("list_kyc.btn_approve_request")}
                </Button>
                <Button
                  onClick={showModalConfirm(KYC_REQUEST_MODE.REJECT)}
                  className={`button block button-transparent-border ${classes.buttonKycDetail}`}
                >
                  {t("list_kyc.btn_reject_request")}
                </Button>
              </>
            )
          )}
        </>
      )}
      {mode === KYC_REQUEST_MODE.APPROVE && (
        <>
          <Typography className={`center-block ${classes.descriptionConfirmModalDetail}`} variant="body2">
            {t("list_kyc.text_description_approve_kyc_modal", { code: requestKycDetail.code })}
          </Typography>
          <Button
            onClick={submitApprove}
            type="submit"
            className={`button block button-gradient ${classes.buttonKycDetail}`}
          >
            {t("list_kyc.btn_approve_request")}
          </Button>
          <Typography onClick={backViewDetail} className="bottom-close-modal mt-24" variant="button">
            {t("component.button_cancel")}
          </Typography>
        </>
      )}
    </SimpleModal>
  );
}

export default memo(ModalKycRequestDetail);
