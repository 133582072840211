import React from "react";
import { ACCEPT_IMAGE } from "../../common/constant";
import { validateFile } from "../../utils";

export default function InputFileComponent({ className, name, setFieldValue, callbackChange }) {
  const handleUpload = (values) => {
    var file = values.target.files[0];
    const fileExtension = validateFile(file);
    if (fileExtension) {
      setFieldValue(name, file);
      callbackChange && callbackChange(file);
    }
  };
  return <input type="file" accept={ACCEPT_IMAGE} className={className} name={name} onChange={handleUpload} />;
}
