import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Checkbox,
  ListItemText,
  Chip,
  OutlinedInput,
  makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

SelectWithCheckboxComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.array.isRequired,
  props: PropTypes.object,
  menuClass: PropTypes.string,
};

SelectWithCheckboxComponent.defaultProps = {
  field: null,
  form: null,
  props: {},
  menuClass: "",
  fieldValue: [],
};

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
  },
}));

function SelectWithCheckboxComponent({
  field,
  form,
  options,
  menuClass,
  message,
  fullFieldWidth,
  labelField,
  fieldValue,
  onChange,
  className,
  ...props
}) {
  const classes = useStyles();
  const isError = Boolean(message) || (field && form.touched[field.name] && Boolean(form.errors[field.name]));
  const { t } = useTranslation();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },

    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    className: menuClass,
  };

  const convertOptionKeyToValue = (options, item) => {
    return options.find((option) => option.key === item)?.value;
  };

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(fieldValue);
  }, [fieldValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      const newValue = selected.length === options.length ? [] : options.map((item) => item.key);
      setSelected(newValue);
      onChange(newValue);
      return;
    }
    setSelected(value);
    onChange(value);
  };

  return (
    <FormControl variant="outlined" fullWidth={fullFieldWidth} error={Boolean(isError)} className={className}>
      <InputLabel id={field.name}>{labelField}</InputLabel>
      <Select
        id={field.name}
        multiple
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput />}
        IconComponent={ExpandMoreIcon}
        renderValue={(selected) => {
          if (selected.length === options.length && options.length > 1) {
            return <Chip className={classes.chip} label={t("common.text_all")} />;
          }

          return selected.map((item) => {
            const newItem = convertOptionKeyToValue(options, item);
            return newItem && <Chip className={classes.chip} key={item} label={newItem} />;
          });
        }}
        MenuProps={MenuProps}
        variant="outlined"
        {...props}
      >
        {options.length > 1 && (
          <MenuItem value="all">
            <Checkbox
              checked={options.length > 0 && selected.length === options.length}
              indeterminate={selected.length > 0 && selected.length < options.length}
            />
            <ListItemText primary={t("common.text_all")} />
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            <Checkbox checked={selected.indexOf(option.key) > -1} />
            <ListItemText primary={option.value} />
          </MenuItem>
        ))}
      </Select>
      {isError && <FormHelperText>{form.errors[field.name]}</FormHelperText>}
    </FormControl>
  );
}

export default SelectWithCheckboxComponent;
