import React from "react";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { Button, makeStyles, Divider, InputAdornment } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

import { AccordionComponent, InputComponent, MultiCheckboxComponent } from "../../../components";
import EmployeeModal from "../model";
import { MAX_LENGTH_INPUT } from "../../../common/constant";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  boxCheckbox: {
    height: 200,
    overflow: "hidden auto",
    border: `1px solid ${theme.overrides.colorBlack}`,
    padding: theme.spacing(1),
    borderTop: "none",

    "& span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  inputSearch: {
    "& > div": {
      border: `1px solid ${theme.overrides.colorBlack}`,
    },
  },
}));

function SearchComponent(props) {
  const {
    onSearch,
    listDepartments = [],
    onSearchChange,
    listRole = [],
    initialValues = {},
    handleResetSearch,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { field } = EmployeeModal;
  const { searchText, departmentID, role } = field;

  const onChange = (setFieldValue, fieldName) => (value) => {
    setFieldValue(fieldName, value);
    onSearchChange({ key: fieldName, value });
  };

  const onSearchText = (setFieldValue) => (event) => {
    const { value, name } = event.target;
    setFieldValue(name, value);
    onSearchChange({ key: name, value: value.trim() });
  };

  const onBlur = (setFieldTouched, fieldName) => () => {
    setFieldTouched(fieldName);
  };

  const onReset = (handleReset) => () => {
    if (!handleResetSearch) return;
    handleResetSearch(handleReset);
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSearch}>
      {({ values, setFieldValue, setFieldTouched, handleReset }) => (
        <Form>
          <Field
            name={searchText}
            placeholder={t("search.placeholder_search_employee")}
            value={values[searchText]}
            onChange={onSearchText(setFieldValue)}
            className={classes.inputSearch}
            maxLength={MAX_LENGTH_INPUT}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            component={InputComponent}
          />
          <AccordionComponent title={t("employee.label_role")}>
            <Field
              name={role}
              options={listRole}
              fieldValue={values[role]}
              fullFieldWidth
              onChange={onChange(setFieldValue, role)}
              onBlur={onBlur(setFieldTouched, role)}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>
          <Divider light className={classes.divider} />
          <AccordionComponent title={t("employee.label_department")}>
            <Field
              name={departmentID}
              options={listDepartments}
              fieldValue={values[departmentID]}
              fullFieldWidth
              onChange={onChange(setFieldValue, departmentID)}
              onBlur={onBlur(setFieldTouched, departmentID)}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>
          <Divider light className={classes.divider} />

          <Button type="submit" className={`button button-gradient ${classes.button}`} fullWidth>
            {t("component.button_search")}
          </Button>
          <Button
            className={`button button-transparent-border ${classes.button}`}
            fullWidth
            onClick={onReset(handleReset)}
          >
            {t("component.button_reset")}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default SearchComponent;
