import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { Button, IconButton, InputAdornment, Link, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import InputComponent from "../../components/Input";

function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });
  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (values) => {
    // console.log(values);
  };

  return (
    <div className="login">
      <Formik initialValues={{ email: "", password: "" }} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, submitForm }) => (
          <Form className="form">
            <div>{t("common.next")}</div>
            <Typography variant="h4">Forgot Password</Typography>
            <Field
              type="text"
              name="email"
              label="Email"
              className="form__input"
              fullWidth
              component={InputComponent}
            />
            <Field
              type={clsx({ password: !showPassword, text: showPassword })}
              name="password"
              label="Password"
              className="form__input"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              component={InputComponent}
            />
            <Link href="#" className="form__link">
              Forgot email?
            </Link>
            <Button color="primary" variant="contained" fullWidth type="submit" className="form__button">
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default LoginPage;
