import React from "react";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import moment from "moment";

import { InputComponent, SelectComponent, SimpleModal, DatePickerComponent } from "../../../components";
import { MAX_LENGTH_INPUT } from "../../../common/constant";
import { isRoleEmployee } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  field: {
    marginTop: theme.spacing(2),
    textAlign: "left",

    "&.multiple-select .Mui-focused": {
      backgroundColor: theme.overrides.colorWhite,
      padding: "0 4px",
    },

    "&.multiple-select .MuiFormLabel-filled": {
      backgroundColor: theme.overrides.colorWhite,
      padding: "0 4px",
    },
  },
  boxAction: {
    display: "flex",
    flexWrap: "wrap",

    "& button": {
      flex: "1 1 260px",
      maxWidth: 260,
      margin: `${theme.spacing(2)}px auto 0 auto`,
    },
  },
  buttonCancel: {
    color: theme.overrides.colorMandalay,
  },
}));

function CreateEmployeeForm(props) {
  const { t } = useTranslation();

  const classes = useStyles();

  const validationSchema = Yup.object({}).shape({
    fullName: Yup.string()
      .trim()
      .required(t("message.MSG_4", { fieldName: t("employee.text_full_name") })),
    email: Yup.string()
      .trim()
      .email(t("message.MSG_2"))
      .required(t("message.MSG_4", { fieldName: t("employee.label_email") })),
    role: Yup.string().required(t("message.MSG_4", { fieldName: t("employee.label_role") })),
    department: Yup.string().when("role", {
      is: "EMPLOYEE",
      then: Yup.string().required(t("message.MSG_4", { fieldName: t("employee.label_department") })),
    }),
  });

  const initialValues = {
    dateOfBirth: null,
    fullName: "",
    email: "",
    role: "",
    department: "",
  };
  const { title, isVisible, toggleModal, roles = [], departments = [], onSubmit } = props;

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  const handleDatePickerChange = (setFieldValue) => (date) => {
    setFieldValue("dateOfBirth", moment(date).format("YYYY-MM-DD"));
  };

  const onChangeRole = (setFieldValue) => (e) => {
    const { value, name } = e.target;

    setFieldValue("department", "");
    setFieldValue(name, value);
  };

  return (
    <SimpleModal isOpeningModal={isVisible} toggleModal={toggleModal} classNamePaper={"kyc-request-form"} width={600}>
      <Box className={classes.root}>
        <Typography variant="h6">{title}</Typography>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <Field
                name="fullName"
                label={t("employee.text_full_name")}
                placeholder={t("employee.placeholder_full_name")}
                className={`${classes.field}`}
                maxLength={MAX_LENGTH_INPUT}
                fullWidth
                component={InputComponent}
              />
              <Field
                name="dateOfBirth"
                label={t("employee.text_birthday")}
                placeholder="YYYY/MM/DD"
                className={`${classes.field}`}
                value={values.dateOfBirth}
                maxDate={new Date()}
                format="yyyy/MM/dd"
                onChange={handleDatePickerChange(setFieldValue)}
                fullWidth
                component={DatePickerComponent}
              />
              <Field
                name="email"
                label={t("employee.label_email")}
                placeholder={t("employee.placeholder_email")}
                className={`${classes.field}`}
                maxLength={MAX_LENGTH_INPUT}
                fullWidth
                component={InputComponent}
              />
              <Field
                name="role"
                label={t("employee.placeholder_role")}
                labelField={t("employee.placeholder_role")}
                className={classes.field}
                options={roles}
                value={values.role}
                onChange={onChangeRole(setFieldValue)}
                fullFieldWidth
                component={SelectComponent}
              />
              {isRoleEmployee(values.role) && (
                <Field
                  name="department"
                  labelField={t("customer_management.label_department")}
                  className={`${classes.field} multiple-select`}
                  options={departments}
                  value={values.department}
                  fullFieldWidth
                  component={SelectComponent}
                />
              )}
              <Box m={2} className={classes.boxAction}>
                <Button type="submit" className="button button-gradient" onClick={handleSubmit}>
                  {t("component.button_create")}
                </Button>
                <Button onClick={toggleModal} className={classes.buttonCancel}>
                  {t("component.button_cancel")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </SimpleModal>
  );
}

CreateEmployeeForm.propTypes = {
  // initialValues: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

CreateEmployeeForm.defaultProps = {
  // initialValues: {},
  isVisible: false,
  toggleModal: () => {},
};

export default CreateEmployeeForm;
