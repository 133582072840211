import { Box, Tooltip } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import { isPresentativeByCard } from "../../utils";

export const IdocRepresentative = ({ idocText, customer }) => {
  const { t } = useTranslation();
  const titleTooltip = t("verification.text_business_card") + ", " + t("verification.text_legal_entity_proof");
  return isPresentativeByCard(customer) ? (
    <Box className="box-idoc-representative">
      <Box className="text-idoc">{idocText}</Box>
      <Tooltip title={titleTooltip} aria-label={titleTooltip.toLowerCase()}>
        <AddCircleOutlinedIcon />
      </Tooltip>
    </Box>
  ) : (
    idocText || ""
  );
};
