import { ActionTypes } from "./actions";

const DEFAULT_STATE = {
  listData: {
    data: [],
  },
  dataChange: null,
};

export default (state = DEFAULT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_LIST_KYC_HISTORY_SUCCESS:
      return {
        ...state,
        listData: payload,
      };
    case ActionTypes.GET_DETAIL_KYC_HISTORY_START:
    case ActionTypes.GET_LIST_KYC_HISTORY_START:
      return {
        ...state,
        dataChange: null,
      };
    case ActionTypes.GET_DETAIL_KYC_HISTORY_SUCCESS:
      return {
        ...state,
        dataChange: payload,
      };
    default:
      return state;
  }
};
