import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { CHART_EMPTY } from "../../common/constant";
import ZoomPhotoChildImage from "../../components/ZoomPhoto/childImage";
import { themeStatusConfig } from "../../utils/themeConfig";

export const useStyles = makeStyles((theme) => ({
  boxContainer: {
    maxWidth: "1140px",
    margin: "auto",
    background: "white",
    borderRadius: "5px",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(4),
  },
  kycTitleContent: {
    marginBottom: theme.spacing(3),
  },
  gridInfoContainer: {
    marginBottom: theme.spacing(5),
  },
  labelInfo: {
    fontWeight: "500",
    fontFamily: `"Roboto", sans-serif`,
  },
  labelValue: {
    wordBreak: "break-word",
  },
  ...themeStatusConfig(theme, 117),
}));
export const useGetInfoBusiness = ({ data }) => {
  const {
    business_card_pic,
    business_card_company,
    business_card_company_addr,
    business_card_phone,
    business_card_mobile,
    business_card_fax,
    business_card_email,
    business_card_web,
  } = data;

  const { t } = useTranslation();
  return [
    {
      label: t("verification.text_company_full_name"),
      value: business_card_pic,
    },
    {
      label: t("verification.text_your_company"),
      value: business_card_company,
    },
    {
      label: t("verification.text_company_address"),
      value: business_card_company_addr,
    },
    {
      label: t("verification.text_telephone_number"),
      value: business_card_phone,
    },
    {
      label: t("verification.text_fax_label"),
      value: business_card_mobile,
    },
    {
      label: t("verification.text_mobile_number_label"),
      value: business_card_fax,
    },
    {
      label: t("verification.text_mail_label"),
      value: business_card_email,
    },
    {
      label: t("verification.text_website_label"),
      value: business_card_web,
      isLink: true,
    },
  ];
};

export const useGetInfoLegal = ({ data }) => {
  const { t } = useTranslation();
  const {
    legal_entity_company,
    legal_entity_representative_name,
    legal_entity_company_business,
    legal_entity_license,
  } = data;
  return [
    {
      label: t("verification.text_your_company"),

      value: legal_entity_company,
    },
    {
      label: t("verification.text_representative_name"),

      value: legal_entity_representative_name,
    },
    {
      label: t("verification.text_company_business"),
      value: legal_entity_company_business,
    },
    {
      label: t("verification.text_license"),
      value: legal_entity_license,
    },
  ];
};

export const RepresentativeInfo = ({ data, document, arrKeyPhotos, title }) => {
  const classes = useStyles();

  const renderInfoBySRS2 = ({ label, value, isLink }, index) => {
    let domValue = "";
    if (isLink) {
      domValue = (
        <a href={value} className={classes.labelValue} target="_blank">
          {value}
        </a>
      );
    } else {
      domValue = (
        <Typography className={classes.labelValue} variant="body2">
          {value || CHART_EMPTY}
        </Typography>
      );
    }
    return (
      <Grid key={index} item xs={12}>
        <Typography className={classes.labelInfo} variant="subtitle1">
          {label}
        </Typography>
        {domValue}
      </Grid>
    );
  };

  const renderUploadPhoto = (arrKeyPhotos, title = "") => {
    return (
      <Grid item md={4} className="grid-col-image">
        {title && (
          <Typography className={classes.kycTitleContent} variant="h6">
            {title}
          </Typography>
        )}
        {arrKeyPhotos.map((item) =>
          data[`${item}_url`] ? (
            <Grid className="grid-image" key={item} item md={12}>
              <ZoomPhotoChildImage fullWith src={data[`${item}_url`]} alt={item} />
            </Grid>
          ) : (
            ""
          )
        )}
      </Grid>
    );
  };

  return (
    <Grid container className="container-legal" spacing={2}>
      <Grid item md={8}>
        <Typography className={classes.kycTitleContent} variant="h6">
          {title}
        </Typography>
        <Grid className={classes.gridInfoContainer} container spacing={2}>
          {document.map(renderInfoBySRS2)}
        </Grid>
      </Grid>
      {renderUploadPhoto(arrKeyPhotos)}
    </Grid>
  );
};
