import { Avatar, Badge, Box, Button, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { memo, useState } from "react";
import { PROFILE_PARAMS } from "../../../common/constant";
import { InputComponent, DatePickerComponent, InputFileComponent } from "../../../components";
import { SimpleModal } from "../../../components/Modal";
import * as Yup from "yup";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import moment from "moment";
import { getBase64 } from "../../../utils";

function ModalEditProfile({ toggleModal, visible, t, classes, userDetail, userDetailDOM, callbackSubmitEdit }) {
  const [newAvatar, setNewAvatar] = useState("");
  const initialValues = {
    full_name: userDetail?.name ? userDetail.name : "",
    birthday: userDetail?.birthday ? userDetail.birthday : "",
    avatar_file: null,
    avatar: userDetail.avatar,
  };

  const handleDatePickerChange = (setFieldValue) => (date) => {
    setFieldValue("birthday", moment(date).format("YYYY-MM-DD"));
  };

  const validationSchema = Yup.object({}).shape({
    birthday: Yup.string()
      .nullable()
      .required(t("message.MSG_4", { fieldName: t("employee.text_birthday") })),
    full_name: Yup.string()
      .trim()
      .required(t("message.MSG_4", { fieldName: t("employee.text_full_name") })),
  });

  const onSubmitEdit = (values) => {
    var formData = new FormData(); // Currently empty
    formData.set("full_name", values.full_name.trim());
    formData.set("birthday", values.birthday);
    formData.set("avatar", values.avatar);
    if (values?.avatar_file?.size) {
      formData.set("avatar_file", values.avatar_file);
    }
    callbackSubmitEdit(formData);
  };

  const callbackUploadAvatar = (file) => {
    getBase64(file, (uri) => {
      setNewAvatar(uri);
    });
  };

  return (
    <SimpleModal
      toggleModal={toggleModal}
      width={448}
      closeBottom={false}
      boxClass={classes.modalSetting}
      isOpeningModal={visible}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitEdit}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Box className={classes.boxFormEditProfile}>
            <Box className={classes.boxAavatarEdit}>
              <Badge
                className={classes.maskWrapAvatarBadge}
                overlap="circle"
                badgeContent={
                  <div className={classes.maskWrapAvatar}>
                    <InputFileComponent
                      className={classes.inputFile}
                      callbackChange={callbackUploadAvatar}
                      setFieldValue={setFieldValue}
                      name="avatar_file"
                    />
                    <ImageOutlinedIcon className={classes.iconImage} />
                  </div>
                }
              >
                <Avatar
                  className={classes.avatar}
                  alt={userDetail.name}
                  src={newAvatar ? newAvatar : userDetail.avatar_url}
                />
              </Badge>
            </Box>
            <Form className="mt-30 mb-12 full-width">
              <Field maxLength={254} name="full_name" component={InputComponent} />
            </Form>
            <Typography className="mt-7 mb-36" variant="subtitle1">{`${t("employee.label_employee_id")}: ${
              userDetail.code
            }`}</Typography>
            {userDetailDOM?.code &&
              PROFILE_PARAMS(t).map((item) => (
                <div key={item.prop} className={`mt-0 mb-16 ${classes.infoWrap}`}>
                  <Typography className={classes.infoLabel} variant="subtitle1">
                    {item.label}
                  </Typography>
                  {item.prop === "birthday" ? (
                    <Field
                      className={classes.dateInput}
                      name="birthday"
                      label={t("employee.text_birthday")}
                      placeholder="YYYY/MM/DD"
                      value={values.birthday}
                      maxDate={new Date()}
                      format="yyyy/MM/dd"
                      onChange={handleDatePickerChange(setFieldValue)}
                      fullWidth
                      component={DatePickerComponent}
                    />
                  ) : (
                    <Typography className={classes.infoValue} variant="body1">
                      {userDetailDOM[item.prop] ? userDetailDOM[item.prop] : "---"}
                    </Typography>
                  )}
                </div>
              ))}
            <Button
              onClick={handleSubmit}
              type="submit"
              className={`button block button-gradient ${classes.btnSaveDetail}`}
            >
              {t("component.button_save")}
            </Button>
            <Typography onClick={toggleModal} className="bottom-close-modal mt-24" variant="button">
              {t("component.button_cancel")}
            </Typography>
          </Box>
        )}
      </Formik>
    </SimpleModal>
  );
}

export default memo(ModalEditProfile);
