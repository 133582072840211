import React from "react";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { SimpleModal } from "../Modal";
import SelectWithCheckboxComponent from "../Select/selectWithCheckbox";
import { SelectComponent, InputPhoneNumber } from "../index";
import { DEFAULT_COUNTRY, getLanguageOptions, getCustomerTypeOptions } from "../../common/constant";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  field: {
    marginTop: theme.spacing(2),
    textAlign: "left",

    "&.multiple-select .Mui-focused": {
      backgroundColor: theme.overrides.colorWhite,
      padding: "0 4px",
    },

    "&.multiple-select .MuiFormLabel-filled": {
      backgroundColor: theme.overrides.colorWhite,
      padding: "0 4px",
    },
  },
  boxAction: {
    display: "flex",
    flexWrap: "wrap",

    "& button": {
      flex: "1 1 260px",
      maxWidth: 260,
      margin: `${theme.spacing(2)}px auto 0 auto`,
    },
  },
  buttonCancel: {
    color: theme.overrides.colorMandalay,
  },
}));

function KYCRequestForm(props) {
  const { t } = useTranslation();

  const LANGUAGE_OPTIONS = getLanguageOptions(t);
  const CUSTOMER_TYPE_OPTIONS = getCustomerTypeOptions(t);

  const classes = useStyles();

  const validationSchema = Yup.object({}).shape({
    departments: Yup.string().required(t("message.MSG_4", { fieldName: t("customer_management.label_department") })),
    customerTypes: Yup.string().required(
      t("message.MSG_4", { fieldName: t("customer_management.label_customer_type") })
    ),
    phoneNumber: Yup.mixed()
      .test("phoneNumber", function (value) {
        return (
          (value?.length < 4 &&
            this.createError({
              message: t("message.MSG_4", { fieldName: t("customer_management.label_customer_phone_number") }),
            })) ||
          (value?.length < 11 &&
            this.createError({
              message: t("message.MSG_39"),
            })) ||
          true
        );
      })
      .required(t("message.MSG_4", { fieldName: t("customer_management.label_customer_phone_number") })),
    language: Yup.string().required(t("message.MSG_4", { fieldName: t("customer_management.label_message_language") })),
  });

  const initialValues = {
    departments: [],
    customerTypes: [],
    phoneNumber: "",
    language: "",
  };
  const { title, isVisible, toggleModal, departments, onSubmit } = props;

  const onChange = (setFieldValue, fieldName, setFieldTouched) => (value) => {
    setFieldValue(fieldName, value);
    if (fieldName === "departments") {
      setFieldValue("customerTypes", []);
      setFieldTouched("customerTypes", false);
    }
  };

  const onBlur = (setFieldTouched, fieldName) => () => {
    setFieldTouched(fieldName);
  };

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  const onChangePhoneNumber = (setFieldValue, fieldName) => (value) => {
    setFieldValue(fieldName, value);
  };

  const mapKeyToCode = (selectedDepartments) => {
    return departments.filter((department) => selectedDepartments.includes(department.key)).map((item) => item.code);
  };

  const filterOptions = (selectedDepartments) => {
    return CUSTOMER_TYPE_OPTIONS.filter((type) => mapKeyToCode(selectedDepartments).includes(type.department));
  };

  return (
    <SimpleModal isOpeningModal={isVisible} toggleModal={toggleModal} className={"kyc-request-form"} width={600}>
      <Box className={classes.root}>
        <Typography variant="h6">{title}</Typography>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, setFieldValue, handleSubmit, setFieldTouched }) => (
            <Form>
              <Field
                name="departments"
                labelField={t("customer_management.label_department")}
                className={`${classes.field} multiple-select`}
                options={departments}
                fieldValue={values.departments}
                fullFieldWidth
                onChange={onChange(setFieldValue, "departments", setFieldTouched)}
                onBlur={onBlur(setFieldTouched, "departments")}
                component={SelectWithCheckboxComponent}
              />
              <Field
                name="customerTypes"
                labelField={t("customer_management.label_customer_type")}
                className={`${classes.field} multiple-select`}
                options={filterOptions(values.departments)}
                fieldValue={values.customerTypes}
                fullFieldWidth
                onChange={onChange(setFieldValue, "customerTypes", setFieldTouched)}
                onBlur={onBlur(setFieldTouched, "customerTypes")}
                disabled={!values.departments.length}
                component={SelectWithCheckboxComponent}
              />
              <Field
                name="phoneNumber"
                label={t("customer_management.label_customer_phone_number")}
                placeholder={t("customer_management.placeholder_phone_number")}
                containerClass={`${classes.field} input_phone-number`}
                onChange={onChangePhoneNumber(setFieldValue, "phoneNumber")}
                value={values.phoneNumber}
                defaultCountry={DEFAULT_COUNTRY}
                component={InputPhoneNumber}
              />
              <Field
                name="language"
                label={t("customer_management.label_message_language")}
                className={classes.field}
                options={LANGUAGE_OPTIONS}
                labelField={t("customer_management.label_message_language")}
                value={values.language}
                fullFieldWidth
                component={SelectComponent}
              />
              <Box m={2} className={classes.boxAction}>
                <Button type="submit" className="button button-gradient" onClick={handleSubmit}>
                  {t("component.button_send")}
                </Button>
                <Button onClick={toggleModal} className={classes.buttonCancel}>
                  {t("component.button_cancel")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </SimpleModal>
  );
}

KYCRequestForm.propTypes = {
  // initialValues: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

KYCRequestForm.defaultProps = {
  // initialValues: {},
  isVisible: false,
  toggleModal: () => {},
};

export default KYCRequestForm;
