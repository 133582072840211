import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

MultiCheckbox.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.array.isRequired,
  props: PropTypes.object,
  menuClass: PropTypes.string,
};

MultiCheckbox.defaultProps = {
  field: null,
  form: null,
  props: {},
  menuClass: "",
};

export const listFieldReset = ["add_doc_types"];

function MultiCheckbox({
  field,
  form,
  options,
  menuClass,
  message,
  fullFieldWidth,
  labelField,
  fieldValue,
  onChange,
  fieldName = "",
  className,
  allOption = true,
  ...props
}) {
  const { t } = useTranslation();

  const [checkedValues, setCheckedValues] = useState([]);

  useEffect(() => {
    if (fieldValue.length) {
      setCheckedValues(fieldValue);
    }
    if (!fieldValue.length && listFieldReset.includes(fieldName)) {
      setCheckedValues(fieldValue);
    }
  }, [fieldValue]);

  function handleSelect(checkedName) {
    if (checkedName === "all") {
      const newValue = checkedValues.length === options.length ? [] : options.map((item) => item.key);
      setCheckedValues(newValue);
      onChange(newValue);
      return;
    }

    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newNames);
    onChange(newNames);
    return newNames;
  }

  return (
    <>
      {options.length > 1 && allOption && (
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => handleSelect("all")}
              checked={options.length > 0 && checkedValues.length === options.length}
              indeterminate={checkedValues.length > 0 && checkedValues.length < options.length}
            />
          }
          label={<Typography variant="body2">{t("common.text_all")}</Typography>}
        />
      )}
      {options.map((option, index) => (
        <FormControlLabel
          control={
            <Checkbox checked={checkedValues.indexOf(option.key) > -1} onChange={() => handleSelect(option.key)} />
          }
          key={index}
          label={
            <Typography variant="body2" title={option.value}>
              {option.value}
            </Typography>
          }
        />
      ))}
    </>
  );
}

export default MultiCheckbox;
