import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    backgroundColor: "transparent",
    boxShadow: "none",

    "&:before": {
      display: "none",
    },
  },
  accordionSummary: {
    paddingLeft: 0,
    justifyContent: "start",

    "& > .MuiAccordionSummary-content": {
      flexGrow: "unset",
    },

    "&.Mui-expanded": {
      minHeight: "unset",
    },

    "& .Mui-expanded": {
      margin: 0,
    },

    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  accordionDetails: {
    flexDirection: "column",
    paddingLeft: 0,
  },
}));

function AccordionComponent(props) {
  const { children, title } = props;
  const classes = useStyles();

  return (
    <Accordion className={classes.accordionRoot}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
        <Typography variant="subtitle2">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
}
export default AccordionComponent;
