import React, { useState } from "react";
import { Drawer, IconButton, List, ListItem, ListItemIcon, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { drawerWidth, REGEX_OPTIONAL_PARAM } from "../../common/constant";
import Logo from "../../resources/images/logo.svg";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    padding: 0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },

    "& .item-icon": {
      margin: "auto",
      justifyContent: "center",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

function DrawerComponent({ isExpandedDrawer, toggleDrawer, allowedRoutes = [] }) {
  const classes = useStyles();
  const location = useLocation();
  let history = useHistory();
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(location.pathname);

  const handleListItemClick = (pathname) => {
    if (REGEX_OPTIONAL_PARAM.test(pathname)) {
      const newPath = pathname
        .split("/")
        .filter((item) => !item.includes("?"))
        .join("/");
      setSelectedItem(newPath);
      history.push(`${newPath}`);
      return;
    }
    setSelectedItem(pathname);
    history.push(`${pathname}`);
  };

  return (
    <Drawer
      variant="permanent"
      className={`${clsx(classes.drawer, {
        [classes.drawerOpen]: isExpandedDrawer,
        [classes.drawerClose]: !isExpandedDrawer,
      })} drawer`}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isExpandedDrawer,
          [classes.drawerClose]: !isExpandedDrawer,
        }),
      }}
    >
      <div className={`${classes.toolbar} drawer__top`}>
        <img src={Logo} alt="Glocaly" />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: !isExpandedDrawer,
          })}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {allowedRoutes
          .filter((item) => !!item.icon)
          .map(({ title, icon, path }, index) => (
            <ListItem
              button
              key={index}
              selected={selectedItem === path || selectedItem.includes(path)}
              onClick={(event) => handleListItemClick(path)}
            >
              <Tooltip title={title} placement="right">
                <ListItemIcon className="item-icon">{icon}</ListItemIcon>
              </Tooltip>
              <Typography variant="subtitle1" className={clsx({ [classes.hide]: !isExpandedDrawer })}>
                {title}
              </Typography>
            </ListItem>
          ))}
      </List>
      <div className={clsx("text-copyright", { [classes.hide]: !isExpandedDrawer })}>{t("common.text_copyright")}</div>
    </Drawer>
  );
}

export default DrawerComponent;
