import React, { createRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getFullUrl, HEADERS } from "../../services/api";

export function AuthenticatedLink({ url, filename, children, enableLang = true }) {
  const link = createRef();
  const token = useSelector((state) => state.authentication.currentUser?.access_token);
  const { i18n } = useTranslation();

  if (url && enableLang) {
    url = url + `/${i18n.language}`;
  }

  if (token) {
    HEADERS["Authorization"] = `Bearer ${token}`;
  }

  const handleAction = () => {
    if (link.current.href) {
      return;
    }
    initComponent();
    link.current.click();
  };

  const initComponent = async () => {
    const result = await fetch(getFullUrl(url), {
      headers: { ...HEADERS },
    });

    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    if(link.current){
      link.current.download = filename;
      link.current.href = href;
    }
  };

  useEffect(() => {
    initComponent();
  }, [i18n.language]);

  return (
    <>
      <a className="btn-export" role="button" ref={link} onClick={handleAction}>
        {children}
      </a>
    </>
  );
}
