import { api } from "./api";

export function getListEmployeeAPI(params = "") {
  return api.get("/manage/company/employee/list", params);
}

export function filterEmployeeAPI(params = "") {
  return api.post("/manage/employee/filter", params);
}

export function createEmployeeAPI(data = {}) {
  return api.post("/manage/employee/create", data);
}

export function getEmployeeDetailsAPI(id) {
  return api.get(`/manage/employee/${id}/info`);
}

export const deleteEmployeeAPI = (employeeID) => {
  return api.delete(`manage/employee/${employeeID}/delete`);
};

export const updateEmployeeAPI = ({ employeeID, body }) => {
  return api.put(`manage/employee/${employeeID}/update`, body, { showToast: false, test: "dsds" });
};

export const exportListEmployeeEndpoint = () => {
  return `/manage/employee/export`;
};
