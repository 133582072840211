import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, AppBar, Toolbar, IconButton, Avatar, Typography, Box } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import clsx from "clsx";
import { withRouter } from "react-router";

import { drawerWidth, NOTIFICATION_PAGE_SIZE } from "../../common/constant";
import SelectComponent from "../Select";
import i18n, { getCurrentLanguage } from "../../i18n/i18n";
import { actions as authenticationActions } from "../../pages/login/actions";
import Notification from "./Notification";

import FlagEN from "../../resources/images/flag-english.svg";
import FlagJP from "../../resources/images/flag-japan.svg";
import LogoutIcon from "../../resources/images/LogoutIcon";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../resources/images/logo.svg";
import { disconnectSocket, initiateSocket, subscribeToNotification } from "../../utils/socket";
import { actions, ActionTypes } from "../../pages/layout/actions";
import { redirectToProfile } from "../../utils";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),

    "& svg": {
      color: theme.overrides.colorActive,
    },
  },
  hide: {
    display: "none",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  selectLanguage: {
    border: `1px solid ${theme.overrides.colorActive}`,
    color: theme.overrides.colorWhite,
    marginRight: theme.spacing(3),

    "& .MuiSelect-select": {
      padding: "12px 40px 12px 10px",
      color: theme.overrides.colorWhite,
    },

    "& .flag": {
      marginRight: theme.spacing(1),
    },

    "& .MuiSvgIcon-root": {
      color: theme.overrides.colorWhite,
    },
  },
  publicContainer: {
    width: "100%",
    maxWidth: 1140,
    margin: "auto",
    padding: theme.spacing(0, 2),
    display: "flex",
    justifyContent: "space-between",
  },
  boxAvatar: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& :hover": {
      opacity: 0.8,
    },
  },
  avatar: {
    border: "solid 2px white",
    marginRight: "12px",
  },
  nameSpan: {
    color: "white",
    maxWidth: "120px",
    marginRight: theme.spacing(3),
  },
}));

function HeaderCommon({ toggleDrawer, isExpandedDrawer, isAuthenticated, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [listNotification, setListNotification] = useState({
    data: [],
    total: 0,
  });
  const userDetail = useSelector((state) => state.userDetail.userDetail);

  const notifications = useSelector((state) => state.app.notifications);
  const actionResult = useSelector((state) => state.app.type);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authenticationActions.userLogout());
  };

  const languages = [
    {
      key: "en",
      value: (
        <Fragment>
          <img className="flag" src={FlagEN} alt="flag english" />
          {t("common.text_language_english")}
        </Fragment>
      ),
    },
    {
      key: "jp",
      value: (
        <Fragment>
          <img className="flag" src={FlagJP} alt="flag japan" />
          {t("common.text_language_japan")}
        </Fragment>
      ),
    },
  ];

  const currentLanguage = getCurrentLanguage();

  const handleChangeLanguage = (event) => {
    const { value } = event.target;

    i18n.changeLanguage(value);
    localStorage.setItem("language", value);
  };

  const getNotifications = useCallback(
    (pageSize) => {
      dispatch(actions.getListNotificationStart({ page: 1, page_size: pageSize }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (notifications?.data?.length) {
      setListNotification(notifications);
    }
  }, [notifications]);

  useEffect(() => {
    if (actionResult === ActionTypes.READ_NOTIFICATION_SUCCESS) {
      getNotifications(NOTIFICATION_PAGE_SIZE);
      dispatch(actions.resetTypeOfAction());
    }
  }, [actionResult, dispatch, getNotifications]);

  useEffect(() => {
    if (!!isAuthenticated) {
      initiateSocket(isAuthenticated);

      getNotifications(NOTIFICATION_PAGE_SIZE);

      subscribeToNotification((response) => {
        setListNotification((prevState) => {
          const newValue = prevState.data;
          return {
            data: [JSON.parse(response), ...newValue],
            total: newValue.length + 1,
          };
        });
      });
    }

    return () => {
      return !!isAuthenticated && disconnectSocket();
    };
  }, [isAuthenticated, getNotifications]);

  return (
    <>
      {Boolean(isAuthenticated) && (
        <AppBar
          position="fixed"
          className={`${clsx(classes.appBar, {
            [classes.appBarShift]: isExpandedDrawer,
          })} header`}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: isExpandedDrawer,
              })}
            >
              <MenuIcon />
            </IconButton>
            <div className="menu-left"></div>
            <div className="menu-right">
              <SelectComponent
                value={currentLanguage}
                onChange={(value) => handleChangeLanguage(value)}
                options={languages}
                menuClass="button--language"
                className={classes.selectLanguage}
              />
              <Notification
                className={classes.badge}
                notifications={listNotification}
                getNotifications={getNotifications}
              />
              <Box onClick={redirectToProfile(props.history)} className={classes.boxAvatar}>
                <Avatar className={classes.avatar} alt={userDetail?.name} src={userDetail?.avatar_url} />
                <Typography noWrap className={classes.nameSpan} variant="subtitle1">
                  {userDetail?.name}
                </Typography>
              </Box>
              <IconButton color="inherit" aria-label="open drawer" onClick={handleLogout} edge="start">
                <LogoutIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      )}
      {!isAuthenticated && (
        <AppBar position="sticky">
          <Toolbar className={classes.publicContainer}>
            <img src={Logo} alt="Glocaly" />
            <div className="menu-left"></div>
            <div className="menu-right">
              <SelectComponent
                value={currentLanguage}
                onChange={(value) => handleChangeLanguage(value)}
                options={languages}
                menuClass="button--language"
                className={classes.selectLanguage}
              />
            </div>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}
export default withRouter(HeaderCommon);
