import { ActionTypes } from "./actions";

const DEFAULT_STATE = {
  employee: {},
  employees: {
    data: [],
  },
  type: "",
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIST_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employee: action.payload,
      };

    case ActionTypes.FILTER_LIST_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employees: action.payload,
      };

    case ActionTypes.CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.GET_EMPLOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        employee: action.payload,
      };

    case ActionTypes.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.UPDATE_EMPLOYEE_ERROR:
      return {
        ...state,
        type: action.payload,
      };

    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: "",
      };
    default:
      return state;
  }
};
