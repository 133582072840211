import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  body2: {
    color: theme.overrides.colorShuttleGray,
  },
}));

function ContentChild({ label, value, ...props }) {
  const classes = useStyles();

  return (
    <Box component="div" {...props}>
      <Typography variant="subtitle1">{label}</Typography>
      <Typography className={classes.body2} variant="body2" component="div">
        {value || "---"}
      </Typography>
    </Box>
  );
}

export default ContentChild;
