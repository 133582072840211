import { Box, Paper, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import LockIcon from "../../resources/images/LockIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
  },
  container: {
    maxWidth: 500,
    margin: "auto",
    textAlign: "center",
  },
  title: {
    marginTop: theme.spacing(4),
  },
  description: {
    color: theme.overrides.colorDustyGray,
    lineHeight: "19px",
    marginTop: theme.spacing(3),
  },
}));

function AccessDeniedPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      <Box className={classes.container}>
        <LockIcon />
        <Typography variant="h4" className={classes.title}>
          {t("access_denied.title")}
        </Typography>
        <Typography variant="subtitle1" className={classes.description}>
          {t("access_denied.description")}
        </Typography>
      </Box>
    </Paper>
  );
}

export default AccessDeniedPage;
