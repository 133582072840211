import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { Button, makeStyles, Divider } from "@material-ui/core";

import { AccordionComponent, InputComponent, MultiCheckboxComponent } from "../../../components";
import {
  getAddDocumentTypesOptions,
  getDepartmentOptions,
  GET_DOCUMENT_TYPE_OPTIONS,
  REQUEST_STATUS_OPTIONS,
} from "../../../common/constant";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

function SearchComponent({ listDepartments, onSearch }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({
    keyword: "",
    department_ids: [],
    idoc_types: GET_DOCUMENT_TYPE_OPTIONS(t).map((item) => item.key),
    status: REQUEST_STATUS_OPTIONS(t).map((item) => item.key),
    add_doc_types: [],
  });

  useEffect(() => {
    if (listDepartments.length) {
      const newDepartments = listDepartments.map((item) => item.key);
      setInitialValues({
        ...initialValues,
        department_ids: newDepartments,
      });
    }
  }, [listDepartments]);

  useEffect(() => {
    onSearch(initialValues);
  }, [initialValues]);

  const onChange = (setFieldValue, fieldName, values) => (value) => {
    setFieldValue(fieldName, value);
    onSearch({ ...values, [fieldName]: value });
  };

  const onChangeInput = (setFieldValue, fieldName, values) => (e) => {
    setFieldValue(fieldName, e.target.value);
  };

  const onBlur = (setFieldTouched, fieldName) => () => {
    setFieldTouched(fieldName);
  };

  const onResetFilter = (handleReset) => () => {
    handleReset();
    onSearch(initialValues);
    window.scrollTo(0, 0);
  };

  const ADD_DOCUMENT_TYPE_OPTIONS = getAddDocumentTypesOptions(t);

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSearch}>
      {({ values, setFieldValue, setFieldTouched, handleReset }) => (
        <Form className="search-list-kyc">
          <Field
            name="keyword"
            placeholder={t("search.placeholder_search_list_edit_kyc")}
            maxLength={254}
            onChange={onChangeInput(setFieldValue, "keyword", values)}
            component={InputComponent}
          />
          <AccordionComponent title={t("search.label_department")}>
            <Field
              name="department_ids"
              options={listDepartments.map((item) => ({
                ...item,
                value: getDepartmentOptions(item.value.toUpperCase(), t),
              }))}
              fieldValue={values.department_ids}
              fullFieldWidth
              onChange={onChange(setFieldValue, "department_ids", values)}
              onBlur={onBlur(setFieldTouched, "department_ids")}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>
          <Divider light className={classes.divider} />
          <AccordionComponent title={t("search.label_identity_document")}>
            <Field
              name="idoc_types"
              options={GET_DOCUMENT_TYPE_OPTIONS(t)}
              fieldValue={values.idoc_types}
              fullFieldWidth
              onChange={onChange(setFieldValue, "idoc_types", values)}
              onBlur={onBlur(setFieldTouched, "idoc_types")}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>
          <Divider light className={classes.divider} />
          <AccordionComponent title={t("search.label_request_status")}>
            <Field
              name="status"
              options={REQUEST_STATUS_OPTIONS(t)}
              fieldValue={values.status}
              fullFieldWidth
              onChange={onChange(setFieldValue, "status", values)}
              onBlur={onBlur(setFieldTouched, "status")}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>
          <Divider light className={classes.divider} />
          <AccordionComponent title={t("search.add_document")}>
            <Field
              name="add_doc_types"
              fieldName="add_doc_types"
              options={ADD_DOCUMENT_TYPE_OPTIONS}
              fieldValue={values?.add_doc_types}
              fullFieldWidth
              onChange={onChange(setFieldValue, "add_doc_types")}
              onBlur={onBlur(setFieldTouched, "add_doc_types")}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>

          <Button type="submit" className={`button button-gradient ${classes.button}`} fullWidth>
            {t("component.button_search")}
          </Button>
          <Button
            className={`button button-transparent-border ${classes.button}`}
            fullWidth
            onClick={onResetFilter(handleReset)}
          >
            {t("component.button_reset")}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default SearchComponent;
