import React, { useCallback, useEffect, useState } from "react";
import { AppBar, Box, Grid, IconButton, Paper, Tab, Tabs, Typography, makeStyles, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash-es";

import {
  BasicResidentRegisterContent,
  ContentChild,
  DriverLicenseContent,
  ForeignerRegistrationCardContent,
  HealthInsuranceCardContent,
  PassportContent,
  ResidentCardContent,
  ShowForPermissionComponent,
  SpecialPermanentResidentContent,
  ZoomPhoto,
} from "../../components";
import { actions, ActionTypes } from "../home/actions";
import { actions as employeeActions } from "../employee/actions";
import { SimpleModal } from "../../components/Modal";
import ArrowBackIcon from "../../resources/images/ArrowBackIcon";
import {
  CUSTOMER_TYPES,
  MAX_LENGTH_TEXTAREA,
  getLanguageOptions,
  getDocumentTypeName,
  getDepartmentOptions,
  getCustomerTypeOptions,
  KYC_STATUS_OPTIONS,
  CHART_EMPTY,
  KEY_UPLOAD_BUSINESS_DETAIL,
  KEY_UPLOAD_LEGAL_DETAIL,
} from "../../common/constant";
import { InputComponent } from "../../components";
import countries from "../../utils/countries";
import {
  formatDate,
  formatPIC,
  isPresentative,
  isStatusApproved,
  isStatusRejected,
  isStatusRequesting,
  isStatusVerifying,
} from "../../utils";
import EditCustomerForm from "./editCustomerForm";
import KYCeditHistory from "./KYCeditHistory";
import ProfileEditHistory from "./ProfileEditHistory";
import roles from "../../utils/roles";
import { RepresentativeInfo, useGetInfoBusiness, useGetInfoLegal } from "./RepresentativeInfo";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",
    alignItems: "center",
  },
  shortContent: {
    padding: theme.spacing(3),
    margin: theme.spacing(5, 0, 2),
  },
  content: {
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.1)",
  },
  appBar: {
    backgroundColor: theme.overrides.colorGallery,
    boxShadow: "none",
    color: "rgba(0, 0, 0, 0.38)",

    "& .Mui-selected": {
      backgroundColor: theme.overrides.colorWhite,
      color: theme.overrides.colorActive,
    },
  },
  tab: {
    backgroundColor: theme.overrides.colorWhite,
  },
  gridRoot: {
    alignItems: "flex-start",
  },
  status: {
    padding: theme.spacing(1, 2),
    display: "inline-block",
    textTransform: "lowercase",

    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  statusApproved: {
    backgroundColor: "rgba(67, 160, 71, 0.2)",
    color: theme.overrides.colorApple,
  },
  statusRejected: {
    backgroundColor: "rgba(239, 83, 80, 0.2)",
    color: theme.overrides.colorBurntSienna,
  },
  statusVerify: {
    backgroundColor: "rgba(255, 153, 0, 0.2)",
    color: theme.overrides.colorOrangePeel,
  },
  statusRequesting: {
    backgroundColor: "rgba(156, 39, 176, 0.2)",
    color: theme.overrides.colorSeance,
  },
  photo: {
    maxWidth: "270px",
    height: "auto",
    width: "100%",
  },
  action: {
    display: "flex",
    justifyContent: "center",

    "& .button": {
      padding: theme.spacing(1, 2),
    },

    "& .button-gradient": {
      marginLeft: theme.spacing(3),
    },
  },
  actionLeft: {
    justifyContent: "left",

    "& .button": {
      padding: theme.spacing(1, 2),
    },
  },
  modal: {
    paddingTop: theme.spacing(3),
    textAlign: "center",
  },
  rejectModal: {
    paddingTop: theme.spacing(3),
    textAlign: "center",

    "& button:first-child": {
      marginTop: theme.spacing(3),
    },
  },
  modalDescription: {
    color: theme.overrides.colorDustyGray,
    padding: theme.spacing(3, 0),
  },
  modalAction: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    "& .button-transparent": {
      marginTop: theme.spacing(1),
    },
  },
  previewImage: {
    width: "100%",
    height: "auto",
  },
  tabPanelKycEditHistory: {
    "& .MuiBox-root": {
      padding: theme.spacing(0),
    },
  },
  tabPanelProfileEditHistory: {
    "& .MuiBox-root": {
      padding: theme.spacing(0),
    },
  },
  modalRoot: {
    padding: theme.spacing(4),
  },
  reasonContent: {
    whiteSpace: "pre-line",
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box p={3} className={classes.tab}>
          {children}
        </Box>
      )}
    </div>
  );
}

function CustomerPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id: customerID } = useParams();
  let history = useHistory();

  const DOCUMENT_TYPE_NAME = getDocumentTypeName(t);
  const listKYCStatus = KYC_STATUS_OPTIONS(t);

  const customer = useSelector((state) => state.customer.customer);
  const requestStatus = useSelector((state) => state.customer.type);
  const departments = useSelector((state) => state.customer.departments);
  const employee = useSelector((state) => state.employee.employee);
  const userDetail = useSelector((state) => state.userDetail.userDetail);

  const [tab, setTab] = useState(0);
  const [isVisibleApproveModal, setIsVisibleApproveModal] = useState(false);
  const [isVisibleRejectModal, setIsVisibleRejectModal] = useState(false);
  const [isVisibleZoomPhotoModal, setIsVisibleZoomPhotoModal] = useState(false);
  const [isVisibleEditCustomerModal, setIsVisibleEditCustomerModal] = useState(false);
  const [zoomPhotoURL, setZoomPhotoURL] = useState("");
  const [request, setRequest] = useState("");
  const [listDepartment, setListDepartment] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);

  const currentUserIsPIC = customer?.pic_code === userDetail?.code;
  const currentUserMapCustomer =
    userDetail?.role === roles.EMPLOYEE ? customer?.department_codes?.includes(userDetail?.department_code) : true;
  const isEmployee = userDetail?.role === roles.EMPLOYEE;

  const initialValue = {
    reason: "",
  };

  const [initialValuesCustomer, setInitialValuesCustomer] = useState({
    departments: [],
    customerTypes: [],
    personInCharge: "",
    language: "",
  });

  const LANGUAGE_OPTIONS = getLanguageOptions(t);

  const validationSchema = Yup.object().shape({
    reason: Yup.string()
      .trim()
      .required(t("message.MSG_4", { fieldName: t("customer_management.placeholder_rejected_reason") })),
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const toggleApproveModal = useCallback(() => {
    setIsVisibleApproveModal(!isVisibleApproveModal);
  }, [isVisibleApproveModal]);

  const toggleRejectModal = useCallback(() => {
    setIsVisibleRejectModal(!isVisibleRejectModal);
  }, [isVisibleRejectModal]);

  const toggleEditCustomerModal = useCallback(() => {
    setIsVisibleEditCustomerModal(!isVisibleEditCustomerModal);
  }, [isVisibleEditCustomerModal]);

  const toggleZoomPhotoModal = useCallback(
    (url) => {
      setIsVisibleZoomPhotoModal(!isVisibleZoomPhotoModal);
      setZoomPhotoURL(url);
    },
    [isVisibleZoomPhotoModal]
  );

  const closeApproveModal = () => {
    setIsVisibleApproveModal(false);
  };

  const closeRejectModal = () => {
    setIsVisibleRejectModal(false);
  };

  const handleApproveKYC = () => {
    dispatch(
      actions.updateKYCStatusStart({
        id: customerID,
        body: {
          is_approved: true,
          reason: "",
        },
      })
    );
    setRequest("APPROVED");
  };

  const goToBack = () => {
    history.push("/customers");
  };

  const handleChangeField = (setFieldValue, fieldName) => (e) => {
    setFieldValue(fieldName, e.target.value);
  };

  const handleRejectKYC = (data) => {
    const { reason } = data;
    dispatch(
      actions.updateKYCStatusStart({
        id: customerID,
        body: {
          is_approved: false,
          reason: reason,
        },
      })
    );
    setRequest("REJECTED");
  };

  const handleUpdateCustomer = (data) => {
    const { departments, customerTypes, personInCharge, language, phone } = data;
    dispatch(
      actions.updateCustomerInformationStart({
        id: customerID,
        body: {
          department_ids: departments,
          customer_types: customerTypes,
          lang: language,
          pic_id: personInCharge,
          phone,
        },
      })
    );
  };

  const resendRequestKYC = () => {
    dispatch(actions.resendRequestKYCStart(customerID));
  };

  useEffect(() => {
    dispatch(employeeActions.getListEmployeeStart());
  }, [dispatch, employeeActions]);

  useEffect(() => {
    if (!departments?.length) {
      dispatch(actions.getDepartmentCompanyStart());
    }
  }, [dispatch, departments]);

  useEffect(() => {
    dispatch(actions.getCustomerInformationStart(customerID));
  }, [dispatch, customerID]);

  useEffect(() => {
    if (departments?.length) {
      const newData = departments.map((item) => ({
        key: item.id,
        value: getDepartmentOptions(item?.code, t),
        code: item.code,
      }));
      setListDepartment(newData);
    }
  }, [departments, t, userDetail]);

  useEffect(() => {
    if (employee?.length) {
      let newData = employee.map((item) => ({
        key: item.id,
        value: item.name,
        code: item.code,
        department: item.department_id,
      }));
      newData.push({
        key: -1,
        value: t("customer_management.text_unassigned"),
        code: "UNASSIGNED",
        department: null,
      });
      setListEmployee(newData);
    }
  }, [employee]);

  useEffect(() => {
    if (!isEmpty(customer)) {
      const { department_ids, customer_types, pic_id, lang, nationality, phone } = customer;
      setInitialValuesCustomer((prevState) => ({
        ...prevState,
        departments: department_ids,
        customerTypes: customer_types,
        personInCharge: pic_id,
        language: lang,
        nationality,
        phone,
      }));
    }
  }, [customer]);

  useEffect(() => {
    switch (requestStatus) {
      case ActionTypes.UPDATE_KYC_STATUS_SUCCESS:
        closeApproveModal();
        closeRejectModal();

        dispatch(actions.getCustomerInformationStart(customerID));

        if (request === "APPROVED") {
          enqueueSnackbar(t("message.MSG_10"), { variant: "success" });
        } else if (request === "REJECTED") {
          enqueueSnackbar(t("message.MSG_11"), { variant: "success" });
        }
        dispatch(actions.resetTypeOfAction());
        break;
      case ActionTypes.UPDATE_CUSTOMER_INFORMATION_SUCCESS:
        enqueueSnackbar(t("message.MSG_28"), { variant: "success" });
        toggleEditCustomerModal();
        dispatch(actions.getCustomerInformationStart(customerID));
        dispatch(actions.resetTypeOfAction());
        break;
      case ActionTypes.RESEND_REQUEST_KYC_SUCCESS:
        enqueueSnackbar(t("message.MSG_23"), { variant: "success" });
        dispatch(actions.resetTypeOfAction());
        break;
      default:
        break;
    }
    return () => {
      setRequest("");
    };
  }, [dispatch, requestStatus, toggleApproveModal, customerID, t, enqueueSnackbar]);

  const customerProfile = [
    {
      label: t("customer_management.text_customer_id"),
      value: customer.customer_code,
    },
    {
      label: t("customer_management.text_department"),
      value: customer.departments?.map((item, index) => (
        <div key={index}>{getDepartmentOptions(item?.toUpperCase(), t)}</div>
      )),
    },
    {
      label: t("customer_management.label_customer_type"),
      value: customer.customer_types?.map((type, index) => (
        <div key={index}>{getCustomerTypeOptions(t).find((option) => option.key === type).value}</div>
      )),
    },

    {
      label: t("customer_management.label_message_language"),
      value: LANGUAGE_OPTIONS.find((item) => item.key === customer.lang)?.value,
    },

    {
      label: t("customer_management.label_person_in_charge"),
      value:
        (customer.pic_deleted && t("customer_management.text_unassigned")) ||
        formatPIC(customer.pic_code, userDetail?.code, t) ||
        customer.pic_name,
    },
  ];

  const contact = [
    {
      label: t("customer_management.text_full_name"),
      value: customer.full_name,
    },
    {
      label: t("customer_management.text_email"),
      value: customer.email,
    },
    {
      label: t("customer_management.text_date_of_birth"),
      value: formatDate(customer.birthday),
    },
    {
      label: t("customer_management.text_phone_number"),
      value: customer.phone,
    },
    {
      label: t("customer_management.text_nationality"),
      value: countries.find((item) => item.key === customer.nationality)?.value,
    },
    {
      label: t("customer_management.text_residential_address"),
      value: customer.residential_address,
    },
  ];

  const renderIdentityDocument = (documentType, values, makeGrid) => {
    switch (documentType) {
      case "DRIVER_LICENSE":
        return <DriverLicenseContent values={values} makeGrid={makeGrid} />;
      case "PASSPORT":
        return <PassportContent values={values} makeGrid={makeGrid} />;
      case "BASIC_RESIDENT_REGISTER":
        return <BasicResidentRegisterContent values={values} makeGrid={makeGrid} />;
      case "HEALTH_INSURANCE_CARD":
        return <HealthInsuranceCardContent values={values} makeGrid={makeGrid} />;
      case "RESIDENCE_CARD":
        return <ResidentCardContent values={values} makeGrid={makeGrid} />;
      case "FOREIGNER_REGISTRATION_CARD":
        return <ForeignerRegistrationCardContent values={values} makeGrid={makeGrid} />;
      case "SPECIAL_PERMANENT_RESIDENT":
        return <SpecialPermanentResidentContent values={values} makeGrid={makeGrid} />;
      default:
        break;
    }
  };

  const businessDocument = useGetInfoBusiness({ data: customer });
  const legalDocument = useGetInfoLegal({ data: customer });

  return (
    <Box>
      <Box className={classes.heading}>
        <IconButton component="span" onClick={goToBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">{t("customer_management.text_customer_profile")}</Typography>
      </Box>
      <Paper elevation={0} className={classes.shortContent}>
        <Grid container spacing={3}>
          {customerProfile.map(({ label, value }, index) => (
            <Grid item md={2} key={index}>
              <ContentChild label={label} value={value} />
            </Grid>
          ))}
          <Grid item md={1}></Grid>
          <Grid item md={1}>
            <Button className="button button-gradient" onClick={toggleEditCustomerModal}>
              {t("component.button_edit")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Box className={classes.content}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs value={tab} onChange={handleChange} indicatorColor="primary">
            <Tab label={t("customer_management.tab_kyc_information")} />
            <Tab label={t("customer_management.tab_kyc_edit_history")} />
            <Tab label={t("customer_management.tab_profile_edit_history")} />
          </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
          <Grid container spacing={2} className={classes.gridRoot}>
            <Grid container item md={7} spacing={2}>
              <Grid item md={12}>
                <Typography variant="h6">{t("customer_management.heading_contact_information")}</Typography>
              </Grid>
              {contact.map(({ label, value }, index) => (
                <Grid item md={6} key={index}>
                  <ContentChild label={label} value={value} />
                </Grid>
              ))}
              <Grid item md={12}>
                <Typography variant="h6">{t("customer_management.heading_identity_document")}</Typography>
              </Grid>
              <Grid item md={6}>
                <ContentChild
                  label={t("customer_management.heading_identity_document")}
                  value={DOCUMENT_TYPE_NAME[customer.idoc_type]}
                />
              </Grid>
              {renderIdentityDocument(customer?.idoc_type, customer, 6)}
              <Grid item md={6}>
                <Typography variant="subtitle1">{t("customer_management.label_kyc_status")}</Typography>
                <span
                  className={clsx(classes.status, {
                    [classes.statusVerify]: isStatusVerifying(customer.status),
                    [classes.statusApproved]: isStatusApproved(customer.status),
                    [classes.statusRejected]: isStatusRejected(customer.status),
                    [classes.statusRequesting]: isStatusRequesting(customer.status),
                  })}
                >
                  {listKYCStatus.find((element) => element.key === customer.status)?.value}
                </span>
              </Grid>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item container md={4}>
              <Grid item md={12}>
                <Typography variant="h6">{t("customer_management.heading_kyc_photos")}</Typography>
              </Grid>
              <Grid item md={12}>
                {customer.idoc_photo1_url ? (
                  <img
                    className={classes.photo}
                    src={customer.idoc_photo1_url}
                    alt="document"
                    onClick={() => toggleZoomPhotoModal(customer.idoc_photo1_url)}
                  />
                ) : (
                  CHART_EMPTY
                )}
              </Grid>
              <Grid item md={12}>
                {customer.idoc_photo2_url && (
                  <img
                    className={classes.photo}
                    src={customer.idoc_photo2_url}
                    alt="document"
                    onClick={() => toggleZoomPhotoModal(customer.idoc_photo2_url)}
                  />
                )}
              </Grid>
              <Grid item md={12}>
                {customer.idoc_video_url && (
                  <img
                    className={classes.photo}
                    src={customer.idoc_video_url}
                    alt="document"
                    onClick={() => toggleZoomPhotoModal(customer.idoc_video_url)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          {isPresentative(customer) && (
            <Fragment>
              <RepresentativeInfo
                data={customer}
                title={t("verification.text_business_card")}
                document={businessDocument}
                arrKeyPhotos={KEY_UPLOAD_BUSINESS_DETAIL}
              />
              <RepresentativeInfo
                title={t("verification.text_legal_entity_proof")}
                data={customer}
                document={legalDocument}
                arrKeyPhotos={KEY_UPLOAD_LEGAL_DETAIL}
              />
            </Fragment>
          )}

          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography variant="h6">{t("customer_management.heading_status_history")}</Typography>
            </Grid>
            <Grid item md={4}>
              <ContentChild label={t("customer_management.label_requested_by")} value={customer.requested_by_name} />
            </Grid>
            <Grid item md={4}>
              <ContentChild
                label={t("customer_management.label_requested_date")}
                value={formatDate(customer.requested_at)}
              />
            </Grid>
            {!isStatusRequesting(customer.status) && (
              <>
                <Grid item md={4}>
                  <ContentChild
                    label={t("customer_management.label_submitted_by")}
                    value={customer.submitted_by_name}
                  />
                </Grid>
                <Grid item md={4}>
                  <ContentChild
                    label={t("customer_management.label_submitted_date")}
                    value={formatDate(customer.submitted_at)}
                  />
                </Grid>
              </>
            )}
            {isStatusApproved(customer.status) && (
              <>
                <Grid item md={4}>
                  <ContentChild label={t("customer_management.label_approved_by")} value={customer.reviewed_by_name} />
                </Grid>
                <Grid item md={4}>
                  <ContentChild
                    label={t("customer_management.label_approved_at")}
                    value={formatDate(customer.reviewed_at)}
                  />
                </Grid>
              </>
            )}
            {isStatusRejected(customer.status) && (
              <>
                <Grid item md={4}>
                  <ContentChild label={t("customer_management.label_rejected_by")} value={customer.reviewed_by_name} />
                </Grid>
                <Grid item md={4}>
                  <ContentChild
                    label={t("customer_management.label_rejected_at")}
                    value={formatDate(customer.reviewed_at)}
                  />
                </Grid>
                <Grid item md={4}>
                  <ContentChild
                    className={classes.reasonContent}
                    label={t("customer_management.label_rejected_reason")}
                    value={customer.reject_reason}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <ShowForPermissionComponent userRoles={[roles.ADMIN]} condition={currentUserIsPIC}>
            <Box my={8} className={classes.action}>
              {isStatusVerifying(customer.status) && (
                <Button className="button button-transparent-border" onClick={toggleRejectModal}>
                  {t("customer_management.button_reject_kyc")}
                </Button>
              )}
              {isStatusVerifying(customer.status) && (
                <Button className="button button-gradient" onClick={toggleApproveModal}>
                  {t("customer_management.button_approve_kyc")}
                </Button>
              )}
            </Box>
            <Box my={8} className={classes.actionLeft}>
              {isStatusRequesting(customer.status) && (
                <Button className="button button-gradient" onClick={resendRequestKYC}>
                  {t("customer_management.text_resend_kyc_request")}
                </Button>
              )}
            </Box>
          </ShowForPermissionComponent>
        </TabPanel>
        <TabPanel className={classes.tabPanelKycEditHistory} value={tab} index={1}>
          <KYCeditHistory />
        </TabPanel>
        <TabPanel className={classes.tabPanelProfileEditHistory} value={tab} index={2}>
          <ProfileEditHistory />
        </TabPanel>
      </Box>
      <ZoomPhoto isOpening={isVisibleZoomPhotoModal} onToggle={toggleZoomPhotoModal} imageURL={zoomPhotoURL} />
      <SimpleModal isOpeningModal={isVisibleApproveModal} toggleModal={toggleApproveModal} boxClass={classes.modalRoot}>
        <Box className={classes.modal}>
          <Typography variant="h6">{t("customer_management.title_modal_approve_kyc")}</Typography>
          <Typography variant="body2" className={classes.modalDescription}>
            {t("customer_management.text_description_modal_approve_kyc")}
          </Typography>
          <Box className={classes.modalAction}>
            <Button className="button button-gradient" onClick={handleApproveKYC}>
              {t("customer_management.button_approve_kyc")}
            </Button>
            <Button className="button button-transparent" onClick={toggleApproveModal}>
              {t("component.button_cancel")}
            </Button>
          </Box>
        </Box>
      </SimpleModal>
      <SimpleModal isOpeningModal={isVisibleRejectModal} toggleModal={toggleRejectModal} boxClass={classes.modalRoot}>
        <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={handleRejectKYC}>
          {({ handleSubmit, setFieldValue }) => (
            <Form className={classes.rejectModal} onSubmit={handleSubmit}>
              <Typography variant="h6">{t("customer_management.title_modal_rejecte_kyc")}</Typography>
              <Typography variant="body2" className={classes.modalDescription}>
                {t("customer_management.text_description_modal_reject_kyc")}
              </Typography>
              <Field
                name="reason"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                onChange={handleChangeField(setFieldValue, "reason")}
                placeholder={t("customer_management.text_area_rejected_reason")}
                maxLength={MAX_LENGTH_TEXTAREA}
                component={InputComponent}
              />
              <Box className={classes.modalAction}>
                <Button type="submit" className="button button-gradient">
                  {t("component.button_reject")}
                </Button>
                <Button className="button button-transparent" onClick={toggleRejectModal}>
                  {t("component.button_cancel")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </SimpleModal>
      <EditCustomerForm
        initialValues={initialValuesCustomer}
        toggleModal={toggleEditCustomerModal}
        isVisible={isVisibleEditCustomerModal}
        departments={listDepartment}
        employees={listEmployee}
        onSubmit={handleUpdateCustomer}
        editable={userDetail?.role === roles.ADMIN || currentUserIsPIC}
        isEmployee={isEmployee}
        currentUserMapCustomer={currentUserMapCustomer}
      />
    </Box>
  );
}

export default CustomerPage;
