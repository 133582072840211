import { NO_FILTER } from "../common/constant";
import { api } from "./api";

export const getKYCCustomerAPI = (params) => {
  let paramsAddIdoc = params?.add_doc_types?.includes(NO_FILTER) ? [] : params?.add_doc_types;
  return api.post("manage/customer/filter", {
    ...params,
    add_doc_types: paramsAddIdoc,
  });
};

export const getDepartmentCompanyAPI = () => {
  return api.get("manage/company/departments/list");
};

export const createKYCRequestAPI = (body) => {
  return api.post("manage/kyc/create", body);
};

export const getCustomerInformationAPI = (id) => {
  return api.get(`manage/customer/${id}/info`);
};

export const updateKYCStatusAPI = ({ id, body }) => {
  return api.post(`manage/kyc/${id}/review`, body);
};

export const updateCustomerAPI = ({ id, body }) => {
  return api.put(`/manage/customer/${id}/update`, body);
};

export const exportListCustomerEndpoint = () => {
  return `/manage/customer/export-customers`;
};

export const resendRequestKYCAPI = (customerId) => {
  return api.post(`/manage/customer/${customerId}/resend-request-kyc`);
};
