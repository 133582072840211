export const ActionTypes = {
  GET_LIST_PROFILE_HISTORY_START: "GET_LIST_PROFILE_HISTORY_START",
  GET_LIST_PROFILE_HISTORY_SUCCESS: "GET_LIST_PROFILE_HISTORY_SUCCESS",
  GET_LIST_PROFILE_HISTORY_ERROR: "GET_LIST_PROFILE_HISTORY_ERROR",
  GET_DETAIL_PROFILE_HISTORY_START: "GET_DETAIL_PROFILE_HISTORY_START",
  GET_DETAIL_PROFILE_HISTORY_SUCCESS: "GET_DETAIL_PROFILE_HISTORY_SUCCESS",
  GET_DETAIL_PROFILE_HISTORY_ERROR: "GET_DETAIL_PROFILE_HISTORY_ERROR",
  UPDATE_CUSTOMER_INFORMATION_SUCCESS: "UPDATE_CUSTOMER_INFORMATION_SUCCESS",
};

export const actions = {
  getListProfileHistoryStart: (params, customerCode) => ({
    type: ActionTypes.GET_LIST_PROFILE_HISTORY_START,
    params,
    customerCode,
  }),
  getListProfileHistorySuccess: (data) => ({ type: ActionTypes.GET_LIST_PROFILE_HISTORY_SUCCESS, payload: data }),
  getListProfileHistoryError: (error) => ({ type: ActionTypes.GET_LIST_PROFILE_HISTORY_ERROR, payload: error }),
  getDetailKYCHistoryStart: (requestId) => ({ type: ActionTypes.GET_DETAIL_PROFILE_HISTORY_START, requestId }),
  getDetailKYCHistorySuccess: (data) => ({ type: ActionTypes.GET_DETAIL_PROFILE_HISTORY_SUCCESS, payload: data }),
  getDetailKYCHistoryError: (error) => ({ type: ActionTypes.GET_DETAIL_PROFILE_HISTORY_ERROR, payload: error }),
};
