import React from "react";
import { Route, Redirect } from "react-router";
import { useSelector } from "react-redux";

function PrivateRoute(props) {
  const { component: Component, roles, ...rest } = props;

  const isAuthenticated = useSelector((state) => state.authentication.currentUser?.access_token);
  const role = useSelector((state) => state.authentication.currentUser?.role);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
        }

        if (role && roles && roles.indexOf(role) === -1) {
          return <Redirect to="/access-denied" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default PrivateRoute;
