import i18n from "../i18n/i18n";

export const HTTP_RESPONSE = {
  OK: 200,
  ERROR_CODE_401: 401,
};

export const RESPONSE_STATUS = {
  SUCCESS: true,
  ERROR: false,
};

export const FORMAT_TIME = "HH:mm:ss";

export const TYPE_EXTRA_INFO = {
  NAME: "name",
  VALUE: "value",
};

export const TYPE_OF_ANT_DESIGN = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  IMG_DONE: "done",
};

export const drawerWidth = 320;

export const languages = [
  {
    key: "jp",
    value: "Japanese",
  },
  {
    key: "en",
    value: "English",
  },
];

export const CUSTOMER_TYPE_OPTIONS = [
  { key: "INVESTOR", value: i18n.t("customer_management.option_investor"), department: "DEVELOPMENT" },
  { key: "TENANT", value: i18n.t("customer_management.option_tenant"), department: "RENTAL" },
  {
    key: "INDIVIDUAL_CONSUMER",
    value: i18n.t("customer_management.option_actual_demand_individual"),
    department: "DEVELOPMENT",
  },
];

export const getCustomerTypeOptions = (t) => [
  { key: "INVESTOR", value: t("customer_management.option_investor"), department: "DEVELOPMENT" },
  { key: "TENANT", value: t("customer_management.option_tenant"), department: "RENTAL" },
  {
    key: "INDIVIDUAL_CONSUMER",
    value: t("customer_management.option_actual_demand_individual"),
    department: "DEVELOPMENT",
  },
];

export const getDepartmentOptions = (code, t = { i18n }) => {
  switch (code) {
    case "DEVELOPMENT":
      return t("component.text_department_development");
    case "RENTAL":
      return t("component.text_department_rental");
    default:
      break;
  }
};

export const CUSTOMER_TYPES = [
  { key: "INVESTOR", value: i18n.t("customer_management.option_investor") },
  { key: "TENANT", value: i18n.t("customer_management.option_tenant") },
  { key: "INDIVIDUAL_CONSUMER", value: i18n.t("customer_management.option_actual_demand_individual") },
];

export const DOCUMENT_TYPE_NAME = {
  DRIVER_LICENSE: "Driver License",
  PASSPORT: "Passport",
  BASIC_RESIDENT_REGISTER: "Basic Resident Register",
  HEALTH_INSURANCE_CARD: "Health Insurance Card",
  RESIDENCE_CARD: "Residence Card",
  FOREIGNER_REGISTRATION_CARD: "Foreigner Registration Card",
  SPECIAL_PERMANENT_RESIDENT: "Special Permanent Resident",
};

export const DOCUMENT_TYPE_OPTIONS = [
  {
    key: "DRIVER_LICENSE",
    value: i18n.t("customer_management.text_driver_license"),
  },
  {
    key: "PASSPORT",
    value: i18n.t("customer_management.text_passport"),
  },
  {
    key: "BASIC_RESIDENT_REGISTER",
    value: i18n.t("customer_management.text_basic_resident_register"),
  },
  {
    key: "HEALTH_INSURANCE_CARD",
    value: i18n.t("customer_management.text_health_insurance_card"),
  },
  {
    key: "RESIDENCE_CARD",
    value: i18n.t("customer_management.text_residence_card"),
  },
  {
    key: "FOREIGNER_REGISTRATION_CARD",
    value: i18n.t("customer_management.text_foreigner_registration_card"),
  },
  {
    key: "SPECIAL_PERMANENT_RESIDENT",
    value: i18n.t("customer_management.text_special_permanent_resident"),
  },
];

export const STATUS_KYC = {
  PENDING: "REQUESTING",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
};

export const KYC_STATUS_OPTIONS = (t) => [
  {
    key: "REQUESTING",
    value: t("search.text_requesting"),
  },
  {
    key: "VERIFYING",
    value: t("search.text_verifying"),
  },
  {
    key: "REJECTED",
    value: t("search.text_rejected"),
  },
  {
    key: "APPROVED",
    value: t("search.text_approved"),
  },
];

export const REQUEST_STATUS_OPTIONS = (t) => [
  {
    key: "REQUESTING",
    value: t("search.text_pending"),
  },
  {
    key: "REJECTED",
    value: t("search.text_rejected"),
  },
  {
    key: "APPROVED",
    value: t("search.text_approved"),
  },
];

export const LANGUAGE_OPTIONS = [
  {
    key: "jp",
    value: "Japanese",
  },
  { key: "en", value: "English" },
  { key: "scn", value: "Simplified Chinese" },
  { key: "cn", value: "Traditional Chinese" },
];

export const REGEX_NUMBER = /^[0-9\b]+$/;
export const REGEX_PHONE_NUMBER = /^[+0-9\b]+$/;

export const KYC_INFO_MODAL = (t) => [
  {
    label: i18n.t("list_kyc.label_customer_name"),
    prop: "customer_name",
  },
  {
    label: i18n.t("list_kyc.label_customer_id"),
    prop: "customer_code",
  },
  {
    label: i18n.t("list_kyc.label_department"),
    prop: "department_names",
  },
  {
    label: i18n.t("list_kyc.label_customer_type"),
    prop: "customer_types",
  },
  {
    label: i18n.t("list_kyc.label_identity_document"),
    prop: "idoc_type",
  },
  {
    label: i18n.t("search.add_document"),
    prop: "add_doc_types",
  },
];

export const KYC_INFO_REQUEST_MODAL = (t) => [
  {
    label: i18n.t("list_kyc.label_request_id"),
    prop: "code",
  },
  {
    label: i18n.t("list_kyc.label_request_date"),
    prop: "created_at",
  },
  {
    label: i18n.t("list_kyc.label_request_status"),
    prop: "status_dom",
  },
  {
    label: i18n.t("list_kyc.label_reason_update_kyc"),
    prop: "reason",
    col: 6,
  },
];

export const GET_DOCUMENT_TYPE_OPTIONS = (t) => [
  {
    key: "DRIVER_LICENSE",
    value: t("customer_management.text_driver_license"),
  },
  {
    key: "PASSPORT",
    value: t("customer_management.text_passport"),
  },
  {
    key: "BASIC_RESIDENT_REGISTER",
    value: t("customer_management.text_basic_resident_register"),
  },
  {
    key: "HEALTH_INSURANCE_CARD",
    value: t("customer_management.text_health_insurance_card"),
  },
  {
    key: "RESIDENCE_CARD",
    value: t("customer_management.text_residence_card"),
  },
  {
    key: "FOREIGNER_REGISTRATION_CARD",
    value: t("customer_management.text_foreigner_registration_card"),
  },
  {
    key: "SPECIAL_PERMANENT_RESIDENT",
    value: t("customer_management.text_special_permanent_resident"),
  },
];

export const EMPLOYEE_ROLES = (t) => [
  {
    key: "COMPANY_ADMIN",
    value: t("employee.text_role_admin"),
  },
  {
    key: "EMPLOYEE",
    value: t("employee.text_role_employee"),
  },
];

export const KYC_REQUEST_MODE = {
  VIEW: "view",
  APPROVE: "APPROVED",
  REJECT: "REJECTED",
};

export const PARAMS_FILTER_DEFAULT = {
  order: null,
  orderBy: null,
  page: 0,
  pageSize: 10,
};

export const BREAKPOINT = 1440;
export const BREAKPOINT_SMALL = 1280;

export const KEY_UPLOAD_HISTORY_DETAIL = ["idoc_photo1", "idoc_photo2", "idoc_video"];
export const KEY_UPLOAD_LEGAL_DETAIL = ["legal_entity_photo1", "legal_entity_photo2"];
export const KEY_UPLOAD_BUSINESS_DETAIL = ["business_card_photo1", "business_card_photo2"];
export const KEY_UPLOAD = [...KEY_UPLOAD_HISTORY_DETAIL, ...KEY_UPLOAD_LEGAL_DETAIL, ...KEY_UPLOAD_BUSINESS_DETAIL];

export const NOTIFICATION_TYPES = ["CUSTOMER_UPDATE_KYC", "CUSTOMER_REQUEST_EDIT_KYC", "EMPLOYEE_CHANGE_PIC"];
export const KYC_INFO_PAGE = [
  {
    label: i18n.t("list_kyc_history.full_name"),
    prop: "full_name",
  },
  {
    label: i18n.t("list_kyc_history.email"),
    prop: "email",
  },
  {
    label: i18n.t("customer_management.text_date_of_birth"),
    prop: "birthday",
  },
  {
    label: i18n.t("customer_management.text_phone_number"),
    prop: "phone",
  },
  {
    label: i18n.t("list_kyc_history.nationality"),
    prop: "nationality",
  },
  {
    label: i18n.t("customer_management.text_residential_address"),
    prop: "residential_address",
  },
];

export const KYC_INFO_IDENTITY_PAGE = [
  {
    label: i18n.t("list_kyc_history.idoc_type"),
    prop: "idoc_type_convert",
    showEmpty: true,
    renderByType: "all",
  },
  {
    label: false,
    paramsLabel: "idoc_type",
    customLabel: (t, idoc_type) => t(`list_kyc_history.idoc_number_${idoc_type}`),
    showEmpty: true,
    prop: "idoc_number",
    renderByType: [
      "DRIVER_LICENSE",
      "PASSPORT",
      "HEALTH_INSURANCE_CARD",
      "RESIDENCE_CARD",
      "FOREIGNER_REGISTRATION_CARD",
      "SPECIAL_PERMANENT_RESIDENT",
    ],
  },
  {
    label: i18n.t("list_kyc_history.full_name"),
    showEmpty: true,
    prop: "idoc_full_name",
    renderByType: [
      "DRIVER_LICENSE",
      "BASIC_RESIDENT_REGISTER",
      "HEALTH_INSURANCE_CARD",
      "RESIDENCE_CARD",
      "FOREIGNER_REGISTRATION_CARD",
      "SPECIAL_PERMANENT_RESIDENT",
    ],
  },
  {
    label: i18n.t("list_kyc_history.idoc_surname"),
    showEmpty: true,
    prop: "idoc_surname",
    renderByType: ["PASSPORT"],
  },
  {
    label: i18n.t("list_kyc_history.idoc_given_name"),
    showEmpty: true,
    prop: "idoc_given_name",
    renderByType: ["PASSPORT"],
  },
  {
    label: i18n.t("list_kyc_history.idoc_nationality"),
    showEmpty: true,
    prop: "idoc_nationality",
    renderByType: ["PASSPORT", "RESIDENCE_CARD", "SPECIAL_PERMANENT_RESIDENT", "FOREIGNER_REGISTRATION_CARD"],
  },
  {
    label: i18n.t("customer_management.text_date_of_birth"),
    showEmpty: true,
    prop: "idoc_birthday",
    renderByType: [
      "DRIVER_LICENSE",
      "PASSPORT",
      "BASIC_RESIDENT_REGISTER",
      "HEALTH_INSURANCE_CARD",
      "RESIDENCE_CARD",
      "FOREIGNER_REGISTRATION_CARD",
      "SPECIAL_PERMANENT_RESIDENT",
    ],
  },
  {
    label: i18n.t("list_kyc_history.idoc_gender"),
    showEmpty: true,
    prop: "idoc_gender",
    renderByType: [
      "PASSPORT",
      "BASIC_RESIDENT_REGISTER",
      "HEALTH_INSURANCE_CARD",
      "RESIDENCE_CARD",
      "FOREIGNER_REGISTRATION_CARD",
      "SPECIAL_PERMANENT_RESIDENT",
    ],
  },
  {
    label: i18n.t("customer_management.text_date_of_issue"),
    showEmpty: true,
    prop: "idoc_issued_date",
    renderByType: ["DRIVER_LICENSE", "PASSPORT"],
  },
  {
    label: i18n.t("list_kyc_history.idoc_applicable_date"),
    showEmpty: true,
    prop: "idoc_applicable_date",
    renderByType: ["HEALTH_INSURANCE_CARD"],
  },
  {
    label: i18n.t("customer_management.text_date_of_expiry"),
    showEmpty: true,
    prop: "idoc_expired_date",
    renderByType: [
      "DRIVER_LICENSE",
      "PASSPORT",
      "BASIC_RESIDENT_REGISTER",
      "HEALTH_INSURANCE_CARD",
      "RESIDENCE_CARD",
      "FOREIGNER_REGISTRATION_CARD",
      "SPECIAL_PERMANENT_RESIDENT",
    ],
  },
  {
    label: i18n.t("list_kyc_history.idoc_supply_no"),
    showEmpty: true,
    prop: "idoc_supply_no",
    renderByType: ["HEALTH_INSURANCE_CARD"],
  },
  {
    label: i18n.t("customer_management.text_address"),
    showEmpty: true,
    prop: "idoc_address",
    renderByType: [
      "DRIVER_LICENSE",
      "BASIC_RESIDENT_REGISTER",
      "HEALTH_INSURANCE_CARD",
      "FOREIGNER_REGISTRATION_CARD",
      "SPECIAL_PERMANENT_RESIDENT",
    ],
  },
  {
    label: i18n.t("customer_management.label_kyc_status"),
    showEmpty: true,
    prop: "status",
    renderByType: "all",
  },
];

export const KYC_INFO_PAGE_STATUS = [
  {
    label: i18n.t("customer_management.label_requested_by"),
    prop: "requested_by_name",
  },
  {
    label: i18n.t("customer_management.label_requested_date"),
    prop: "requested_at",
  },
  {
    label: i18n.t("customer_management.label_submitted_by"),
    prop: "submitted_by_name",
  },
  {
    label: i18n.t("customer_management.label_submitted_date"),
    prop: "submitted_at",
  },
  {
    label: i18n.t("customer_management.label_approved_by"),
    prop: "reviewed_by_name",
  },
  {
    label: i18n.t("customer_management.label_approved_at"),
    prop: "reviewed_at",
  },
];

export const PROFILE_TEXT_CHANGE = {
  department_names: "profile.text_change_department",
  customer_types: "profile.text_change_customer_type",
  lang: "profile.text_change_message_language",
  pic: "profile.text_change _person",
  phone: "profile.text_change_phone",
};

export const PROFILE_INFO = (t) => ({
  department_names: t("customer_management.text_department"),
  customer_types: t("customer_management.label_customer_type"),
  lang: t("customer_management.label_message_language"),
  pic: t("profile.text_company_full_name"),
  phone: t("list_kyc_history.phone"),
});

export const ACCEPT_IMAGE = ".jpg,.jpeg,.png";
export const ACCEPT_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
export const IMAGE_SIZE = 10 * 1024 * 1024;

export const PROFILE_PARAMS = (t) => [
  {
    label: t("employee.text_created_on"),
    prop: "created_at",
  },
  {
    label: t("employee.text_birthday"),
    prop: "birthday",
  },
  {
    label: t("employee.label_email"),
    prop: "email",
  },
  {
    label: t("employee.label_role"),
    prop: "role",
  },
  {
    label: t("employee.label_department"),
    prop: "department_code",
  },
];

export const GENDER_TYPE = {
  MALE: i18n.t("common.text_gender_male"),
  FEMALE: i18n.t("common.text_gender_female"),
};

export const GET_GENDER_TYPE = (t) => ({
  MALE: t("common.text_gender_male"),
  FEMALE: t("common.text_gender_female"),
});

export const MAX_LENGTH_INPUT = 254;
export const MAX_LENGTH_TEXTAREA = 1000;
export const REGEX_OPTIONAL_PARAM = /\w+\?$/;

export const OTP_TIME = 119;

export const COUNTRY_OPTIONS = ["VN", "JP", "GB", "CN"];
export const DEFAULT_COUNTRY = "JP";

export const END_POINT_EXPORT = {
  KYC_EDIT_REQUEST: "/manage/kyc-edit-request/export",
  KYC_HISTORY: (customerCode) => `/manage/customer/${customerCode}/export-kyc-history`,
  PROFILE_HISTORY: (customerCode) => `/manage/customer/${customerCode}/export-profile-edit-history`,
};

export const getDefaultPageSize = (t) => [
  {
    value: 10,
    label: t("component.text_page_size_options", { size: 10 }),
  },
  {
    value: 20,
    label: t("component.text_page_size_options", { size: 20 }),
  },
  {
    value: 30,
    label: t("component.text_page_size_options", { size: 30 }),
  },
];

export const TYPE_PASSWORD_PROCESS = {
  FORGOT: "FORGOT_PASSWORD",
  CHANGE: "CHANGE_PASSWORD",
};
export const NOTIFICATION_PAGE_SIZE = 20;
export const NOTIFICATION_OFFSET = 10;

export const getLanguageOptions = (t) => [
  {
    key: "jp",
    value: t("common.text_language_japan"),
  },
  { key: "en", value: t("common.text_language_english") },
  { key: "scn", value: t("common.text_language_chinese") },
  { key: "cn", value: t("common.text_language_traditional_chinese") },
];

export const getDocumentTypeOptions = (t) => [
  {
    key: "DRIVER_LICENSE",
    value: t("customer_management.text_driver_license"),
  },
  {
    key: "PASSPORT",
    value: t("customer_management.text_passport"),
  },
  {
    key: "BASIC_RESIDENT_REGISTER",
    value: t("customer_management.text_basic_resident_register"),
  },
  {
    key: "HEALTH_INSURANCE_CARD",
    value: t("customer_management.text_health_insurance_card"),
  },
  {
    key: "RESIDENCE_CARD",
    value: t("customer_management.text_residence_card"),
  },
  {
    key: "FOREIGNER_REGISTRATION_CARD",
    value: t("customer_management.text_foreigner_registration_card"),
  },
  {
    key: "SPECIAL_PERMANENT_RESIDENT",
    value: t("customer_management.text_special_permanent_resident"),
  },
];

export const getAddDocumentTypesOptions = (t) => [
  {
    key: "BUSINESS_CARD",
    value: t("verification.text_business_card"),
  },
  {
    key: "LEGAL_ENTITY_PROOF",
    value: t("verification.text_legal_entity_proof"),
  },
];

export const getDocumentTypeName = (t) => ({
  DRIVER_LICENSE: t("customer_management.text_driver_license"),
  PASSPORT: t("customer_management.text_passport"),
  BASIC_RESIDENT_REGISTER: t("customer_management.text_basic_resident_register"),
  HEALTH_INSURANCE_CARD: t("customer_management.text_health_insurance_card"),
  RESIDENCE_CARD: t("customer_management.text_residence_card"),
  FOREIGNER_REGISTRATION_CARD: t("customer_management.text_foreigner_registration_card"),
  SPECIAL_PERMANENT_RESIDENT: t("customer_management.text_special_permanent_resident"),
});
export const EMPTY_LIST_KYC = ["department_ids", "idoc_types", "status"];

export const NO_FILTER = "NO_FILTER";

export const SELECT_ALL = [NO_FILTER];

export const CHART_EMPTY = "---";
