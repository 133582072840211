import React from "react";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { SimpleModal } from "../../../components/Modal";
import { InputPhoneNumber, SelectComponent, SelectWithCheckboxComponent } from "../../../components/index";
import { DEFAULT_COUNTRY, getCustomerTypeOptions, getLanguageOptions, STATUS_KYC } from "../../../common/constant";
import AutocompleteComponent from "../../../components/Autocomplete";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  field: {
    marginTop: theme.spacing(2),
    textAlign: "left",

    "&.multiple-select .Mui-focused": {
      backgroundColor: theme.overrides.colorWhite,
      padding: "0 4px",
    },

    "&.multiple-select .MuiFormLabel-filled": {
      backgroundColor: theme.overrides.colorWhite,
      padding: "0 4px",
    },
  },
  boxAction: {
    display: "flex",
    flexWrap: "wrap",

    "& button": {
      flex: "1 1 260px",
      maxWidth: 260,
      margin: `${theme.spacing(2)}px auto 0 auto`,
    },
  },
  buttonCancel: {
    color: theme.overrides.colorMandalay,
  },
}));

function EditCustomerForm(props) {
  const { t } = useTranslation();
  const LANGUAGE_OPTIONS = getLanguageOptions(t);
  const CUSTOMER_TYPE_OPTIONS = getCustomerTypeOptions(t);
  const customer = useSelector((state) => state.customer.customer);

  const classes = useStyles();

  const validationSchema = Yup.object({}).shape({
    departments: Yup.string().required(t("message.MSG_4", { fieldName: t("customer_management.label_department") })),
    customerTypes: Yup.string().required(
      t("message.MSG_4", { fieldName: t("customer_management.label_customer_type") })
    ),
    personInCharge: Yup.string()
      .nullable()
      .trim()
      .required(t("message.MSG_4", { fieldName: t("customer_management.label_person_in_charge") })),
    language: Yup.string().required(t("message.MSG_4", { fieldName: t("customer_management.label_message_language") })),
  });

  const {
    title,
    isVisible,
    toggleModal,
    departments,
    initialValues,
    employees,
    onSubmit,
    editable,
    currentUserMapCustomer,
    isEmployee,
  } = props;

  const onChange =
    (setFieldValue, fieldName, setFieldTouched, values = {}) =>
    (value) => {
      setFieldValue(fieldName, value);
      if (fieldName === "departments") {
        setFieldValue("customerTypes", []);
        setFieldTouched("customerTypes", false);

        const { personInCharge } = values;

        const departmentID = employees.find((employee) => employee.key === personInCharge)?.department;

        if (!value.includes(departmentID)) {
          setFieldValue("personInCharge", []);
          setFieldTouched("personInCharge", false);
        }
      }
    };

  const onBlur = (setFieldTouched, fieldName) => () => {
    setFieldTouched(fieldName);
  };

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  const mapKeyToCode = (selectedDepartments) => {
    return departments.filter((department) => selectedDepartments.includes(department.key)).map((item) => item.code);
  };

  const filterOptions = (selectedDepartments) => {
    return CUSTOMER_TYPE_OPTIONS.filter((type) => mapKeyToCode(selectedDepartments).includes(type.department));
  };

  const filterEmployeeByDepartment = (employees, departments) => {
    return employees.filter(
      (employee) => employee.department === null || departments.indexOf(employee.department) > -1
    );
  };

  const onChangePhoneNumber = (setFieldValue, fieldName) => (value) => {
    setFieldValue(fieldName, value);
  };

  return (
    <SimpleModal isOpeningModal={isVisible} toggleModal={toggleModal} className={"kyc-request-form"} width={600}>
      <Box className={classes.root}>
        <Typography variant="h6">{title}</Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, handleSubmit, setFieldTouched, errors }) => (
            <Form>
              <Typography variant="h6">{t("customer_management.modal_edit_customer_title")}</Typography>
              <Field
                name="departments"
                labelField={t("customer_management.label_department")}
                className={`${classes.field} multiple-select`}
                options={departments}
                fieldValue={values.departments}
                fullFieldWidth
                onChange={onChange(setFieldValue, "departments", setFieldTouched, values)}
                onBlur={onBlur(setFieldTouched, "departments")}
                disabled={!editable || isEmployee}
                component={SelectWithCheckboxComponent}
              />
              <Field
                name="customerTypes"
                labelField={t("customer_management.label_customer_type")}
                className={`${classes.field} multiple-select`}
                options={filterOptions(values.departments)}
                fieldValue={values.customerTypes}
                fullFieldWidth
                disabled={!values.departments.length || !editable}
                onChange={onChange(setFieldValue, "customerTypes")}
                onBlur={onBlur(setFieldTouched, "customerTypes")}
                component={SelectWithCheckboxComponent}
              />
              <Field
                name="phone"
                label={t("customer_management.label_customer_phone_number")}
                placeholder={t("customer_management.placeholder_phone_number")}
                containerClass={`${classes.field} input_phone-number`}
                onChange={onChangePhoneNumber(setFieldValue, "phone")}
                value={values.phone}
                defaultCountry={DEFAULT_COUNTRY}
                isDisabled={customer?.status !== STATUS_KYC.PENDING}
                component={InputPhoneNumber}
              />
              <Field
                name="language"
                label={t("customer_management.label_message_language")}
                className={classes.field}
                options={LANGUAGE_OPTIONS}
                labelField={t("customer_management.label_message_language")}
                value={values.language}
                fullFieldWidth
                disabled={!editable}
                component={SelectComponent}
              />
              <Field
                name={"personInCharge"}
                label={t("customer_management.label_person_in_charge")}
                labelField={t("customer_management.label_person_in_charge")}
                className={classes.field}
                options={filterEmployeeByDepartment(employees, values.departments)}
                value={values.personInCharge}
                onChange={(event, newValue) => {
                  setFieldValue("personInCharge", newValue);
                }}
                fullFieldWidth
                disabled={!currentUserMapCustomer}
                component={AutocompleteComponent}
              />
              <Box m={2} className={classes.boxAction}>
                <Button type="submit" className="button button-gradient" onClick={handleSubmit}>
                  {t("component.button_save")}
                </Button>
                <Button onClick={toggleModal} className={classes.buttonCancel}>
                  {t("component.button_cancel")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </SimpleModal>
  );
}

EditCustomerForm.propTypes = {
  // initialValues: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

EditCustomerForm.defaultProps = {
  // initialValues: {},
  isVisible: false,
  toggleModal: () => {},
};

export default EditCustomerForm;
