import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Badge,
  Box,
  IconButton,
  makeStyles,
  List,
  ListItem,
  SwipeableDrawer,
  Typography,
  Button,
} from "@material-ui/core";
import { Notifications as NotificationsIcon, Close as CloseIcon } from "@material-ui/icons";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";

import { NOTIFICATION_PAGE_SIZE, NOTIFICATION_TYPES, NOTIFICATION_OFFSET, FORMAT_TIME } from "../../../common/constant";
import { formatDate } from "../../../utils";
import { actions } from "../../../pages/layout/actions";

const useStyles = makeStyles((theme) => ({
  badge: {
    marginRight: theme.spacing(2),

    "& .MuiSvgIcon-root": {
      color: theme.overrides.colorWhite,
    },
    "& .MuiBadge-badge": {
      backgroundColor: theme.overrides.colorBurntSienna,
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2, 0),
  },
  swipeableDrawer: {
    overflowY: "hidden",
  },
  container: {
    overflowY: "auto",
    height: "100vh",
  },
  list: {
    width: 450,
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    flexDirection: "column",
    alignItems: "start",
  },
  listItemUnRead: {
    backgroundColor: theme.overrides.colorConcrete,
  },
  time: {
    display: "flex",

    "& > .date": {
      marginRight: theme.spacing(1),
    },
  },
  loadMore: {
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "10px",
  },
  endMessage: {
    textAlign: "center",
  },
}));

function Notification(props) {
  const { notifications, getNotifications } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [notificationLength, setNotificationLength] = useState(NOTIFICATION_PAGE_SIZE);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const userCode = useSelector((state) => state.userDetail.userDetail?.code);

  const handleOpen = useCallback(() => {
    setIsVisible((prevState) => !prevState);
  }, [isVisible]);

  const readAllNotification = () => {
    dispatch(actions.readNotificationStart(null));
  };

  const readNotification = (id, link) => () => {
    dispatch(actions.readNotificationStart([id]));
    history.push(link);
    handleOpen();
  };

  const renderTime = (date) => {
    const timeDiff = moment(new Date()).diff(date, "hour");

    return (
      <Box className={classes.time}>
        <Typography className="date" variant="body2">
          {formatDate(date)}
        </Typography>
        <Typography variant="body2">
          {timeDiff > 1 ? moment(date).format(FORMAT_TIME) : moment(date).fromNow()}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (notifications?.total && notificationLength >= notifications.total) {
      setHasMore(false);
    }
  }, [notifications]);

  const fetchMoreData = () => {
    setNotificationLength((prevState) => {
      const newState = prevState + NOTIFICATION_OFFSET;
      getNotifications(newState);
      return newState;
    });
  };

  const renderNotification = (notification) => {
    const { notification_type, sender_code, sender_name, created_at, read, id, data } = notification;

    switch (notification_type) {
      case NOTIFICATION_TYPES[0]:
        return (
          <ListItem
            className={clsx(classes.listItem, { [classes.listItemUnRead]: !read })}
            button
            onClick={readNotification(id, `/customers/${sender_code}`)}
          >
            <Typography variant="subtitle1">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("component.notification_customer_update_kyc", { customerCode: sender_code }),
                }}
              ></div>
            </Typography>
            {renderTime(created_at)}
          </ListItem>
        );
      case NOTIFICATION_TYPES[1]:
        return (
          <ListItem
            button
            className={clsx(classes.listItem, { [classes.listItemUnRead]: !read })}
            onClick={readNotification(id, `/list-kyc/detail/${data?.request_id}`)}
          >
            <Typography variant="subtitle1">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("component.notification_customer_request_edit_kyc", {
                    customerCode: sender_code,
                    requestCode: data?.request_code,
                  }),
                }}
              ></div>
            </Typography>
            {renderTime(created_at)}
          </ListItem>
        );
      case NOTIFICATION_TYPES[2]:
        return (
          <ListItem
            button
            className={clsx(classes.listItem, { [classes.listItemUnRead]: !read })}
            onClick={readNotification(id, `/customers/${data?.customer_code}`)}
          >
            <Typography variant="subtitle1">
              {sender_code !== data?.new_pic_code && userCode === data?.new_pic_code && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("component.notification_employee_received_pic", {
                      employeeName: sender_name,
                      customerCode: data?.customer_code,
                    }),
                  }}
                ></div>
              )}
              {sender_code !== data?.new_pic_code && userCode === data?.old_pic_code && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("component.notification_employee_change_pic", {
                      employeeName: sender_name,
                      customerCode: data?.customer_code,
                      receivedName: data?.new_pic_name,
                    }),
                  }}
                ></div>
              )}
              {sender_code === data?.new_pic_code && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("component.notification_other_employee_change_pic", {
                      employeeName: sender_name,
                      customerCode: data?.customer_code,
                    }),
                  }}
                ></div>
              )}
            </Typography>
            {renderTime(created_at)}
          </ListItem>
        );

      default:
        break;
    }
  };

  return (
    <Box>
      <IconButton className={classes.badge} onClick={handleOpen}>
        <Badge
          color="secondary"
          variant="dot"
          invisible={!notifications?.total || notifications?.data?.every((notification) => notification.read)}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={isVisible}
        onClose={handleOpen}
        onOpen={handleOpen}
        className={classes.swipeableDrawer}
      >
        <Box id="notification" className={classes.container}>
          <Box className={classes.top}>
            <Typography variant="h6">{t("component.title_notification")}</Typography>
            <IconButton onClick={handleOpen}>
              <CloseIcon />
            </IconButton>
          </Box>
          {!!notifications?.total && (
            <Button onClick={readAllNotification} fullWidth>
              {t("component.text_read_all")}
            </Button>
          )}
          <InfiniteScroll
            dataLength={notificationLength}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              notifications?.total > NOTIFICATION_PAGE_SIZE && (
                <Box className={classes.loadMore}>
                  <CircularProgress color="primary" size={30} />
                </Box>
              )
            }
            scrollableTarget="notification"
          >
            <List component="nav" className={classes.list} aria-label="contacts">
              {notifications?.data?.map((notification, index) => (
                <Fragment key={index}>{renderNotification(notification)}</Fragment>
              ))}
            </List>
          </InfiniteScroll>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default Notification;
