import { Avatar, Box, Button, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import * as Yup from "yup";

import { InputComponent, SimpleModal, InputPhoneNumber } from "../../../components";
import { ACCEPT_IMAGE, DEFAULT_COUNTRY, MAX_LENGTH_INPUT } from "../../../common/constant";
import { getBase64, validateFile } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(2),
  },
  action: {
    width: "50%",
    marginTop: theme.spacing(4),

    "& .button": {
      width: "100%",

      "&:not(:first-child)": {
        marginTop: theme.spacing(1),
      },
    },
  },
  label: {
    marginTop: theme.spacing(1),
    display: "block",
  },
  avatar: {
    width: "100%",
    height: "auto",
    border: "1px solid #BC8F58",
  },
}));

function SettingCompanyModal(props) {
  const { company = {}, onSubmit, isVisible, toggleModal } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const [initialValues, setInitialValues] = useState({
    name: "",
    address: "",
    contactNo: "",
    email: "",
    postalCode: "",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .nullable()
      .trim()
      .required(t("message.MSG_4", { fieldName: t("setting.label_company_name") })),
    address: Yup.string()
      .nullable()
      .trim()
      .required(t("message.MSG_4", { fieldName: t("setting.label_address") })),
    contactNo: Yup.mixed()
      .nullable()
      .test("contactNo", function (value) {
        return (
          (value?.length < 4 &&
            this.createError({
              message: t("message.MSG_4", { fieldName: t("setting.label_contact_number") }),
            })) ||
          (value?.length < 11 &&
            this.createError({
              message: t("message.MSG_39"),
            })) ||
          true
        );
      })
      .required(t("message.MSG_4", { fieldName: t("setting.label_contact_number") })),
    email: Yup.string()
      .nullable()
      .trim()
      .email(t("message.MSG_2"))
      .required(t("message.MSG_4", { fieldName: t("setting.label_company_email") })),
  });

  useEffect(() => {
    if (!isEmpty(company)) {
      const { name, address, contact_no, email, postal_code, logo_url } = company;

      setInitialValues({
        name: name || "",
        address: address || "",
        contactNo: contact_no || "",
        email: email || "",
        postalCode: postal_code || "",
        logoUrl: logo_url || "",
      });
    }
  }, [company]);

  const handleChangePhoneNumber = (setFieldValue, setFieldTouched) => (value) => {
    setFieldValue("contactNo", value);
    setFieldTouched("contactNo", true);
  };

  const handleUpload = (setFieldValue) => (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (validateFile(file)) {
      setFieldValue(name, file);
      getBase64(file, (uri) => {
        setFieldValue("logoUrl", uri);
      });
    }
  };

  const _onSubmit = (data) => {
    if (!onSubmit) return;
    onSubmit(data);
  };

  return (
    <SimpleModal isOpeningModal={isVisible} toggleModal={toggleModal} width={800}>
      <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={_onSubmit}>
        {({ setFieldValue, setFieldTouched, values }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item md={3}>
                <Avatar className={classes.avatar} alt={values.name} variant="square" src={values.logoUrl} />
                <Field>
                  {() => (
                    <>
                      <input
                        accept={ACCEPT_IMAGE}
                        name="logoFile"
                        id={"avatar"}
                        className="hide"
                        type="file"
                        onChange={handleUpload(setFieldValue)}
                      />
                      <label htmlFor={"avatar"} className={`button-label ${classes.label}`}>
                        <Button component="div" className="button button-transparent-border" fullWidth>
                          {t("setting.button_upload_photo")}
                        </Button>
                      </label>
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={8}>
                <Field
                  name="name"
                  label={t("setting.label_company_name")}
                  placeholder={t("setting.placeholder_company_name")}
                  fullWidth
                  maxLength={MAX_LENGTH_INPUT}
                  component={InputComponent}
                />
                <Field
                  name="address"
                  className={classes.field}
                  label={t("setting.label_address")}
                  placeholder={t("setting.placeholder_address")}
                  fullWidth
                  maxLength={MAX_LENGTH_INPUT}
                  component={InputComponent}
                />
                <Field
                  name="contactNo"
                  label={t("setting.label_contact_number")}
                  placeholder={t("setting.placeholder_contact_number")}
                  containerClass={classes.field}
                  defaultCountry={DEFAULT_COUNTRY}
                  onChange={handleChangePhoneNumber(setFieldValue, setFieldTouched)}
                  value={values.contactNo}
                  maxLength={MAX_LENGTH_INPUT}
                  component={InputPhoneNumber}
                />
                <Field
                  name="email"
                  label={t("setting.label_company_email")}
                  placeholder={t("setting.placeholder_company_email")}
                  className={classes.field}
                  fullWidth
                  maxLength={MAX_LENGTH_INPUT}
                  component={InputComponent}
                />
                <Field
                  name="postalCode"
                  label={t("setting.label_postal_code")}
                  placeholder={t("setting.placeholder_postal_code")}
                  className={classes.field}
                  fullWidth
                  maxLength={100}
                  component={InputComponent}
                />
                <Box className={classes.action}>
                  <Button type="submit" className="button button-gradient">
                    {t("component.button_save")}
                  </Button>
                  <Button className="button button-transparent" onClick={toggleModal}>
                    {t("component.button_cancel")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SimpleModal>
  );
}

export default SettingCompanyModal;
