import { Button } from "@material-ui/core";
import React, { memo } from "react";
import { GET_DOCUMENT_TYPE_OPTIONS, GET_GENDER_TYPE } from "../../../common/constant";
import { SimpleModal } from "../../../components/Modal";
import { formatDate } from "../../../utils";
import { renderStatusKYC } from "../KYCeditHistory/style";
import _, { find } from "lodash";
import ZoomPhotoChildImage from "../../../components/ZoomPhoto/childImage";
import EmptyData from "../../../components/EmptyData";
import countries from "../../../utils/countries";

function ModalKycHistoryDetail({
  toggleModal,
  visible,
  t,
  classes,
  requestDetail,
  dataChange: { idData, customerCode, dataNormal, dataUpload },
}) {
  let changeIdocType = false;
  let dataDocType = {};

  dataNormal = dataNormal?.map((item) => {
    let oldValue = item?.old_version_value;
    let newValue = item?.new_version_value;
    if (item.key === "idoc_type") {
      changeIdocType = true;
      dataDocType = {
        ...item,
        key_new_value: newValue,
        key_old_value: oldValue,
        old_version_value: oldValue ? _.find(GET_DOCUMENT_TYPE_OPTIONS(t), { key: oldValue.toUpperCase() })?.value : "",
        new_version_value: newValue ? _.find(GET_DOCUMENT_TYPE_OPTIONS(t), { key: newValue.toUpperCase() })?.value : "",
      };
      return dataDocType;
    }
    if (item.key === "idoc_gender") {
      return {
        ...item,
        old_version_value: oldValue ? GET_GENDER_TYPE(t)[oldValue.toUpperCase()] : "",
        new_version_value: newValue ? GET_GENDER_TYPE(t)[newValue.toUpperCase()] : "",
      };
    }
    if (item.key === "idoc_nationality") {
      return {
        ...item,
        old_version_value: oldValue ? _.find(countries, { key: oldValue }).value : "",
        new_version_value: newValue ? _.find(countries, { key: newValue }).value : "",
      };
    }
    return item;
  });

  const openTabViewKYCHistory = (id) => () => {
    window.open(`/kyc-history/${customerCode}/${id}`, "_blank");
  };

  return (
    <SimpleModal
      title={t("list_kyc_history.title_modal_kyc_edit_detail")}
      toggleModal={toggleModal}
      width={1140}
      boxClass={classes.boxModalKycHistory}
      isOpeningModal={visible}
    >
      <table className="app-table table-kyc-request-detail">
        <thead>
          <tr>
            <th className="center">{t("list_kyc_history.label_request_id")}</th>
            <th className="center">{t("list_kyc_history.label_edited_date")}</th>
            <th className="center">{t("list_kyc_history.label_reason")}</th>
            <th className="center">{t("list_kyc_history.label_status")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="center" rowSpan="2">
              {requestDetail?.request_code}
            </td>
            <td className="center" rowSpan="2">
              {formatDate(requestDetail?.created_at)}
            </td>
            <td className="center" rowSpan="2">
              {requestDetail?.reason}
            </td>
            <td className="center">{renderStatusKYC(classes, requestDetail?.status, t)}</td>
          </tr>
          <tr>
            <td className="center">{requestDetail?.reject_reason ? requestDetail.reject_reason : ""}</td>
          </tr>
        </tbody>
      </table>
      <table className="app-table table-kyc-history-info">
        <thead>
          <tr>
            <th rowSpan="2" className="left col-info-label">
              {t("list_kyc_history.title_table_information")}
            </th>
            <th colSpan="2" className="center">
              {t("list_kyc_history.title_table_changes")}
            </th>
          </tr>
          <tr>
            <th className="center" style={{ width: `410px` }}>
              {t("list_kyc_history.title_table_before")}
              <span onClick={openTabViewKYCHistory(idData?.old_version_value)} className="view-item">
                {t("list_kyc_history.label_view")}
              </span>
            </th>
            <th className="center unset-border-left" style={{ width: `410px` }}>
              {t("list_kyc_history.title_table_after")}
              <span onClick={openTabViewKYCHistory(idData?.new_version_value)} className="view-item">
                {t("list_kyc_history.label_view")}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {dataNormal?.map((item) => {
            if (item?.key === "idoc_number" && changeIdocType) {
              const idocType = find(dataNormal, { key: "idoc_type" });
              const validateDocumentType = ["DRIVER_LICENSE", "PASSPORT"].some(
                (item) => item === idocType.key_old_value || item === idocType.key_new_value
              );
              return (
                <>
                  {validateDocumentType ? (
                    <>
                      {item.old_version_value && (
                        <tr key={`${item.key}-${dataDocType.key_old_value}`}>
                          <td className="info-item">
                            {t(`list_kyc_history.idoc_number_${dataDocType?.key_old_value}`)}
                          </td>
                          <td className="center info-item old">{item?.old_version_value}</td>
                          <td className="center info-item"></td>
                        </tr>
                      )}
                      {item.new_version_value && (
                        <tr key={`${item.key}-${dataDocType.key_old_value}`}>
                          <td className="info-item">
                            {t(`list_kyc_history.idoc_number_${dataDocType?.key_new_value}`)}
                          </td>
                          <td className="center info-item old"></td>
                          <td className="center info-item">{item?.new_version_value}</td>
                        </tr>
                      )}
                    </>
                  ) : (
                    (item.new_version_value || item.old_version_value) && (
                      <tr key={`${item.key}-${dataDocType.key_old_value}`}>
                        <td className="info-item">{t(`list_kyc_history.idoc_number_${dataDocType?.key_new_value}`)}</td>
                        <td className="center info-item old">{item?.old_version_value}</td>
                        <td className="center info-item">{item?.new_version_value}</td>
                      </tr>
                    )
                  )}
                </>
              );
            } else {
              let label =
                item?.key === "idoc_number" && !dataDocType?.key_new_value
                  ? `${item.key}_${requestDetail?.idoc_type}`
                  : item?.key;

              return (
                <tr key={item?.key}>
                  <td className="info-item">{t(`list_kyc_history.${label}`)}</td>
                  <td className="center info-item old">
                    {item?.old_version_value &&
                    ["idoc_birthday", "idoc_issued_date", "idoc_expired_date", "idoc_applicable_date"].includes(
                      item?.key
                    )
                      ? formatDate(item?.old_version_value)
                      : item?.old_version_value}
                  </td>
                  <td className="center info-item">
                    {item?.new_version_value &&
                    ["idoc_birthday", "idoc_issued_date", "idoc_expired_date", "idoc_applicable_date"].includes(
                      item?.key
                    )
                      ? formatDate(item?.new_version_value)
                      : item?.new_version_value}
                  </td>
                </tr>
              );
            }
          })}
          {dataUpload?.map((item, index) => {
            return (
              <tr>
                {!index && (
                  <td rowSpan={dataUpload?.length} className="info-item">
                    {t("list_kyc_history.upload_photo")}
                  </td>
                )}
                <td className="center info-item old">
                  {item?.old_version_value && (
                    <ZoomPhotoChildImage width={200} alt={item.key} src={item.old_version_value} />
                  )}
                </td>
                <td className="center info-item">
                  {item?.new_version_value && (
                    <ZoomPhotoChildImage width={200} alt={item.key} src={item.new_version_value} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {!dataUpload?.length && !dataNormal?.length && <EmptyData text={t("common.no_data")} />}
      <Button onClick={toggleModal} className={`button block button-gradient ${classes.buttonCloseModalDetail}`}>
        {t("common.text_close")}
      </Button>
    </SimpleModal>
  );
}

export default memo(ModalKycHistoryDetail);
