import { ActionTypes } from "./actions";
import { ActionTypes as LoginActionTypes } from "../login/actions";
const DEFAULT_STATE = {
  userDetail: {},
  changPassword: 0,
  resetVerifyCount: 0
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.RESET_FOLLOW_CHANGE_PASSWORD: 
    case LoginActionTypes.USER_LOGOUT: 
    return {
      userDetail: state.userDetail,
      changPassword: 0,
      resetVerifyCount: 0
    }
    case ActionTypes.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
      };
    case ActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
      };
    case ActionTypes.CREATE_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        verifyData: action.payload,
        resetVerifyCount: state.resetVerifyCount + 1,
      };
    case ActionTypes.CONFIRM_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        confirmData: action.payload,
      };
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changPassword: state.changPassword + 1,
      };
    default:
      return state;
  }
};
