import { Button } from "@material-ui/core";
import React, { memo } from "react";
import { SimpleModal } from "../../../../components/Modal";
import _ from "lodash";
import { renderChangeArray, getValueType } from "../style";
import {
  getCustomerTypeOptions,
  getDepartmentOptions,
  getLanguageOptions,
  PROFILE_INFO,
} from "../../../../common/constant";
import { formatDate } from "../../../../utils";

function ModalKycHistoryDetail({ toggleModal, visible, t, classes, profileHistory }) {
  const dataNormal = profileHistory?.change_logs?.map((item) => {
    switch (item.key) {
      case "department_names":
        return {
          ...item,
          old_version_value: renderChangeArray(
            item.old_version_value.map((item) => getDepartmentOptions(item.toUpperCase(), t))
          ),
          new_version_value: renderChangeArray(
            item.new_version_value.map((item) => getDepartmentOptions(item.toUpperCase(), t))
          ),
        };
      case "customer_types":
        return {
          ...item,
          old_version_value: renderChangeArray(item.old_version_value, getCustomerTypeOptions(t)),
          new_version_value: renderChangeArray(item.new_version_value, getCustomerTypeOptions(t)),
        };
      case "lang":
        return {
          ...item,
          old_version_value: getValueType(getLanguageOptions(t), item.old_version_value),
          new_version_value: getValueType(getLanguageOptions(t), item.new_version_value),
        };
      default:
        return item;
    }
  });

  return (
    <SimpleModal
      title={t("profile.title_modal_profile_detail")}
      toggleModal={toggleModal}
      width={1140}
      boxClass={classes.boxModalKycHistory}
      isOpeningModal={visible}
    >
      <table className="app-table table-kyc-request-detail">
        <thead>
          <tr>
            <th className="center">{t("employee.label_employee_id")}</th>
            <th className="center">{t("profile.label_edit_by")}</th>
            <th className="center">{t("list_kyc_history.label_edited_date")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="center" rowSpan="2">
              {profileHistory.employee_code}
            </td>
            <td className="center" rowSpan="2">
              {profileHistory.employee_name}
            </td>
            <td className="center" rowSpan="2">
              {formatDate(profileHistory.updated_date)}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="app-table table-kyc-history-info">
        <thead>
          <tr>
            <th rowSpan="2" className="left col-info-label">
              {t("list_kyc_history.title_table_information")}
            </th>
            <th colSpan="2" className="center">
              {t("list_kyc_history.title_table_changes")}
            </th>
          </tr>
          <tr>
            <th className="center" style={{ width: `410px` }}>
              {t("list_kyc_history.title_table_before")}
            </th>
            <th className="center unset-border-left" style={{ width: `410px` }}>
              {t("list_kyc_history.title_table_after")}
            </th>
          </tr>
        </thead>
        <tbody>
          {dataNormal?.map((item) => (
            <tr key={item?.key}>
              <td className="center info-item">{PROFILE_INFO(t)[item.key]}</td>
              <td className="center info-item old">{item?.old_version_value}</td>
              <td className="center info-item">{item?.new_version_value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button onClick={toggleModal} className={`button block button-gradient ${classes.buttonCloseModalDetail}`}>
        {t("common.text_close")}
      </Button>
    </SimpleModal>
  );
}

export default memo(ModalKycHistoryDetail);
