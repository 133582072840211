import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2H5zm12 3a1 1 0 00-1 1v10a1 1 0 102 0V7a1 1 0 00-1-1zm-5 4a1 1 0 00-1 1v6a1 1 0 102 0v-6a1 1 0 00-1-1zm-6 5a1 1 0 112 0v2a1 1 0 11-2 0v-2z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgComponent;
