import { put, takeLatest } from "redux-saga/effects";
import { actions, ActionTypes } from "./actions";
import { getListProfileHistoryAPI } from "../../../services/KYCRequest";
import { RESPONSE_STATUS } from "../../../common/constant";
import { actions as appActions } from "../../../pages/layout/actions";

function* getListProfileHistory({ params, customerCode }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getListProfileHistoryAPI(params, customerCode);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListProfileHistorySuccess(response.data));
    } else {
      yield put(actions.getListProfileHistoryError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getListProfileHistoryError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchProfileHistory() {
  yield takeLatest(ActionTypes.GET_LIST_PROFILE_HISTORY_START, getListProfileHistory);
}

