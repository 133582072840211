import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  requests: {
    data: [],
  },
  kycDetail: {},
};

export default (state = DEFAULT_STATE, action) => {
  const { requests } = state;
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CHANGE_EMPTY_LIST_KYC_DATA:
      return {
        ...state,
        requests: {
          data: [],
        },
      };
    case ActionTypes.GET_LIST_REQUEST_KYC_START:
      return {
        ...state,
        kycDetail: {},
      };
    case ActionTypes.GET_LIST_REQUEST_KYC_SUCCESS:
      return {
        ...state,
        requests: payload,
      };
    case ActionTypes.GET_DETAIL_KYC_SUCCESS:
      return {
        ...state,
        kycDetail: payload,
      };
    case ActionTypes.PUT_CONFIRM_KYC_REQUEST_SUCCESS: {
      return {
        ...state,
        kycDetail: payload,
        requests: {
          ...requests,
          data: requests.data.map(item => {
            return item.id === payload.id ? payload : item;
          }),
        },
      };
    }
    default:
      return state;
  }
};
