import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Grid, IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { useSnackbar } from "notistack";
import AddIcon from "@material-ui/icons/Add";

import { TableComponent } from "../../components";
import { actions, ActionTypes } from "./actions";
import { actions as customerActions } from "../home/actions";
import employeeModel from "./model";
import CreateEmployeeModal from "./createForm";
import { EMPLOYEE_ROLES, SELECT_ALL, getDepartmentOptions } from "../../common/constant";
import SearchComponent from "./search";
import { compareShallowObject, formatANumber, getSuffixCSVFile } from "../../utils";
import { AuthenticatedLink } from "../../components/AuthenticatedLink";
import { exportListEmployeeEndpoint } from "../../services/EmployeeRequest";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: `-${theme.spacing(3)}px`,
  },
  boxTop: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  right: {
    textAlign: "right",
  },
  button: {
    borderRadius: 0,
    background: "linear-gradient(129.66deg, #BB9043 0%, #DDA74F 64.36%, #BB9043 132.87%)",
    color: theme.overrides.colorWhite,

    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
    },

    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
  link: {
    color: theme.overrides.colorAzureRadiance,
  },
  searchContainer: {
    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.05)",
    padding: theme.spacing(5, 3),
  },
}));

function EmployeePage() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const { field } = employeeModel;
  const { searchText, employeeName, id, role, departmentName, email, departmentID, type } = field;

  const listEmployeeRole = EMPLOYEE_ROLES(t);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const employees = useSelector((state) => state.employee.employees);
  const requestStatus = useSelector((state) => state.employee.type);
  const departments = useSelector((state) => state.customer.departments);

  const defaultFilters = {
    order: null,
    orderBy: null,
    page: 0,
    pageSize: 10,
  };
  const defaultSearchTerm = {
    [searchText]: "",
    [role]: SELECT_ALL,
    [departmentID]: SELECT_ALL,
  };

  const [initialValuesSearch, setInitialValuesSearch] = useState({
    [searchText]: "",
    [departmentID]: "",
    [role]: listEmployeeRole.map((employee) => employee.key),
  });

  const [filters, setFilters] = useState(defaultFilters);
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);
  const [isVisibleCreateForm, setIsVisibleCreateForm] = useState(false);
  const [listDepartment, setListDepartment] = useState([]);

  const columns = [
    { dataIndex: "no", align: "center", label: t("customer_management.text_no"), isSorter: false },
    { dataIndex: id, align: "left", label: t("employee.label_employee_id"), isSorter: true, width: 150, minWidth: 100 },
    {
      dataIndex: employeeName,
      align: "left",
      label: t("employee.label_employee_name"),
      isSorter: true,
      width: 180,
      minWidth: 130,
      ellipsis: true,
    },
    { dataIndex: type, align: "center", label: t("employee.label_role"), isSorter: false },
    { dataIndex: departmentName, align: "center", label: t("employee.label_department"), isSorter: false },
    {
      dataIndex: email,
      align: "left",
      label: t("employee.label_email"),
      isSorter: false,
      width: 200,
      minWidth: 100,
      ellipsis: true,
    },
    { dataIndex: "action", align: "center", label: t("employee.label_action"), isSorter: false },
  ];

  const dataSource = employees.data.map((item, index) => {
    const { page, pageSize } = filters;

    return {
      no: formatANumber(index + 1 + page * pageSize),
      [id]: item[id],
      [employeeName]: item[employeeName],
      [departmentName]: getDepartmentOptions(item[departmentName]?.toUpperCase(), t),
      [type]: listEmployeeRole.find((employee) => employee.key === item[type])?.value,
      [email]: item[email],
      action: (
        <Link to={`/employees/${item[id]}`} className={classes.link}>
          {t("customer_management.button_view_detail")}
        </Link>
      ),
    };
  });

  const toggleCreateFormModal = useCallback(() => {
    setIsVisibleCreateForm(!isVisibleCreateForm);
  }, [isVisibleCreateForm]);

  const handleChangePage = (page) => {
    setFilters({
      ...filters,
      page,
    });
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setFilters({
      ...filters,
      page: 0,
      pageSize,
    });
  };

  const handleFilters = (data) => {
    setFilters({
      ...filters,
      ...data,
    });
  };

  useEffect(() => {
    dispatch(customerActions.getDepartmentCompanyStart());
  }, [dispatch]);

  useEffect(() => {
    if (departments?.length) {
      const newData = departments.map((item) => ({
        key: item.id + "",
        value: getDepartmentOptions(item.code, t),
        code: item.code,
      }));
      setListDepartment(newData);
      setInitialValuesSearch((prevState) => ({
        ...prevState,
        [departmentID]: newData.map((item) => item.key),
      }));
    }
  }, [departments, t]);

  useEffect(() => {
    const { order, orderBy, page, pageSize } = filters;
    dispatch(
      actions.filterListEmployeeStart({
        ...searchTerm,
        page: page + 1,
        page_size: pageSize,
        order_type: order,
        order_by: orderBy,
      })
    );
  }, [dispatch, filters, searchTerm]);

  const handleSubmit = (data) => {
    const { dateOfBirth, fullName, email, role, department } = data;
    dispatch(
      actions.createEmployeeStart({
        birthday: dateOfBirth,
        full_name: fullName.trim(),
        email: email.trim(),
        user_type: role,
        department_id: department || null,
        lang: i18n.language,
      })
    );
  };

  useEffect(() => {
    if (requestStatus === ActionTypes.CREATE_EMPLOYEE_SUCCESS) {
      toggleCreateFormModal();
      enqueueSnackbar(t("message.MSG_31"), { variant: "success" });
      dispatch(actions.resetTypeOfAction());

      setFilters(defaultFilters);

      setSearchTerm(defaultSearchTerm);

      const { order, orderBy, page, pageSize } = defaultFilters;
      dispatch(
        actions.filterListEmployeeStart({
          page: page + 1,
          page_size: pageSize,
          order_type: order,
          order_by: orderBy,
        })
      );
    }
  }, [requestStatus, toggleCreateFormModal, dispatch, filters]);

  const onSearchChange = (data) => {
    const { key, value } = data;

    if (!compareShallowObject(defaultFilters, filters)) {
      setFilters(defaultFilters);
    }

    if (key !== searchText) {
      setSearchTerm({
        ...searchTerm,
        [key]: value,
      });
    }
  };

  const handleSearch = (data) => {
    setFilters(defaultFilters);

    setSearchTerm({
      ...data,
      [searchText]: data[searchText].trim(),
    });
  };

  const handleResetSearch = (callback) => {
    callback();
    setSearchTerm(defaultSearchTerm);
  };

  const renderButtonExport = useMemo(() => {
    return (
      <AuthenticatedLink
        url={exportListEmployeeEndpoint()}
        filename={`${t("employee.csv_file_name", { suffix: getSuffixCSVFile() })}.csv`}
      >
        <IconButton className={classes.button}>
          <SaveAltIcon />
        </IconButton>
      </AuthenticatedLink>
    );
  }, [t]);

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={9} md={9} lg={10}>
          <Box py={4} className={classes.boxTop}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant="h5">{t("employee.title")}</Typography>
              </Grid>
              <Grid item md={6} className={classes.right}>
                <IconButton className={classes.button} onClick={toggleCreateFormModal}>
                  <AddIcon />
                </IconButton>
                {renderButtonExport}
              </Grid>
            </Grid>
          </Box>
          <TableComponent
            headCells={columns}
            rows={dataSource}
            currentPage={filters.page}
            rowPerPage={filters.pageSize}
            rowPerPageOptions={[]}
            order={filters.order}
            orderBy={filters.orderBy}
            handleFilters={handleFilters}
            rowLength={parseInt(employees.total || 0)}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid item xs={3} md={3} lg={2}>
          <Paper elevation={0} className={classes.searchContainer}>
            <SearchComponent
              initialValues={initialValuesSearch}
              onSearch={handleSearch}
              onSearchChange={onSearchChange}
              listDepartments={listDepartment}
              listRole={listEmployeeRole}
              handleResetSearch={handleResetSearch}
            />
          </Paper>
        </Grid>
      </Grid>
      <CreateEmployeeModal
        isVisible={isVisibleCreateForm}
        toggleModal={toggleCreateFormModal}
        title={t("employee.modal_title_create_form")}
        roles={listEmployeeRole}
        departments={listDepartment}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
export default EmployeePage;
