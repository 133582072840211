import React from "react";
import { Tooltip, Typography, withStyles } from "@material-ui/core";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.overrides.colorActive,
    color: theme.overrides.colorWhite,
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1),
    textAlign: "center",
  },
  arrow: {
    color: theme.overrides.colorActive,
  },
}))(Tooltip);

export default function ShortContent({ value }) {
  return (
    <HtmlTooltip
      title={
        <>
          {value.map((item, index) => (
            <Typography variant="caption" component="div" key={index}>
              {item}
            </Typography>
          ))}
        </>
      }
      arrow
    >
      <span>
        {value[0]} {!!(value.length - 1) && `+${value.length - 1}`}
      </span>
    </HtmlTooltip>
  );
}
