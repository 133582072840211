import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { TableComponent } from "../../components";
import { actions, ActionTypes } from "./actions";
import customerModel from "./model";
import KYCRequestForm from "../../components/KYCRequestForm";
import {
  getCustomerTypeOptions,
  getDocumentTypeName,
  DOCUMENT_TYPE_OPTIONS,
  KYC_STATUS_OPTIONS,
  getDepartmentOptions,
  SELECT_ALL,
  getAddDocumentTypesOptions,
} from "../../common/constant";
import SearchComponent from "./search";
import { actions as employeeActions } from "../employee/actions";
import {
  compareShallowObject,
  isStatusRejected,
  isStatusVerifying,
  isStatusApproved,
  isStatusRequesting,
  formatPIC,
  getSuffixCSVFile,
  formatANumber,
} from "../../utils";
import ShortContent from "./shortContent";
import { AuthenticatedLink } from "../../components/AuthenticatedLink";
import { exportListCustomerEndpoint } from "../../services/CustomerRequest";
import { trim } from "lodash-es";
import roles from "../../utils/roles";
import { IdocRepresentative } from "./IdocRepresentative";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: `-${theme.spacing(2)}px`,
  },
  boxTop: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  right: {
    textAlign: "right",
  },
  buttonSend: {
    padding: 10,
    marginRight: theme.spacing(2),
  },
  buttonSave: {
    borderRadius: 0,
    background: "linear-gradient(129.66deg, #BB9043 0%, #DDA74F 64.36%, #BB9043 132.87%)",
    color: theme.overrides.colorWhite,

    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
    },
  },
  status: {
    padding: theme.spacing(1, 2),
    display: "inline-block",
    textTransform: "lowercase",
    width: "100%",

    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  statusApproved: {
    backgroundColor: "rgba(67, 160, 71, 0.2)",
    color: theme.overrides.colorApple,
  },
  statusRejected: {
    backgroundColor: "rgba(239, 83, 80, 0.2)",
    color: theme.overrides.colorBurntSienna,
  },
  statusVerify: {
    backgroundColor: "rgba(255, 153, 0, 0.2)",
    color: theme.overrides.colorOrangePeel,
  },
  statusRequesting: {
    backgroundColor: "rgba(156, 39, 176, 0.2)",
    color: theme.overrides.colorSeance,
  },
  link: {
    color: theme.overrides.colorAzureRadiance,
  },
  searchContainer: {
    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.05)",
    padding: theme.spacing(5, 3),
  },
}));

function CustomerManagementPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { field } = customerModel;
  const {
    searchText,
    customerName,
    id,
    phoneNumber,
    customerType,
    departmentName,
    document,
    pic,
    status,
    departmentID,
    picID,
    documents,
    addDocTypes,
  } = field;

  const DOCUMENT_TYPE_NAME = getDocumentTypeName(t);
  const CUSTOMER_TYPE_OPTIONS = getCustomerTypeOptions(t);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const listKYCStatus = KYC_STATUS_OPTIONS(t);

  const customers = useSelector((state) => state.customer.customers);
  const departments = useSelector((state) => state.customer.departments);
  const requestStatus = useSelector((state) => state.customer.type);
  const employee = useSelector((state) => state.employee.employee);
  const userDetail = useSelector((state) => state.userDetail.userDetail);

  const defaultFilters = {
    order: null,
    orderBy: null,
    page: 0,
    pageSize: 10,
  };

  const defaultSearchTerm = {
    [searchText]: "",
    [departmentID]: SELECT_ALL,
    [customerType]: SELECT_ALL,
    [documents]: SELECT_ALL,
    [status]: SELECT_ALL,
    [picID]: SELECT_ALL,
    [addDocTypes]: [],
  };

  const [initialValuesSearch, setInitialValuesSearch] = useState({
    [searchText]: "",
    [departmentID]: [],
    [customerType]: CUSTOMER_TYPE_OPTIONS.map((item) => item.key),
    [documents]: DOCUMENT_TYPE_OPTIONS.map((item) => item.key),
    [status]: listKYCStatus.map((item) => item.key),
    // [addDocTypes]: getAddDocumentTypesOptions(t).map((item) => item.key),
    [addDocTypes]: [],
    searchPIC: "",
    [picID]: [],
  });

  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);

  const [filters, setFilters] = useState(defaultFilters);
  const [isVisibleKYCRequestFrom, setIsVisibleKYCRequestForm] = useState(false);
  const [listDepartment, setListDepartment] = useState([]);
  const [listDepartmentWithRole, setListDepartmentWithRole] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);

  const columns = [
    { dataIndex: "no", align: "center", label: t("customer_management.text_no"), isSorter: false, width: "4%" },
    { dataIndex: id, align: "left", label: t("customer_management.text_customer_id"), isSorter: true, width: "11%" },
    {
      dataIndex: customerName,
      align: "left",
      label: t("customer_management.text_customer_name"),
      isSorter: true,
      width: 120,
      minWidth: 100,
      ellipsis: true,
    },
    {
      dataIndex: departmentName,
      align: "left",
      label: t("customer_management.text_department"),
      isSorter: false,
      width: "10%",
    },
    {
      dataIndex: customerType,
      align: "left",
      label: t("customer_management.text_type"),
      isSorter: false,
      width: "10%",
    },
    {
      dataIndex: pic,
      align: "left",
      label: t("customer_management.text_pic"),
      isSorter: false,
      width: 80,
      minWidth: 80,
      ellipsis: true,
    },
    {
      dataIndex: phoneNumber,
      align: "left",
      label: t("customer_management.text_phone_number"),
      isSorter: false,
      width: "10%",
    },
    {
      dataIndex: document,
      align: "center",
      label: t("customer_management.text_identity_document"),
      isSorter: false,
      width: "10%",
    },
    { dataIndex: status, align: "center", label: t("customer_management.text_status"), isSorter: false, width: "12%" },
    {
      dataIndex: "action",
      align: "center",
      label: t("customer_management.text_action"),
      isSorter: false,
      width: "8%",
    },
  ];

  const dataSource = customers.data.map((item, index) => {
    const { page, pageSize } = filters;
    const newDepartmentName = item[customerType].map(
      (type) => CUSTOMER_TYPE_OPTIONS.find((option) => option.key === type).value
    );

    return {
      no: formatANumber(index + 1 + page * pageSize),
      [id]: item[id],
      [customerName]: item[customerName],
      [departmentName]: (
        <>
          {item[departmentName].map((item, index) => (
            <div key={index}>{getDepartmentOptions(item?.toUpperCase(), t)}</div>
          ))}
        </>
      ),
      [customerType]: <ShortContent index={index} value={newDepartmentName} />,
      [pic]: item?.pic_deleted ? t("customer_management.text_unassigned") : item[pic],
      [phoneNumber]: item[phoneNumber],
      [document]: <IdocRepresentative idocText={DOCUMENT_TYPE_NAME[item[document]]} customer={item} />,
      [status]: (
        <span
          className={clsx(classes.status, {
            [classes.statusVerify]: isStatusVerifying(item[status]),
            [classes.statusApproved]: isStatusApproved(item[status]),
            [classes.statusRejected]: isStatusRejected(item[status]),
            [classes.statusRequesting]: isStatusRequesting(item[status]),
          })}
        >
          {listKYCStatus.find((element) => element.key === item[status])?.value}
        </span>
      ),
      action: (
        <Link to={`/customers/${item.customer_code}`} className={classes.link}>
          {t("customer_management.button_view_detail")}
        </Link>
      ),
    };
  });

  const toggleKYCRequestModal = useCallback(() => {
    setIsVisibleKYCRequestForm(!isVisibleKYCRequestFrom);
  }, [isVisibleKYCRequestFrom]);

  const handleChangePage = (page) => {
    setFilters({
      ...filters,
      page,
    });
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setFilters({
      ...filters,
      page: 0,
      pageSize,
    });
  };

  const handleFilters = (data) => {
    setFilters({
      ...filters,
      ...data,
    });
  };

  useEffect(() => {
    dispatch(actions.getDepartmentCompanyStart());
  }, [dispatch]);

  useEffect(() => {
    dispatch(employeeActions.getListEmployeeStart());
  }, [dispatch]);

  useEffect(() => {
    if (departments?.length) {
      const newData = departments.map((item) => ({
        key: item.id + "",
        value: getDepartmentOptions(item?.code, t),
        code: item.code,
      }));
      setListDepartment(newData);
      setInitialValuesSearch((prevState) => ({
        ...prevState,
        [departmentID]: newData.map((item) => item.key),
      }));
    }
  }, [userDetail, departments, t]);

  useEffect(() => {
    if (employee?.length) {
      let newData = employee.map((item) => ({
        key: item.id,
        value: formatPIC(item.code, userDetail?.code, t) || item.name,
        code: item.code,
      }));
      newData.push({
        key: -1,
        value: t("customer_management.text_unassigned"),
        code: "UNASSIGNED",
      });
      setListEmployee(newData);
      setInitialValuesSearch((prevState) => ({
        ...prevState,
        [picID]: newData.map((item) => item.key),
      }));
    }
  }, [employee, t]);

  useEffect(() => {
    const { order, orderBy, page, pageSize } = filters;
    dispatch(
      actions.getKYCCustomerStart({
        ...searchTerm,
        page: page + 1,
        page_size: pageSize,
        order_type: order,
        order_by: orderBy,
      })
    );
  }, [dispatch, filters, searchTerm]);

  const handleSubmit = (data) => {
    const { departments, customerTypes, phoneNumber, language } = data;
    dispatch(
      actions.createKYCRequestStart({
        department_ids: departments,
        customer_types: customerTypes,
        phone: phoneNumber,
        language,
      })
    );
  };

  useEffect(() => {
    if (requestStatus === ActionTypes.CREATE_KYC_REQUEST_SUCCESS) {
      toggleKYCRequestModal();
      enqueueSnackbar(t("message.MSG_25"), { variant: "success" });
      dispatch(actions.resetTypeOfAction());

      setFilters(defaultFilters);

      setSearchTerm(defaultSearchTerm);

      const { order, orderBy, page, pageSize } = defaultFilters;
      dispatch(
        actions.getKYCCustomerStart({
          page: page + 1,
          page_size: pageSize,
          order_type: order,
          order_by: orderBy,
        })
      );
    }
  }, [requestStatus, toggleKYCRequestModal, dispatch]);

  useEffect(() => {
    const { role, department_code } = userDetail;

    if (listDepartment.length) {
      setListDepartmentWithRole(listDepartment);

      if (role === roles.EMPLOYEE) {
        const newDepartments = listDepartment.filter((item) => item.code === department_code);
        setListDepartmentWithRole(newDepartments);
      }
    }
  }, [listDepartment]);

  const onSearchChange = (data) => {
    const { key, value } = data;
    if (!compareShallowObject(defaultFilters, filters)) {
      setFilters(defaultFilters);
    }

    const selectAll = initialValuesSearch[key].length === value.length;

    if (key !== searchText) {
      setSearchTerm({
        ...searchTerm,
        [key]: selectAll ? SELECT_ALL : value,
      });
    }
  };

  const handleSearch = (data) => {
    setFilters(defaultFilters);
    let newData = { ...data };

    for (const key in data) {
      if (key !== searchText) {
        const selectAll = initialValuesSearch[key].length === data[key].length;
        newData[key] = selectAll ? SELECT_ALL : data[key];
      }
    }

    setSearchTerm({
      ...newData,
      [searchText]: trim(newData[searchText]),
    });
  };

  const handleResetSearch = (callback) => {
    callback();
    setSearchTerm(defaultSearchTerm);
  };

  const renderButtonExport = useMemo(() => {
    return (
      <AuthenticatedLink
        url={exportListCustomerEndpoint()}
        filename={`${t("customer_management.csv_file_name", {
          suffix: getSuffixCSVFile(),
        })}.csv`}
      >
        <IconButton className={classes.buttonSave}>
          <SaveAltIcon />
        </IconButton>
      </AuthenticatedLink>
    );
  }, [t]);

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={9} md={9} lg={10}>
          <Box py={4} className={classes.boxTop}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant="h5">{t("customer_management.title")}</Typography>
              </Grid>
              <Grid item md={6} className={classes.right}>
                <Button className={`button button-gradient ${classes.buttonSend}`} onClick={toggleKYCRequestModal}>
                  {t("customer_management.button_send_new_kyc_request")}
                </Button>
                {renderButtonExport}
              </Grid>
            </Grid>
          </Box>
          <TableComponent
            headCells={columns}
            rows={dataSource}
            currentPage={filters.page}
            rowPerPage={filters.pageSize}
            rowPerPageOptions={[]}
            order={filters.order}
            orderBy={filters.orderBy}
            handleFilters={handleFilters}
            rowLength={parseInt(customers.total || 0)}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid item xs={3} md={3} lg={2}>
          <Paper elevation={0} className={classes.searchContainer}>
            <SearchComponent
              initialValues={initialValuesSearch}
              onSearch={handleSearch}
              onSearchChange={onSearchChange}
              listDepartments={listDepartment}
              listEmployee={listEmployee}
              handleResetSearch={handleResetSearch}
            />
          </Paper>
        </Grid>
      </Grid>
      <KYCRequestForm
        isVisible={isVisibleKYCRequestFrom}
        toggleModal={toggleKYCRequestModal}
        title={t("customer_management.text_send_new_kyc_request")}
        departments={listDepartmentWithRole}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
export default CustomerManagementPage;
