import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ShowForPermissionComponent = (props) => {
  const role = useSelector((state) => state.userDetail.userDetail?.role);
  const { userRoles, children, condition = false } = props;
  const couldShow = userRoles.includes(role);

  return couldShow || condition ? children : null;
};

ShowForPermissionComponent.propTypes = {
  userRoles: PropTypes.array.isRequired,
};

export default ShowForPermissionComponent;
