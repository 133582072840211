import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 11a4 4 0 100-8 4 4 0 000 8zM12 13a9.985 9.985 0 00-8 4 9.985 9.985 0 008 4 9.985 9.985 0 008-4 9.985 9.985 0 00-8-4z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgComponent;
