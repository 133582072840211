export const ActionTypes = {
  GET_LIST_EMPLOYEE_START: 'GET_LIST_EMPLOYEE_START',
  GET_LIST_EMPLOYEE_SUCCESS: 'GET_LIST_EMPLOYEE_SUCCESS',
  GET_LIST_EMPLOYEE_ERROR: 'GET_LIST_EMPLOYEE_ERROR',

  FILTER_LIST_EMPLOYEE_START: 'FILTER_LIST_EMPLOYEE_START',
  FILTER_LIST_EMPLOYEE_SUCCESS: 'FILTER_LIST_EMPLOYEE_SUCCESS',
  FILTER_LIST_EMPLOYEE_ERROR: 'FILTER_LIST_EMPLOYEE_ERROR',

  CREATE_EMPLOYEE_START: 'CREATE_EMPLOYEE_START',
  CREATE_EMPLOYEE_SUCCESS: 'CREATE_EMPLOYEE_SUCCESS',
  CREATE_EMPLOYEE_ERROR: 'CREATE_EMPLOYEE_ERROR',

  GET_EMPLOYEE_DETAILS_START: 'GET_EMPLOYEE_DETAILS_START',
  GET_EMPLOYEE_DETAILS_SUCCESS: 'GET_EMPLOYEE_DETAILS_SUCCESS',
  GET_EMPLOYEE_DETAILS_ERROR: 'GET_EMPLOYEE_DETAILS_ERROR',

  DELETE_EMPLOYEE_START: 'DELETE_EMPLOYEE_START',
  DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
  DELETE_EMPLOYEE_ERROR: 'DELETE_EMPLOYEE_ERROR',

  UPDATE_EMPLOYEE_START: 'UPDATE_EMPLOYEE_START',
  UPDATE_EMPLOYEE_SUCCESS: 'UPDATE_EMPLOYEE_SUCCESS',
  UPDATE_EMPLOYEE_ERROR: 'UPDATE_EMPLOYEE_ERROR',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getListEmployeeStart: function (params) {
    return {
      type: ActionTypes.GET_LIST_EMPLOYEE_START,
      params,
    };
  },
  getListEmployeeSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_EMPLOYEE_SUCCESS,
      payload: data,
    };
  },
  getListEmployeeError: function (error) {
    return {
      type: ActionTypes.GET_LIST_EMPLOYEE_ERROR,
      payload: error,
    };
  },

  filterListEmployeeStart: function (params) {
    return {
      type: ActionTypes.FILTER_LIST_EMPLOYEE_START,
      params,
    };
  },
  filterListEmployeeSuccess: function (data) {
    return {
      type: ActionTypes.FILTER_LIST_EMPLOYEE_SUCCESS,
      payload: data,
    };
  },
  filterListEmployeeError: function (error) {
    return {
      type: ActionTypes.FILTER_LIST_EMPLOYEE_ERROR,
      payload: error,
    };
  },

  createEmployeeStart: function (params) {
    return {
      type: ActionTypes.CREATE_EMPLOYEE_START,
      params,
    };
  },
  createEmployeeSuccess: function (data) {
    return {
      type: ActionTypes.CREATE_EMPLOYEE_SUCCESS,
      payload: data,
    };
  },
  createEmployeeError: function (error) {
    return {
      type: ActionTypes.CREATE_EMPLOYEE_ERROR,
      payload: error,
    };
  },

  getEmployeeDetailsStart: function (params) {
    return {
      type: ActionTypes.GET_EMPLOYEE_DETAILS_START,
      params,
    };
  },
  getEmployeeDetailsSuccess: function (data) {
    return {
      type: ActionTypes.GET_EMPLOYEE_DETAILS_SUCCESS,
      payload: data,
    };
  },
  getEmployeeDetailsError: function (error) {
    return {
      type: ActionTypes.GET_EMPLOYEE_DETAILS_ERROR,
      payload: error,
    };
  },

  deleteEmployeeStart: function (params) {
    return {
      type: ActionTypes.DELETE_EMPLOYEE_START,
      params,
    };
  },
  deleteEmployeeSuccess: function (data) {
    return {
      type: ActionTypes.DELETE_EMPLOYEE_SUCCESS,
      payload: data,
    };
  },
  deleteEmployeeError: function (error) {
    return {
      type: ActionTypes.DELETE_EMPLOYEE_ERROR,
      payload: error,
    };
  },

  updateEmployeeStart: function (params) {
    return {
      type: ActionTypes.UPDATE_EMPLOYEE_START,
      params,
    };
  },
  updateEmployeeSuccess: function (data) {
    return {
      type: ActionTypes.UPDATE_EMPLOYEE_SUCCESS,
      payload: data,
    };
  },
  updateEmployeeError: function (error) {
    return {
      type: ActionTypes.UPDATE_EMPLOYEE_ERROR,
      payload: error,
    };
  },

  resetTypeOfAction: function () {
    return {
      type: ActionTypes.RESET_TYPE_OF_ACTION,
    };
  },
};
