import { all } from "redux-saga/effects";
import { watchAuthentication } from "../pages/login/saga";
import { watchKYCCustomer } from "../pages/home/saga";
import { watchRequestKYC } from "../pages/listKYC/saga";
import { watchEmployee } from "../pages/employee/saga";
import { watchKYCHistory } from "../pages/customer/KYCeditHistory/saga";
import { watchApp } from "../pages/layout/saga";
import { watchKYCHistoryDetail } from "../pages/kycHistory/saga";
import { watchProfileHistory } from "../pages/customer/ProfileEditHistory/saga";
import { watchUserDetail } from "../pages/profile/saga";
import { watchSetting } from "../pages/setting/saga";

function* rootSaga() {
  yield all([
    watchAuthentication(),
    watchKYCCustomer(),
    watchRequestKYC(),
    watchEmployee(),
    watchKYCHistory(),
    watchProfileHistory(),
    watchApp(),
    watchKYCHistoryDetail(),
    watchUserDetail(),
    watchSetting(),
  ]);
}
export default rootSaga;
