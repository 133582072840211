import { put, takeLatest } from "redux-saga/effects";
import { actions, ActionTypes } from "./actions";
import { getNotificationsAPI, readNotificationAPI } from "../../services/AppRequest";
import { RESPONSE_STATUS } from "../../common/constant";

function* getListNotification({ params }) {
  try {
    const response = yield getNotificationsAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListNotificationSuccess(response.data));
    } else {
      yield put(actions.getListNotificationError(response));
    }
  } catch (e) {
    yield put(actions.getListNotificationError(e));
  }
}

function* readNotification({ params }) {
  try {
    yield put(actions.showLoading());
    const response = yield readNotificationAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.readNotificationSuccess(response.data));
    } else {
      yield put(actions.readNotificationError(response));
    }
    yield put(actions.hideLoading());
  } catch (e) {
    yield put(actions.readNotificationError(e));
    yield put(actions.hideLoading());
  }
}

export function* watchApp() {
  yield takeLatest(ActionTypes.GET_LIST_NOTIFICATION_START, getListNotification);
  yield takeLatest(ActionTypes.READ_NOTIFICATION_START, readNotification);
}
