import io from "socket.io-client";
let socket;

export const initiateSocket = (token) => {
  socket = io(process.env.REACT_APP_API_URL);
  if (socket && token) {
    socket.emit("auth", { token });
  }
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const subscribeToNotification = (callback) => {
  if (!socket) return;
  socket.on("app-msg", (response) => {
    callback(response);
  });
};
