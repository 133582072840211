export const ActionTypes = {
  SHOW_LOADING: "SHOW_LOADING",
  HIDE_LOADING: "HIDE_LOADING",

  GET_LIST_NOTIFICATION_START: "GET_LIST_NOTIFICATION_START",
  GET_LIST_NOTIFICATION_SUCCESS: "GET_LIST_NOTIFICATION_SUCCESS",
  GET_LIST_NOTIFICATION_ERROR: "GET_LIST_NOTIFICATION_ERROR",

  READ_NOTIFICATION_START: "READ_NOTIFICATION_START",
  READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",
  READ_NOTIFICATION_ERROR: "READ_NOTIFICATION_ERROR",

  RESET_TYPE_OF_ACTION: "RESET_TYPE_OF_ACTION",
};

export const actions = {
  showLoading: function () {
    return {
      type: ActionTypes.SHOW_LOADING,
    };
  },
  hideLoading: function () {
    return {
      type: ActionTypes.HIDE_LOADING,
    };
  },

  getListNotificationStart: function (params) {
    return {
      type: ActionTypes.GET_LIST_NOTIFICATION_START,
      params,
    };
  },
  getListNotificationSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_NOTIFICATION_SUCCESS,
      payload: data,
    };
  },
  getListNotificationError: function (error) {
    return {
      type: ActionTypes.GET_LIST_NOTIFICATION_ERROR,
      payload: error,
    };
  },

  readNotificationStart: function (params) {
    return {
      type: ActionTypes.READ_NOTIFICATION_START,
      params,
    };
  },
  readNotificationSuccess: function (data) {
    return {
      type: ActionTypes.READ_NOTIFICATION_SUCCESS,
      payload: data,
    };
  },
  readNotificationError: function (error) {
    return {
      type: ActionTypes.READ_NOTIFICATION_ERROR,
      payload: error,
    };
  },

  resetTypeOfAction: function () {
    return {
      type: ActionTypes.RESET_TYPE_OF_ACTION,
    };
  },
};
