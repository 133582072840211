import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { formatCountries, formatDate, formatGender } from "../../utils";
import ContentChild from "../ContentChild";

function SpecialPermanentResidentContent({ values, makeGrid = false }) {
  const { t } = useTranslation();
  const {
    idoc_number,
    idoc_birthday,
    idoc_expired_date,
    idoc_full_name,
    idoc_gender,
    idoc_address,
    idoc_nationality,
  } = values;

  const documentContents = [
    {
      label: t("customer_management.text_full_name"),
      value: idoc_full_name,
    },
    {
      label: t("customer_management.text_date_of_expiry"),
      value: formatDate(idoc_expired_date),
    },
    {
      label: t("customer_management.text_date_of_birth"),
      value: formatDate(idoc_birthday),
    },
    {
      label: t("customer_management.text_gender"),
      value: formatGender(idoc_gender),
    },
    {
      label: t("customer_management.text_nationality"),
      value: formatCountries(idoc_nationality),
    },
    {
      label: t("customer_management.text_card_number"),
      value: idoc_number,
    },
    {
      label: t("customer_management.text_address"),
      value: idoc_address,
    },
  ];

  return (
    <>
      {documentContents.map((document, index) => (
        <Fragment key={index}>
          {!makeGrid && <ContentChild label={document.label} value={document.value} />}
          {!!makeGrid && (
            <Grid item md={makeGrid}>
              <ContentChild label={document.label} value={document.value || "---"} />
            </Grid>
          )}
        </Fragment>
      ))}
    </>
  );
}

export default SpecialPermanentResidentContent;
