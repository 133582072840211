import { ActionTypes } from "./actions";

const DEFAULT_STATE = {
  company: {},
  type: "",
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        company: action.payload,
      };
    case ActionTypes.UPDATE_COMPANY_SETTING_SUCCESS:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: "",
      };
    default:
      return state;
  }
};
