import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={100} height={136} viewBox="0 0 100 136" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M50 0C28.07 0 10.228 17.841 10.228 39.772v22.715c0 .258-.319.693-.569.777-2.15.717-3.897 1.338-5.496 1.955-1.907.735-3.345 2.848-3.345 4.915v52.193c0 2.053 1.429 4.168 3.323 4.919A123.96 123.96 0 0050.001 136c15.769 0 31.198-2.946 45.858-8.755 1.895-.75 3.323-2.865 3.323-4.918V70.134c0-2.067-1.438-4.18-3.346-4.916-1.6-.616-3.346-1.238-5.495-1.955-.25-.083-.568-.518-.568-.777V39.772C89.772 17.842 71.93 0 50 0zM39.167 86.03c0-5.987 4.85-10.832 10.833-10.832 5.981 0 10.831 4.845 10.831 10.832 0 3.968-2.237 7.289-5.416 9.172v17.909A5.413 5.413 0 0150 118.527a5.412 5.412 0 01-5.416-5.416v-17.91c-3.178-1.882-5.416-5.203-5.416-9.17zm36.93-46.258V59.37a124.32 124.32 0 00-26.093-2.775c-8.751 0-17.53.933-26.1 2.772V39.772c0-14.39 11.706-26.097 26.096-26.097 14.389 0 26.097 11.707 26.097 26.097z"
        fill="#181619"
      />
    </svg>
  );
}

export default SvgComponent;
