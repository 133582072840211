import { NO_FILTER } from "../common/constant";
import { api } from "./api";

export const getListRequestKYCAPI = (params) => {
  let paramsAddIdoc = "";
  if (params?.add_doc_types?.length > 1) {
    paramsAddIdoc = [NO_FILTER];
  } else if (!params.add_doc_types?.length) {
    paramsAddIdoc = ["UNDEFINE"];
  } else {
    paramsAddIdoc = params.add_doc_types;
  }

  return api.get("manage/kyc-edit-request/filter", {
    ...params,
    add_doc_types: paramsAddIdoc,
  });
};

export const putConfirmKYCAPI = (body, requestId) => {
  return api.put(`manage/kyc-edit-request/${requestId}/review`, body);
};

export const getDetailKYCAPI = (requestId) => {
  return api.get(`manage/kyc-edit-request/${requestId}/detail`);
};

export const getListKYCHistoryAPI = (params, customerCode) => {
  return api.get(`/manage/customer/${customerCode}/kyc/history`, params);
};

export const getDetailKYCHistoryAPI = (requestId) => {
  return api.get(`/manage/kyc/${requestId}/detail`);
};

export const getDetailKYCHistoryPageAPI = (customerId, kycId) => {
  return api.get(`/manage/customer/${customerId}/info?kyc_id=${kycId}`);
};

export const getListProfileHistoryAPI = (params, customerCode) => {
  return api.get(`/manage/customer/${customerCode}/profile-edit-history`, params);
};
