import { Avatar, Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { trim } from "lodash";
import { useSnackbar } from "notistack";

import SettingCompanyModal from "./modal";
import { actions, ActionTypes } from "./actions";
import { camelCaseToUnderscore, isFile } from "../../utils";

function SettingPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const company = useSelector((state) => state.setting.company);
  const requestStatus = useSelector((state) => state.setting.type);

  const toggleModal = useCallback(() => {
    setIsVisible((prevState) => !prevState);
  }, []);

  useEffect(() => {
    dispatch(actions.getCompanyDetailStart());
  }, [requestStatus]);

  useEffect(() => {
    if (requestStatus === ActionTypes.UPDATE_COMPANY_SETTING_SUCCESS) {
      toggleModal();
      enqueueSnackbar(t("message.MSG_28"), { variant: "success" });
      dispatch(actions.resetTypeOfAction());
    }
  }, [requestStatus]);

  const companyInformation = [
    {
      label: t("setting.label_company_name"),
      value: company?.name,
    },
    {
      label: t("setting.label_address"),
      value: company?.address,
    },
    {
      label: t("setting.label_contact_number"),
      value: company?.contact_no,
    },
    {
      label: t("setting.label_company_email"),
      value: company?.email,
    },
    {
      label: t("setting.label_postal_code"),
      value: company?.postal_code,
    },
  ];

  const onSubmit = (data) => {
    let formData = new FormData();

    for (let key in data) {
      if (isFile(data[key])) {
        formData.append(camelCaseToUnderscore(key), data[key]);
      }
      formData.append(camelCaseToUnderscore(key), trim(data[key]));
    }
    dispatch(actions.updateCompanySettingStart(formData));
  };

  return (
    <Box className={classes.root}>
      <Box py={4} className={classes.boxTop}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h5">{t("setting.title")}</Typography>
          </Grid>
          <Grid item md={6} className={classes.right}>
            <Button className={`button button-gradient ${classes.buttonSend}`} onClick={toggleModal}>
              {t("component.button_edit")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Paper elevation={0} className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={2} className={classes.avatarContainer}>
            <Avatar src={company?.logo_url} alt="company avatar" variant="square" className={classes.avatar} />
          </Grid>
          <Grid container item md={9}>
            {companyInformation?.map((item, index) => (
              <Grid container item md={12} key={index}>
                <Grid item md={2} className={classes.label}>
                  <Typography variant="subtitle1">{item.label}</Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="body1" component="h6" className={classes.content}>
                    {item.value || "---"}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Paper>
      <SettingCompanyModal company={company} isVisible={isVisible} toggleModal={toggleModal} onSubmit={onSubmit} />
    </Box>
  );
}

export default SettingPage;
