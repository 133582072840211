export default {
  field: {
    id: "code",
    searchText: "keyword",
    employeeName: "name",
    departmentName: "department_name",
    departmentID: "department_ids",
    role: "roles",
    type: "type",
    email: "email",
  },
};
