import { put, takeLatest } from "redux-saga/effects";
import { actions, ActionTypes } from "./actions";
import {
  getListEmployeeAPI,
  filterEmployeeAPI,
  createEmployeeAPI,
  getEmployeeDetailsAPI,
  deleteEmployeeAPI,
  updateEmployeeAPI,
} from "../../services/EmployeeRequest";
import { RESPONSE_STATUS } from "../../common/constant";
import { actions as appActions } from "../../pages/layout/actions";

function* getListEmployee({ params }) {
  try {
    const response = yield getListEmployeeAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListEmployeeSuccess(response?.data));
    } else {
      yield put(actions.getListEmployeeError(response));
    }
  } catch (e) {
    yield put(actions.getListEmployeeError(e));
  }
}

function* filterListEmployee({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield filterEmployeeAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.filterListEmployeeSuccess(response?.data));
    } else {
      yield put(actions.filterListEmployeeError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.filterListEmployeeError(e));
    yield put(appActions.hideLoading());
  }
}

function* createEmployee({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield createEmployeeAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.createEmployeeSuccess(response?.data));
    } else {
      yield put(actions.createEmployeeError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.createEmployeeError(e));
    yield put(appActions.hideLoading());
  }
}

function* getEmployee({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getEmployeeDetailsAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getEmployeeDetailsSuccess(response?.data));
    } else {
      yield put(actions.getEmployeeDetailsError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getEmployeeDetailsError(e));
    yield put(appActions.hideLoading());
  }
}

function* deleteEmployee({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield deleteEmployeeAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.deleteEmployeeSuccess(response?.data));
    } else {
      yield put(actions.deleteEmployeeError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.deleteEmployeeError(e));
    yield put(appActions.hideLoading());
  }
}

function* updateEmployee({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield updateEmployeeAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.updateEmployeeSuccess(response?.data));
    } else {
      yield put(actions.updateEmployeeError(response?.error));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.updateEmployeeError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchEmployee() {
  yield takeLatest(ActionTypes.GET_LIST_EMPLOYEE_START, getListEmployee);
  yield takeLatest(ActionTypes.FILTER_LIST_EMPLOYEE_START, filterListEmployee);
  yield takeLatest(ActionTypes.CREATE_EMPLOYEE_START, createEmployee);
  yield takeLatest(ActionTypes.GET_EMPLOYEE_DETAILS_START, getEmployee);
  yield takeLatest(ActionTypes.DELETE_EMPLOYEE_START, deleteEmployee);
  yield takeLatest(ActionTypes.UPDATE_EMPLOYEE_START, updateEmployee);
}
