import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { SimpleModal } from "../../../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

function WarningModal(props) {
  const { t } = useTranslation();
  const { data, employeeName, isVisible, toggleModal } = props;
  const total_customer = data?.data?.total_customer;

  const classes = useStyles();

  return (
    <SimpleModal isOpeningModal={isVisible} toggleModal={toggleModal} closeBottom boxClass={classes.root}>
      <Typography variant="caption">
        <div
          dangerouslySetInnerHTML={{
            __html: t("employee.modal_warning_description", { employeeName, totalProfile: total_customer }),
          }}
        ></div>
      </Typography>
    </SimpleModal>
  );
}

export default WarningModal;
