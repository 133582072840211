import { Box, Button, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { memo } from "react";
import LogoComponent from "../../../resources/images/Logo";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";

function SuccessResetPassword({ toggle, visible, t, classes }) {
  return (
    visible && (
      <Formik enableReinitialize>
        <Box className={classes.boxFormEditProfile}>
          <Form className="form">
            <LogoComponent className="center-margin-block" />
            <Typography variant="h5" className="center-block mt-46 mb-46">
              {t("profile.title_reset_password")}
            </Typography>
            <CheckCircleOutlinedIcon className={classes.iconSuccessResetPassword} />
            <Typography variant="body2" className={`center-block mt-25 mb-53 ${classes.textSuccessReset}`}>
              {t("message.MSG_41")}
            </Typography>
            <div className={classes.btnConfirmWrap}>
              <Button onClick={toggle} className={`button button-gradient ${classes.btnBackToLogin}`} fullWidth>
                {t("profile.title_back_to_login")}
              </Button>
            </div>
          </Form>
        </Box>
      </Formik>
    )
  );
}

export default memo(SuccessResetPassword);
