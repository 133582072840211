import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { loginAPI, createVerifyCodeAPI } from '../../services/AuthRequest';
import { RESPONSE_STATUS } from '../../common/constant';
import { actions as appActions } from '../../pages/layout/actions';
import { actions as myProfileAction } from "../profile/actions";

function* userLogin({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield loginAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.userLoginSuccess(response.data));
    } else {
      yield put(actions.userLoginError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.userLoginError(e));
    yield put(appActions.hideLoading());
  }
}

function* confirmEmail({ body }) {
  try {
    yield put(appActions.showLoading());
    const response = yield createVerifyCodeAPI(body);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(myProfileAction.createVerifyCodeSuccess(response.data));
    } else {
      yield put(myProfileAction.createVerifyCodeError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(myProfileAction.createVerifyCodeError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchAuthentication() {
  yield takeLatest(ActionTypes.POST_USER_LOGIN_START, userLogin);
  yield takeLatest(ActionTypes.CONFIRM_EMAIL_START, confirmEmail);
}
