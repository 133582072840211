import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: "right",
  },
  modalSetting: {
    padding: theme.spacing(7),
  },
  containerInfo: {
    position: "relative",
    background: "white",
    borderRadius: "5px",
    padding: theme.spacing(6),
  },
  btnActionWrap: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "26px",
    right: "24px",
  },
  btnChangePassword: {
    width: "204px",
    marginRight: theme.spacing(2),
  },
  btnEdit: {
    width: "82px",
  },
  avatar: {
    width: "100px",
    height: "100px",
    position: "relative",
  },
  infoWrap: {
    display: "flex",
  },
  infoValue: {
    lineHeight: "27px",
  },
  infoLabel: {
    minWidth: "143px",
  },
  btnSaveDetail: {
    width: "274px",
    margin: theme.spacing(2, "auto"),
  },
  boxAavatarEdit: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  maskWrapAvatar: {
    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  maskWrapAvatarBadge: {
    cursor: "pointer",
    "& >span.MuiBadge-badge": {
      top: "41px",
      right: "-6px",
      transform: "unset",
      transformOrigin: "unset",
    },
    "&:hover .MuiSvgIcon-root": {
        opacity: 0.7
    }
  },
  iconImage: {
    fill: "white",
    cursor: "pointer",
    zIndex: 0,
    "&:hover": {
      opacity: 0.7,
    },
  },
  boxFormEditProfile: {
    "& input": {
      padding: "10.5px 14px",
    },
  },
  dateInput: {
    padding: 0,
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  inputFile: {
    opacity: 0,
    zIndex: 10,
    position: "absolute",
    width: "100px",
    height: "100px",
    padding: 0,
    cursor: "pointer",
  },
  resendWrap: {
    display: "flex",
    width: 'max-content',
    alignItems: "center",
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
        opacity: 0.7
    },
    '& >*' : {
        color: theme.overrides.colorActive
    },
    '& >.MuiSvgIcon-root': {
        fontSize: '20px',
    }
  },
  textResend: {
      fontSize: '14px',
      textTransform: 'uppercase',
      fontWeight: 500,
      marginLeft: '7px',
  },
  btnConfirmWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& button' : {
        width: '150px'
      }
  },
}));
