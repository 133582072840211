import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import DateFnsUtils from "@date-io/date-fns";
import jaLocale from "date-fns/locale/ja";
import sCNLocale from "date-fns/locale/zh-CN";
import enLocale from "date-fns/locale/en-US";
import tCNLocale from "date-fns/locale/zh-TW";
import { useTranslation } from "react-i18next";
import IconCalendar from "../../resources/images/iconCalendar";

import { getCurrentLanguage } from "../../i18n/i18n";

const localeMap = {
  en: enLocale,
  jp: jaLocale,
  scn: sCNLocale,
  tcn: tCNLocale,
};

const useStyles = makeStyles((theme) => ({
  adornment: {
    padding: 0,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

// Remove dialog action in _component.scss
export default function DatePickerField({ field, form: { errors, touched }, message, ...props }) {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const currentLanguage = getCurrentLanguage();
  const [locale, setLocale] = useState(currentLanguage);

  const updateLanguage = (language) => {
    setLocale(language);
  };

  useEffect(() => {
    i18n.on("languageChanged", updateLanguage);
    return () => {
      i18n.off("languageChanged", updateLanguage);
    };
  }, [i18n]);

  return (
    <Grid container>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
        <KeyboardDatePicker
          InputProps={{
            readOnly: true,
          }}
          {...field}
          {...props}
          inputVariant="outlined"
          error={Boolean(message) || (touched[field.name] && Boolean(errors[field.name]))}
          invalidDateMessage={message || (touched[field.name] && errors[field.name])}
          helperText={message || (touched[field.name] && errors[field.name])}
          autoOk
          keyboardIcon={<IconCalendar />}
          KeyboardButtonProps={{
            className: classes.adornment,
          }}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
}
