import { Box, Grid, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import clsx from "clsx";
import { useStyles } from "./style";
import { actions } from "./actions";
import {
  CHART_EMPTY,
  getDocumentTypeName,
  KEY_UPLOAD_BUSINESS_DETAIL,
  KEY_UPLOAD_LEGAL_DETAIL,
  KYC_STATUS_OPTIONS,
} from "../../common/constant";
import {
  formatDate,
  isPresentative,
  isStatusApproved,
  isStatusRejected,
  isStatusRequesting,
  isStatusVerifying,
} from "../../utils";
import countries from "../../utils/countries";

import {
  BasicResidentRegisterContent,
  ContentChild,
  DriverLicenseContent,
  ForeignerRegistrationCardContent,
  HealthInsuranceCardContent,
  PassportContent,
  ResidentCardContent,
  SpecialPermanentResidentContent,
  ZoomPhoto,
} from "../../components";
import { RepresentativeInfo, useGetInfoBusiness, useGetInfoLegal } from "../customer/RepresentativeInfo";
import { Fragment } from "react";

function KYCHistory(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const detailHistoryStore = useSelector((state) => state.detailKYCHistory.kycHistory);
  const [isVisibleZoomPhotoModal, setIsVisibleZoomPhotoModal] = useState(false);
  const [zoomPhotoURL, setZoomPhotoURL] = useState("");

  const DOCUMENT_TYPE_NAME = getDocumentTypeName(t);
  const listKYCStatus = KYC_STATUS_OPTIONS(t);

  const contact = [
    {
      label: t("customer_management.text_full_name"),
      value: detailHistoryStore.full_name,
    },
    {
      label: t("customer_management.text_email"),
      value: detailHistoryStore.email,
    },
    {
      label: t("customer_management.text_date_of_birth"),
      value: formatDate(detailHistoryStore.birthday),
    },
    {
      label: t("customer_management.text_phone_number"),
      value: detailHistoryStore.phone,
    },
    {
      label: t("customer_management.text_nationality"),
      value: countries.find((item) => item.key === detailHistoryStore.nationality)?.value,
    },
    {
      label: t("customer_management.text_residential_address"),
      value: detailHistoryStore.residential_address,
    },
  ];

  useEffect(() => {
    const { customerId, kycId } = props.match.params;
    dispatch(actions.getKYCHistoryStart(customerId, kycId));
  }, []);

  const toggleZoomPhotoModal = useCallback(
    (url) => {
      setIsVisibleZoomPhotoModal(!isVisibleZoomPhotoModal);
      setZoomPhotoURL(url);
    },
    [isVisibleZoomPhotoModal]
  );

  const renderIdentityDocument = (documentType, values, makeGrid) => {
    switch (documentType) {
      case "DRIVER_LICENSE":
        return <DriverLicenseContent values={values} makeGrid={makeGrid} />;
      case "PASSPORT":
        return <PassportContent values={values} makeGrid={makeGrid} />;
      case "BASIC_RESIDENT_REGISTER":
        return <BasicResidentRegisterContent values={values} makeGrid={makeGrid} />;
      case "HEALTH_INSURANCE_CARD":
        return <HealthInsuranceCardContent values={values} makeGrid={makeGrid} />;
      case "RESIDENCE_CARD":
        return <ResidentCardContent values={values} makeGrid={makeGrid} />;
      case "FOREIGNER_REGISTRATION_CARD":
        return <ForeignerRegistrationCardContent values={values} makeGrid={makeGrid} />;
      case "SPECIAL_PERMANENT_RESIDENT":
        return <SpecialPermanentResidentContent values={values} makeGrid={makeGrid} />;
      default:
        break;
    }
  };
  const businessDocument = useGetInfoBusiness({ data: detailHistoryStore });
  const legalDocument = useGetInfoLegal({ data: detailHistoryStore });

  return (
    <Box className={classes.boxContainer}>
      <Grid container spacing={2} className={classes.gridRoot}>
        <Grid container item md={7} spacing={2}>
          <Grid item md={12}>
            <Typography variant="h6">{t("customer_management.heading_contact_information")}</Typography>
          </Grid>
          {contact.map(({ label, value }, index) => (
            <Grid item md={6} key={index}>
              <ContentChild label={label} value={value} />
            </Grid>
          ))}
          <Grid item md={12}>
            <Typography variant="h6">{t("customer_management.heading_identity_document")}</Typography>
          </Grid>
          <Grid item md={6}>
            <ContentChild
              label={t("customer_management.heading_identity_document")}
              value={DOCUMENT_TYPE_NAME[detailHistoryStore.idoc_type]}
            />
          </Grid>
          {renderIdentityDocument(detailHistoryStore?.idoc_type, detailHistoryStore, 6)}
          <Grid item md={6}>
            <Typography variant="subtitle1">{t("customer_management.label_kyc_status")}</Typography>
            <span
              className={clsx(classes.status, {
                [classes.statusVerify]: isStatusVerifying(detailHistoryStore.status),
                [classes.statusApproved]: isStatusApproved(detailHistoryStore.status),
                [classes.statusRejected]: isStatusRejected(detailHistoryStore.status),
                [classes.statusRequesting]: isStatusRequesting(detailHistoryStore.status),
              })}
            >
              {listKYCStatus.find((element) => element.key === detailHistoryStore.status)?.value}
            </span>
          </Grid>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item container md={4}>
          <Grid item md={12}>
            <Typography variant="h6">{t("customer_management.heading_kyc_photos")}</Typography>
          </Grid>
          <Grid item md={12}>
            {detailHistoryStore?.idoc_photo1_url ? (
              <img
                className={classes.photo}
                src={detailHistoryStore.idoc_photo1_url}
                alt={detailHistoryStore.idoc_photo1}
                onClick={() => toggleZoomPhotoModal(detailHistoryStore.idoc_photo1_url)}
              />
            ) : (
              CHART_EMPTY
            )}
          </Grid>
          <Grid item md={12}>
            {detailHistoryStore?.idoc_photo2_url ? (
              <img
                className={classes.photo}
                src={detailHistoryStore.idoc_photo2_url}
                alt={detailHistoryStore.idoc_photo2}
                onClick={() => toggleZoomPhotoModal(detailHistoryStore.idoc_photo2_url)}
              />
            ) : (
              CHART_EMPTY
            )}
          </Grid>
          <Grid item md={12}>
            {detailHistoryStore?.idoc_video_url ? (
              <img
                className={classes.photo}
                src={detailHistoryStore.idoc_video_url}
                alt={detailHistoryStore.idoc_video}
                onClick={() => toggleZoomPhotoModal(detailHistoryStore.idoc_video_url)}
              />
            ) : (
              CHART_EMPTY
            )}
          </Grid>
        </Grid>
        {isPresentative(detailHistoryStore) && (
          <Fragment>
            <RepresentativeInfo
              data={detailHistoryStore}
              title={t("verification.text_business_card")}
              document={businessDocument}
              arrKeyPhotos={KEY_UPLOAD_BUSINESS_DETAIL}
            />
            <RepresentativeInfo
              title={t("verification.text_legal_entity_proof")}
              data={detailHistoryStore}
              document={legalDocument}
              arrKeyPhotos={KEY_UPLOAD_LEGAL_DETAIL}
            />
          </Fragment>
        )}
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="h6">{t("customer_management.heading_status_history")}</Typography>
          </Grid>
          <Grid item md={4}>
            <ContentChild
              label={t("customer_management.label_requested_by")}
              value={detailHistoryStore.requested_by_name}
            />
          </Grid>
          <Grid item md={4}>
            <ContentChild
              label={t("customer_management.label_requested_date")}
              value={formatDate(detailHistoryStore.requested_at)}
            />
          </Grid>
          {!isStatusRequesting(detailHistoryStore.status) && (
            <>
              <Grid item md={4}>
                <ContentChild
                  label={t("customer_management.label_submitted_by")}
                  value={detailHistoryStore.submitted_by_name}
                />
              </Grid>
              <Grid item md={4}>
                <ContentChild
                  label={t("customer_management.label_submitted_date")}
                  value={formatDate(detailHistoryStore.submitted_at)}
                />
              </Grid>
            </>
          )}
          {isStatusApproved(detailHistoryStore.status) && (
            <>
              <Grid item md={4}>
                <ContentChild
                  label={t("customer_management.label_approved_by")}
                  value={detailHistoryStore.reviewed_by_name}
                />
              </Grid>
              <Grid item md={4}>
                <ContentChild
                  label={t("customer_management.label_approved_at")}
                  value={formatDate(detailHistoryStore.reviewed_at)}
                />
              </Grid>
            </>
          )}
          {isStatusRejected(detailHistoryStore.status) && (
            <>
              <Grid item md={4}>
                <ContentChild
                  label={t("customer_management.label_rejected_by")}
                  value={detailHistoryStore.reviewed_by_name}
                />
              </Grid>
              <Grid item md={4}>
                <ContentChild
                  label={t("customer_management.label_rejected_at")}
                  value={formatDate(detailHistoryStore.reviewed_at)}
                />
              </Grid>
              <Grid item md={4}>
                <ContentChild
                  className={classes.reasonContent}
                  label={t("customer_management.label_rejected_reason")}
                  value={detailHistoryStore.reject_reason}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <ZoomPhoto isOpening={isVisibleZoomPhotoModal} onToggle={toggleZoomPhotoModal} imageURL={zoomPhotoURL} />
    </Box>
  );
}

export default withRouter(KYCHistory);
