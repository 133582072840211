import React from "react";
import { Settings as SettingsIcon } from "@material-ui/icons";
import { Redirect } from "react-router";

import HomePage from "./pages/home";
import ListKYCPage from "./pages/listKYC";
import EmployeePage from "./pages/employee";
import EmployeeDetailsPage from "./pages/employee/details";
import LoginPage from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import CustomerPage from "./pages/customer";
import KYCHistory from "./pages/kycHistory";
import SettingPage from "./pages/setting";
import ProfilePage from "./pages/profile";

import IconCustomerManagement from "./resources/images/CustomerManagement";
import IconAccountManagement from "./resources/images/AccountManagement";
import IconKYCManagement from "./resources/images/KYCManagement";
import Roles from "./utils/roles";
import AccessDeniedPage from "./pages/accessDenied";

export const publicRoutes = [
  {
    name: "login",
    path: "/login",
    component: LoginPage,
    exact: true,
  },
  {
    name: "login",
    path: "/forgot-password",
    component: ForgotPassword,
    exact: true,
  },
];

export const privateRoutes = (t) => {
  return [
    {
      name: "Home",
      path: "/",
      component: () => <Redirect to="/customers" />,
      exact: true,
      title: t("menu.item_customer_management"),
    },
    {
      name: "Customer Management",
      path: "/customers",
      component: HomePage,
      exact: true,
      title: t("menu.item_customer_management"),
      icon: <IconCustomerManagement />,
    },
    {
      name: "KYCManagementDetail",
      path: "/list-kyc/detail/:requestId",
      component: ListKYCPage,
      exact: false,
    },
    {
      name: "KYCManagement",
      path: "/list-kyc",
      component: ListKYCPage,
      exact: false,
      icon: <IconKYCManagement />,
      title: t("menu.item_kyc_edit_request"),
    },
    {
      name: "Employee",
      path: "/employees",
      component: EmployeePage,
      exact: true,
      title: t("menu.item_employee_management"),
      icon: <IconAccountManagement />,
      permission: [Roles.ADMIN],
    },
    {
      name: "EmployeeDetails",
      path: "/employees/:employeeID",
      component: EmployeeDetailsPage,
      exact: true,
      permission: [Roles.ADMIN],
    },
    {
      name: "CustomerProfile",
      path: "/customers/:id",
      component: CustomerPage,
      exact: true,
    },
    {
      name: "MyProfile",
      path: "/profile",
      component: ProfilePage,
      exact: true,
    },
    {
      name: "Setting",
      path: "/setting",
      component: SettingPage,
      exact: true,
      title: t("menu.item_setting"),
      icon: <SettingsIcon />,
      permission: [Roles.ADMIN],
    },
  ];
};

export const simpleRoutes = [
  {
    name: "Access Denied",
    path: "/access-denied",
    component: AccessDeniedPage,
    exact: true,
  },
  {
    name: "KYCHistory",
    path: "/kyc-history/:customerId/:kycId",
    component: KYCHistory,
    exact: true,
  },
];

export const ROUTE_HIDE_LAYOUT = ["/kyc-history"];
