import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PARAMS_FILTER_DEFAULT } from "../../../common/constant";
import { TableComponent } from "../../../components";
import { columns, renderDataProfileHistory, useStyles } from "./style";
import { actions } from "./actions";
import { changeTable } from "../../../utils";
import ModalDetailProfile from "./ProfileDetail";

function ProfileEditHistory({}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerCode = useSelector((state) => state.customer.customer.customer_code);
  const listProfileHistory = useSelector((state) => state.profileHistory.listData);
  const countEditProfile = useSelector((state) => state.profileHistory.countEditProfile);

  const [visibleDetailProfileHistory, setVisibleDetailProfileHistory] = useState(false);
  const [filters, setFilters] = useState(PARAMS_FILTER_DEFAULT);
  const [profileHistory, setProfileHistory] = useState({});

  const toggleModalViewKYCHistory = () => {
    setVisibleDetailProfileHistory(!visibleDetailProfileHistory);
  };

  //when-click view kyc detail
  const viewKYCDetails = useCallback(
    (profileHistory) => () => {
      setProfileHistory(profileHistory);
      setVisibleDetailProfileHistory(true);
    },
    []
  );

  const fetchData = () => {
    const { order, orderBy, page, pageSize } = filters;
    dispatch(
      actions.getListProfileHistoryStart(
        {
          ...filters,
          page: page + 1,
          page_size: pageSize,
          order_type: order,
          order_by: orderBy,
        },
        customerCode
      )
    );
  };

  //listener change filter
  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    if (countEditProfile) {
      fetchData();
    }
  }, [countEditProfile]);

  return (
    <>
      <TableComponent
        headCells={columns(t, classes, customerCode)}
        rows={renderDataProfileHistory(listProfileHistory?.data, filters, classes, t, viewKYCDetails)}
        currentPage={filters.page}
        rowPerPage={filters.pageSize}
        order={filters.order}
        orderBy={filters.orderBy}
        rowLength={listProfileHistory?.total}
        handleFilters={changeTable(setFilters, filters).handleFilters}
        onChangePage={changeTable(setFilters, filters).handleChangePage}
        onChangeRowsPerPage={changeTable(setFilters, filters).handleChangeRowsPerPage}
      />
      <ModalDetailProfile
        toggleModal={toggleModalViewKYCHistory}
        visible={visibleDetailProfileHistory}
        classes={classes}
        profileHistory={profileHistory}
        t={t}
      />
    </>
  );
}

export default memo(ProfileEditHistory);
