import React from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { dateNowNoSpecial, formatDate } from "../../../utils";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {
  themConfigModalOverflow,
  themeConfigButtonSave,
  themeConfigLink,
  themeStatusConfig,
} from "../../../utils/themeConfig";
import { END_POINT_EXPORT } from "../../../common/constant";
import { AuthenticatedLink } from "../../../components/AuthenticatedLink";

export const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: "right",
  },
  ...themeStatusConfig(theme),
  ...themeConfigLink(theme),
  ...themeConfigButtonSave(theme, true),
  buttonSend: {
    padding: 10,
    marginRight: theme.spacing(2),
  },
  buttonCloseModalDetail: {
    margin: "auto",
    marginTop: theme.spacing(6),
    width: "274px",
  },
  boxModalKycHistory: {
    padding: theme.spacing(3),
    ...themConfigModalOverflow(),
  },
}));

//config colum
export const columns = (t, classes, customerCode) => {
  return [
    { dataIndex: "no", minWidth: 35, align: "center", label: t("customer_management.text_no"), isSorter: false },
    {
      dataIndex: "created_at",
      minWidth: 120,
      align: "left",
      label: t("list_kyc_history.label_edited_date"),
      isSorter: true,
    },
    {
      dataIndex: "request_code",
      minWidth: 120,
      align: "left",
      label: t("list_kyc_history.label_request_id"),
      isSorter: true,
    },
    { dataIndex: "reason", width: 500, align: "left", label: t("list_kyc_history.label_reason"), isSorter: false },
    { dataIndex: "status", align: "center", label: t("list_kyc_history.label_status"), isSorter: false },
    {
      align: "center",
      dataIndex: "action",
      minWidth: 200,
      label: (
        <AuthenticatedLink
          url={END_POINT_EXPORT.KYC_HISTORY(customerCode)}
          filename={`${t("file_name.kyc_edit_history", { customerCode })}.csv`}
          children={
            <IconButton className={classes.buttonSave}>
              <SaveAltIcon />
              <Typography variant="button">{t("component.button_export_data").toUpperCase()}</Typography>
            </IconButton>
          }
        />
      ),
      isSorter: false,
    },
  ];
};

//render data to table
export const renderDataKycRequest = (listKyc, filters, classes, t, callbackRequestKyc) => {
  return listKyc?.map((item, index) => {
    const { page, pageSize } = filters;
    return {
      no: index + 1 + page * pageSize,
      created_at: formatDate(item.created_at, "YYYY/MM/DD HH:mm:ss"),
      request_code: item.request_code,
      reason: <p className="col-overflow" title={item.reason}>{item.reason}</p>,
      status: renderStatusKYC(classes, item?.status, t),
      action: (
        <Link className={classes.link} to="#" onClick={callbackRequestKyc(item)}>
          {t("customer_management.button_view_detail")}
        </Link>
      ),
    };
  });
};

//render kyc status DOM
export const renderStatusKYC = (classes, status, t) => {
  let value = status === 'REQUESTING' ? 'PENDING' : status;
  return value && (
      <span
          className={clsx(classes.status, {
              [classes.statusVerify]: status === 'VERIFYING',
              [classes.statusApproved]: status === 'APPROVED',
              [classes.statusRejected]: status === 'REJECTED',
              [classes.statusRequesting]: status === 'REQUESTING',
          })}>
          {t(`search.text_${value?.toLowerCase()}`)}
      </span>
  );
};
