import { Avatar, Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { PROFILE_PARAMS, EMPLOYEE_ROLES, TYPE_PASSWORD_PROCESS, getDepartmentOptions } from "../../common/constant";
import { formatDate, getDurationSecondFromNow } from "../../utils";
import ModalVerifySecurity from "./ModalVerifySecurity";
import { actions } from "./actions";
import ModalEditProfile from "./ModalEditProfile";
import EmptyData from "../../components/EmptyData";
import ModalChangePassword from "./ModalChangePassword";
import i18n from "../../i18n/i18n";

function SettingPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [visibleModalDetail, setVisibleModalDetail] = useState(false);
  const [visibleModalChangePassword, setVisibleModalChangePassword] = useState(false);
  const [visibleModalVerify, setVisibleModalVerify] = useState(false);
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const verifyData = useSelector((state) => state.userDetail.verifyData);
  const confirmData = useSelector((state) => state.userDetail.confirmData);
  const changPassword = useSelector((state) => state.userDetail.changPassword);
  const resetVerifyCount = useSelector((state) => state.userDetail.resetVerifyCount);

  const otpTime = -getDurationSecondFromNow(verifyData?.expired_at);

  const listEmployeeRole = EMPLOYEE_ROLES(t);

  let userDetailDOM = userDetail;
  if (!_.isEmpty(userDetail, true)) {
    userDetailDOM = {
      ...userDetail,
      department_code: getDepartmentOptions(userDetail?.department_code, t),
      role: _.find(listEmployeeRole, { key: userDetail.role }).value,
      birthday: userDetail?.birthday?.replace(/-/g, "/"),
      created_at: formatDate(userDetail?.created_at, "YYYY/MM/DD HH:mm:ss"),
    };
  }

  const toggleModalEditDetail = () => {
    setVisibleModalDetail(!visibleModalDetail);
  };

  const toggleModalChangePassword = () => {
    setVisibleModalChangePassword(!visibleModalChangePassword);
  };

  const toggleModalVerify = () => {
    setVisibleModalVerify(!visibleModalVerify);
  };

  const onSubmitVerifyCode = useCallback((values) => {
    dispatch(actions.confirmVerifyCodeStart(values));
  }, []);

  const onCreateVerifyCode = () => {
    dispatch(
      actions.createVerifyCodeStart({
        email: userDetail.email,
        lang: i18n.language,
        action_type: TYPE_PASSWORD_PROCESS.CHANGE,
      })
    );
  };

  const onEditProfile = useCallback((values) => {
    dispatch(actions.updateProfileStart(values));
    setVisibleModalDetail(false);
  }, []);

  const onChangePassword = useCallback((values) => {
    dispatch(actions.changePasswordStart(values));
  }, []);

  useEffect(() => {
    if (verifyData?.expired_at) {
      setVisibleModalVerify(true);
      setVisibleModalChangePassword(false);
    }
  }, [verifyData]);

  useEffect(() => {
    if (confirmData?.expired_at) {
      setVisibleModalVerify(false);
      setVisibleModalChangePassword(true);
    }
  }, [confirmData]);

  useEffect(() => {
    if (changPassword) {
      setVisibleModalChangePassword(false);
    }
  }, [changPassword]);

  useEffect(() => {
    setVisibleModalChangePassword(false);
    setVisibleModalVerify(false);
    dispatch(actions.resetFollowChangePassword());
  }, []);

  return (
    <div className="customer-management">
      <Grid container>
        <Grid className="layout__container-filter-right" item xs={12}>
          <Box py={4}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant="h5">{t("profile.title_page")}</Typography>
              </Grid>
            </Grid>
          </Box>
          {!_.isEmpty(userDetail, true) ? (
            <Box className={classes.containerInfo} py={2}>
              <div className={classes.btnActionWrap}>
                <Button
                  onClick={onCreateVerifyCode}
                  className={`button button-transparent-border ${classes.btnChangePassword}`}
                  fullWidth
                >
                  {t("profile.btn_change_password")}
                </Button>
                <Button
                  type="submit"
                  onClick={toggleModalEditDetail}
                  className={`button button-gradient ${classes.btnEdit}`}
                  fullWidth
                >
                  {t("profile.btn_edit")}
                </Button>
              </div>
              <div>
                <Avatar className={classes.avatar} alt={userDetail.name} src={userDetail.avatar_url} />
                <Typography className="mt-18" variant="h5">
                  {userDetail.name}
                </Typography>
                <Typography className="mt-7 mb-36" variant="subtitle1">{`${t("employee.label_employee_id")}: ${
                  userDetail.code
                }`}</Typography>
                {userDetailDOM?.code &&
                  PROFILE_PARAMS(t).map((item) => (
                    <div key={item.prop} className={`mt-0 mb-16 ${classes.infoWrap}`}>
                      <Typography className={classes.infoLabel} variant="subtitle1">
                        {item.label}
                      </Typography>
                      <Typography className={classes.infoValue} variant="body1">
                        {userDetailDOM[item.prop] ? userDetailDOM[item.prop] : "---"}
                      </Typography>
                    </div>
                  ))}
              </div>
            </Box>
          ) : (
            <EmptyData text={t("common.no_data")} />
          )}
        </Grid>
      </Grid>
      {visibleModalVerify && (
        <ModalVerifySecurity
          toggleModal={toggleModalVerify}
          callbackSubmitVerify={onSubmitVerifyCode}
          visible={visibleModalVerify}
          otpTime={otpTime}
          resetVerifyCount={resetVerifyCount}
          request_id={verifyData?.request_id}
          onResetVerifyCode={onCreateVerifyCode}
          classes={classes}
          t={t}
        />
      )}
      <ModalEditProfile
        toggleModal={toggleModalEditDetail}
        callbackSubmitEdit={onEditProfile}
        visible={visibleModalDetail}
        userDetail={userDetail}
        userDetailDOM={userDetailDOM}
        classes={classes}
        t={t}
      />
      <ModalChangePassword
        toggleModal={toggleModalChangePassword}
        request_id={verifyData?.request_id}
        callbackSubmitChangePassword={onChangePassword}
        visible={visibleModalChangePassword}
        userDetail={userDetail}
        userDetailDOM={userDetailDOM}
        classes={classes}
        t={t}
      />
    </div>
  );
}

export default SettingPage;
