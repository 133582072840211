import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {},
  boxTop: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  right: {
    textAlign: "right",
  },
  paper: {
    padding: theme.spacing(6),
  },
  avatarContainer: {
    marginRight: theme.spacing(5),
  },
  avatar: {
    width: "100%",
    height: "auto",
    maxHeight: 150,
  },
  content: {
    color: theme.overrides.colorShuttleGray,
  },
  label: {
    [theme.breakpoints.down("md")]: {
      marginRight: 30,
    },
  },
}));
