export const ActionTypes = {
  GET_LIST_REQUEST_KYC_START: 'GET_LIST_REQUEST_KYC_START',
  GET_LIST_REQUEST_KYC_SUCCESS: 'GET_LIST_REQUEST_KYC_SUCCESS',
  GET_LIST_REQUEST_KYC_ERROR: 'GET_LIST_REQUEST_KYC_ERROR',
  GET_DETAIL_KYC_START: 'GET_DETAIL_KYC_START',
  GET_DETAIL_KYC_SUCCESS: 'GET_DETAIL_KYC_SUCCESS',
  GET_DETAIL_KYC_ERROR: 'GET_DETAIL_KYC_ERROR',
  PUT_CONFIRM_KYC_REQUEST_START: 'PUT_CONFIRM_KYC_REQUEST_START',
  PUT_CONFIRM_KYC_REQUEST_SUCCESS: 'PUT_CONFIRM_KYC_REQUEST_SUCCESS',
  PUT_CONFIRM_KYC_REQUEST_ERROR: 'PUT_CONFIRM_KYC_REQUEST_ERROR',
  CHANGE_EMPTY_LIST_KYC_DATA: 'CHANGE_EMPTY_LIST_KYC_DATA',
};

export const actions = {
  changeListEmptyStart: () => ({type: ActionTypes.CHANGE_EMPTY_LIST_KYC_DATA}),
  getListRequestKYCStart: params => ({ type: ActionTypes.GET_LIST_REQUEST_KYC_START, params }),
  getListRequestKYCSuccess: data => ({ type: ActionTypes.GET_LIST_REQUEST_KYC_SUCCESS, payload: data }),
  getListRequestKYCError: error => ({ type: ActionTypes.GET_LIST_REQUEST_KYC_ERROR, payload: error }),
  getDetailKYCStart: requestId => ({ type: ActionTypes.GET_DETAIL_KYC_START, requestId }),
  getDetailKYCSuccess: data => ({ type: ActionTypes.GET_DETAIL_KYC_SUCCESS, payload: data }),
  getDetailKYCError: error => ({ type: ActionTypes.GET_DETAIL_KYC_ERROR, payload: error }),
  putConfirmKYCStart: (body, requestId) => ({ type: ActionTypes.PUT_CONFIRM_KYC_REQUEST_START, body, requestId }),
  putConfirmKYCSuccess: data => ({ type: ActionTypes.PUT_CONFIRM_KYC_REQUEST_SUCCESS, payload: data }),
  putConfirmKYCError: error => ({ type: ActionTypes.PUT_CONFIRM_KYC_REQUEST_ERROR, payload: error }),
};
