import { Box, IconButton, Typography, makeStyles, Paper, Grid, Button } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import WarningIcon from "@material-ui/icons/Warning";

import ArrowBackIcon from "../../../resources/images/ArrowBackIcon";
import { actions, ActionTypes } from "../actions";
import { formatDate, isCurrentUser } from "../../../utils";
import { ContentChild, SimpleModal } from "../../../components";
import { EMPLOYEE_ROLES, getDepartmentOptions } from "../../../common/constant";
import { useSnackbar } from "notistack";
import EditFormComponent from "../editForm";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",
    alignItems: "center",
  },
  boxAction: {
    display: "flex",
    justifyContent: "flex-end",

    "& > .button:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  paper: {
    marginRight: theme.spacing(3),
    padding: theme.spacing(2),
  },
  contentChild: {
    marginTop: theme.spacing(2),
  },
  modalDelete: {
    textAlign: "center",

    "& .button": {
      maxWidth: 280,
    },

    "& .button-transparent": {
      marginTop: theme.spacing(2),
    },
  },
  modalTitle: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    maxWidth: 180,
    margin: "auto",

    "& .MuiSvgIcon-root": {
      color: theme.overrides.colorBurntSienna,
    },
  },
  modalDescription: {
    marginTop: theme.spacing(2),
  },
}));

function EmployeeDetails() {
  let history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { employeeID } = useParams();

  const listEmployeeRole = EMPLOYEE_ROLES(t);

  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [initialEmployeeValues, setInitialEmployeeValues] = useState({
    employeeID: "",
    fullName: "",
    createdOn: null,
    department: "",
    createdBy: "",
    dateOfBirth: null,
    email: "",
    role: "",
  });

  const employee = useSelector((state) => state.employee.employee);
  const statusRequest = useSelector((state) => state.employee.type);
  const userDetail = useSelector((state) => state.userDetail.userDetail);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(actions.getEmployeeDetailsStart(employeeID));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(employee)) {
      const { code, name, created_at, department_id, created_by_name, birthday, email, role } = employee;
      setInitialEmployeeValues({
        employeeID: code,
        fullName: name || "",
        createdOn: created_at,
        department: department_id || "",
        createdBy: created_by_name,
        dateOfBirth: birthday,
        email: email || "",
        role: role || "",
      });
    }
  }, [employee]);

  useEffect(() => {
    if (statusRequest === ActionTypes.DELETE_EMPLOYEE_SUCCESS) {
      goToEmployees();
      dispatch(actions.resetTypeOfAction());
      enqueueSnackbar(t("message.MSG_30"), { variant: "success" });
    } else if (statusRequest === ActionTypes.UPDATE_EMPLOYEE_SUCCESS) {
      setIsEditForm(false);
      enqueueSnackbar(t("message.MSG_28"), { variant: "success" });
      dispatch(actions.getEmployeeDetailsStart(employeeID));
      dispatch(actions.resetTypeOfAction());
    }
  }, [dispatch, statusRequest]);

  const employeeDetails = [
    {
      label: t("employee.label_employee_id"),
      value: employee.code,
    },
    {
      label: t("employee.text_birthday"),
      value: formatDate(employee.birthday),
    },
    {
      label: t("employee.text_full_name"),
      value: employee.name,
    },
    {
      label: t("employee.label_email"),
      value: employee.email,
    },
    {
      label: t("employee.text_created_on"),
      value: formatDate(employee.created_at),
    },
    {
      label: t("employee.label_role"),
      value: listEmployeeRole.find((role) => role.key === employee.role)?.value,
    },
    {
      label: t("employee.label_department"),
      value: getDepartmentOptions(employee?.department_name?.toUpperCase(), t),
    },
    {
      label: t("employee.text_created_by"),
      value: employee.created_by_name,
    },
  ];

  const goToEmployees = () => {
    return history.push("/employees");
  };

  const toggleDeleteModal = useCallback(() => {
    setIsVisibleDeleteModal((previousState) => !previousState);
  }, [isVisibleDeleteModal]);

  const toggleEditMode = useCallback(() => {
    setIsEditForm((previousState) => !previousState);
  }, [isEditForm]);

  const deleteEmployee = () => {
    dispatch(actions.deleteEmployeeStart(employeeID));
  };

  const onEditEmployee = (data) => {
    const { fullName, department, dateOfBirth, email, role } = data;
    dispatch(
      actions.updateEmployeeStart({
        employeeID,
        body: {
          full_name: fullName.trim(),
          email: email.trim(),
          birthday: dateOfBirth,
          user_type: role,
          department_id: department || null,
        },
      })
    );
  };

  return (
    <Box>
      <Box className={classes.heading}>
        <IconButton component="span" onClick={goToEmployees}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">{t("employee.employee_detail_title")}</Typography>
      </Box>
      <Paper elevation={0} className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item md={8}>
            <Typography variant="h6">{t("employee.employee_detail_heading")}</Typography>
          </Grid>
          <Grid item md={4}>
            {!isEditForm && !isCurrentUser(employee.code, userDetail.code) && (
              <Box className={classes.boxAction}>
                <Button className="button button-transparent-border" onClick={toggleDeleteModal}>
                  {t("component.button_delete")}
                </Button>
                <Button className="button button-gradient" onClick={toggleEditMode}>
                  {t("component.button_edit")}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
        {!isEditForm && (
          <Grid container spacing={3}>
            <Grid item container md={8}>
              {employeeDetails.map(({ label, value }, index) => (
                <Grid item md={5} key={index}>
                  <ContentChild label={label} value={value} className={classes.contentChild} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {isEditForm && (
          <EditFormComponent
            initialValues={initialEmployeeValues}
            backToEmployeeDetails={toggleEditMode}
            onSubmit={onEditEmployee}
          />
        )}
      </Paper>
      <SimpleModal
        isOpeningModal={isVisibleDeleteModal}
        toggleModal={toggleDeleteModal}
        className={classes.modalDelete}
        width={480}
      >
        <Box className={classes.modalTitle}>
          <WarningIcon />
          <Typography variant="h6">{t("employee.modal_delete_title")}</Typography>
          <WarningIcon />
        </Box>
        <Typography variant="body2" className={classes.modalDescription}>
          {t("employee.modal_delete_text_1")}
        </Typography>
        <Typography variant="body2" className={classes.modalDescription}>
          {t("employee.modal_delete_text_2")}
        </Typography>
        <Box mt={3}>
          <Button className="button button-gradient" onClick={deleteEmployee} fullWidth>
            {t("component.button_delete")}
          </Button>
          <Button className="button button-transparent" onClick={toggleDeleteModal} fullWidth>
            {t("component.button_cancel")}
          </Button>
        </Box>
      </SimpleModal>
    </Box>
  );
}

export default EmployeeDetails;
