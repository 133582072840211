import { ActionTypes } from "./actions";

const DEFAULT_STATE = {
  customer: {},
  session: {},
  currentUser: {
    access_token: "",
    role: "",
  },
  type: "",
  error: "",
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.POST_USER_LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };
    case ActionTypes.USER_LOGOUT:
      return {
        ...state,
        ...DEFAULT_STATE,
      };

    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: "",
      };
    default:
      return state;
  }
};
