export const ActionTypes = {
  GET_KYC_CUSTOMER_START: "GET_KYC_CUSTOMER_START",
  GET_KYC_CUSTOMER_SUCCESS: "GET_KYC_CUSTOMER_SUCCESS",
  GET_KYC_CUSTOMER_ERROR: "GET_KYC_CUSTOMER_ERROR",

  GET_DEPARTMENT_COMPANY_START: "GET_DEPARTMENT_COMPANY_START",
  GET_DEPARTMENT_COMPANY_SUCCESS: "GET_DEPARTMENT_COMPANY_SUCCESS",
  GET_DEPARTMENT_COMPANY_ERROR: "GET_DEPARTMENT_COMPANY_ERROR",

  CREATE_KYC_REQUEST_START: "CREATE_KYC_REQUEST_START",
  CREATE_KYC_REQUEST_SUCCESS: "CREATE_KYC_REQUEST_SUCCESS",
  CREATE_KYC_REQUEST_ERROR: "CREATE_KYC_REQUEST_ERROR",

  GET_CUSTOMER_INFORMATION_START: "GET_CUSTOMER_INFORMATION_START",
  GET_CUSTOMER_INFORMATION_SUCCESS: "GET_CUSTOMER_INFORMATION_SUCCESS",
  GET_CUSTOMER_INFORMATION_ERROR: "GET_CUSTOMER_INFORMATION_ERROR",

  UPDATE_KYC_STATUS_START: "UPDATE_KYC_STATUS_START",
  UPDATE_KYC_STATUS_SUCCESS: "UPDATE_KYC_STATUS_SUCCESS",
  UPDATE_KYC_STATUS_ERROR: "UPDATE_KYC_STATUS_ERROR",

  UPDATE_CUSTOMER_INFORMATION_START: "UPDATE_CUSTOMER_INFORMATION_START",
  UPDATE_CUSTOMER_INFORMATION_SUCCESS: "UPDATE_CUSTOMER_INFORMATION_SUCCESS",
  UPDATE_CUSTOMER_INFORMATION_ERROR: "UPDATE_CUSTOMER_INFORMATION_ERROR",

  RESEND_REQUEST_KYC_START: "RESEND_REQUEST_KYC_START",
  RESEND_REQUEST_KYC_SUCCESS: "RESEND_REQUEST_KYC_SUCCESS",
  RESEND_REQUEST_KYC_ERROR: "RESEND_REQUEST_KYC_ERROR",

  RESET_TYPE_OF_ACTION: "RESET_TYPE_OF_ACTION",
};

export const actions = {
  getKYCCustomerStart: function (params) {
    return {
      type: ActionTypes.GET_KYC_CUSTOMER_START,
      params,
    };
  },
  getKYCCustomerSuccess: function (data) {
    return {
      type: ActionTypes.GET_KYC_CUSTOMER_SUCCESS,
      payload: data,
    };
  },
  getKYCCustomerError: function (error) {
    return {
      type: ActionTypes.GET_KYC_CUSTOMER_ERROR,
      payload: error,
    };
  },

  getDepartmentCompanyStart: function () {
    return {
      type: ActionTypes.GET_DEPARTMENT_COMPANY_START,
    };
  },
  getDepartmentCompanySuccess: function (data) {
    return {
      type: ActionTypes.GET_DEPARTMENT_COMPANY_SUCCESS,
      payload: data,
    };
  },
  getDepartmentCompanyError: function (error) {
    return {
      type: ActionTypes.GET_DEPARTMENT_COMPANY_ERROR,
      payload: error,
    };
  },

  createKYCRequestStart: function (params) {
    return {
      type: ActionTypes.CREATE_KYC_REQUEST_START,
      params,
    };
  },
  createKYCRequestSuccess: function (data) {
    return {
      type: ActionTypes.CREATE_KYC_REQUEST_SUCCESS,
      payload: data,
    };
  },
  createKYCRequestError: function (error) {
    return {
      type: ActionTypes.CREATE_KYC_REQUEST_ERROR,
      payload: error,
    };
  },

  getCustomerInformationStart: function (params) {
    return {
      type: ActionTypes.GET_CUSTOMER_INFORMATION_START,
      params,
    };
  },
  getCustomerInformationSuccess: function (data) {
    return {
      type: ActionTypes.GET_CUSTOMER_INFORMATION_SUCCESS,
      payload: data,
    };
  },
  getCustomerInformationError: function (error) {
    return {
      type: ActionTypes.GET_CUSTOMER_INFORMATION_ERROR,
      payload: error,
    };
  },

  updateKYCStatusStart: function (params) {
    return {
      type: ActionTypes.UPDATE_KYC_STATUS_START,
      params,
    };
  },
  updateKYCStatusSuccess: function (data) {
    return {
      type: ActionTypes.UPDATE_KYC_STATUS_SUCCESS,
      payload: data,
    };
  },
  updateKYCStatusError: function (error) {
    return {
      type: ActionTypes.UPDATE_KYC_STATUS_ERROR,
      payload: error,
    };
  },

  updateCustomerInformationStart: function (params) {
    return {
      type: ActionTypes.UPDATE_CUSTOMER_INFORMATION_START,
      params,
    };
  },
  updateCustomerInformationSuccess: function (data) {
    return {
      type: ActionTypes.UPDATE_CUSTOMER_INFORMATION_SUCCESS,
      payload: data,
    };
  },
  updateCustomerInformationError: function (error) {
    return {
      type: ActionTypes.UPDATE_CUSTOMER_INFORMATION_ERROR,
      payload: error,
    };
  },

  resendRequestKYCStart: function (params) {
    return {
      type: ActionTypes.RESEND_REQUEST_KYC_START,
      params,
    };
  },
  resendRequestKYCSuccess: function (data) {
    return {
      type: ActionTypes.RESEND_REQUEST_KYC_SUCCESS,
      payload: data,
    };
  },
  resendRequestKYCError: function (error) {
    return {
      type: ActionTypes.RESEND_REQUEST_KYC_ERROR,
      payload: error,
    };
  },

  resetTypeOfAction: function () {
    return {
      type: ActionTypes.RESET_TYPE_OF_ACTION,
    };
  },
};
