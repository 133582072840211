import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import { Button, IconButton, InputAdornment, Link, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import InputComponent from "../../components/Input";
import { actions } from "./actions";
import { actions as actionsProfile } from "../profile/actions";
import LogoComponent from "../../resources/images/Logo";
import { MAX_LENGTH_INPUT, TYPE_PASSWORD_PROCESS } from "../../common/constant";
import { useStyles } from "./styles";
import ConfirmEmail from "./ConfirmEmail";
import i18n from "../../i18n/i18n";
import ConfirmCode from "./ConfirmCode";
import { getDurationSecondFromNow } from "../../utils";
import ResetPassword from "./ResetPassword";
import SuccessResetPassword from "./SuccessResetPassword";

function LoginPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [visibleConfirmEmail, setVisibleConfirmEmail] = useState(false);
  const [visibleResetPassword, setVisibleResetPassword] = useState(false);
  const [visibleModalVerify, setVisibleModalVerify] = useState(false);
  const [visibleResetSuccess, setVisibleResetSuccess] = useState(false);
  const [emailConfirm, setEmailConfirm] = useState("");
  const { t } = useTranslation();
  const { verifyData, confirmData, changPassword, resetVerifyCount } = useSelector((state) => state.userDetail);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email(t("message.MSG_2"))
      .trim()
      .required(t("message.MSG_4", { fieldName: t("login.text_email") })),
    password: Yup.string().required(t("message.MSG_4", { fieldName: t("login.text_password") })),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    const { username, password } = data;
    dispatch(
      actions.userLoginStart({
        username: username.trim(),
        password,
      })
    );
  };

  const toggleConfirmEmail = () => {
    setVisibleConfirmEmail(!visibleConfirmEmail);
  };

  const toggleVerify = () => {
    setVisibleModalVerify(!visibleModalVerify);
  };

  const toggleResetPassword = () => {
    setVisibleResetPassword(!visibleResetPassword);
  };

  const onConfirmEmail = useCallback((values) => {
    setEmailConfirm(values.email);
    dispatch(actions.confirmEmailStart({ ...values, lang: i18n.language, action_type: TYPE_PASSWORD_PROCESS.FORGOT }));
  }, []);

  const onResetVerifyCode = useCallback(() => {
    dispatch(
      actions.confirmEmailStart({ email: emailConfirm, lang: i18n.language, action_type: TYPE_PASSWORD_PROCESS.FORGOT })
    );
  }, [emailConfirm]);

  const onSubmitVerifyCode = useCallback((values) => {
    dispatch(actionsProfile.confirmVerifyCodeStart(values));
  }, []);

  const onChangePassword = useCallback((values) => {
    dispatch(actionsProfile.changePasswordStart(values));
  }, []);

  useEffect(() => {
    dispatch(actionsProfile.resetFollowChangePassword());
  }, []);

  const onResetCaseChangePassword = useCallback(() => {
    setVisibleResetSuccess(false);
    dispatch(actionsProfile.resetFollowChangePassword());
  }, []);

  useEffect(() => {
    if (verifyData?.expired_at) {
      setVisibleModalVerify(true);
      setVisibleConfirmEmail(false);
    }
  }, [verifyData]);

  useEffect(() => {
    if (confirmData?.expired_at) {
      setVisibleModalVerify(false);
      setVisibleResetPassword(true);
    }
  }, [confirmData]);

  useEffect(() => {
    if (changPassword) {
      setVisibleResetPassword(false);
      setVisibleResetSuccess(true);
    }
  }, [changPassword]);

  return (
    <div className="login">
      <div className="container">
        {!visibleConfirmEmail && !visibleModalVerify && !visibleResetPassword && !visibleResetSuccess && (
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form className="form">
              <LogoComponent />
              <Typography variant="h5" className="form__title">
                {t("login.text_login")}
              </Typography>
              <Field
                type="text"
                name="username"
                label={t("login.text_email")}
                className="form__input"
                fullWidth
                maxLength={MAX_LENGTH_INPUT}
                component={InputComponent}
              />
              <Field
                type={clsx({ password: !showPassword, text: showPassword })}
                name="password"
                label={t("login.text_password")}
                className="form__input"
                fullWidth
                maxLength={16}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                component={InputComponent}
              />
              <Link href="#" onClick={toggleConfirmEmail} className="form__link">
                {t("login.title_forgot_password")}
              </Link>
              <Button fullWidth type="submit" className="button button-gradient">
                {t("login.button_login")}
              </Button>
            </Form>
          </Formik>
        )}
        <ConfirmEmail
          toggle={toggleConfirmEmail}
          callbackSubmitEmail={onConfirmEmail}
          visible={visibleConfirmEmail}
          classes={classes}
          t={t}
        />
        <ConfirmCode
          toggle={toggleVerify}
          resetVerifyCount={resetVerifyCount}
          callbackSubmitVerify={onSubmitVerifyCode}
          visible={visibleModalVerify}
          otpTime={-getDurationSecondFromNow(verifyData?.expired_at)}
          request_id={verifyData?.request_id}
          onResetVerifyCode={onResetVerifyCode}
          classes={classes}
          t={t}
        />
        <ResetPassword
          toggle={toggleResetPassword}
          request_id={verifyData?.request_id}
          callbackSubmitChangePassword={onChangePassword}
          visible={visibleResetPassword}
          classes={classes}
          t={t}
        />
        <SuccessResetPassword
          toggle={onResetCaseChangePassword}
          visible={visibleResetSuccess}
          classes={classes}
          t={t}
        />
      </div>
    </div>
  );
}

export default LoginPage;
