import { put, takeLatest } from "redux-saga/effects";
import { actions, ActionTypes } from "./actions";
import {
  getKYCCustomerAPI,
  getDepartmentCompanyAPI,
  createKYCRequestAPI,
  getCustomerInformationAPI,
  updateKYCStatusAPI,
  updateCustomerAPI,
  resendRequestKYCAPI,
} from "../../services/CustomerRequest";
import { RESPONSE_STATUS } from "../../common/constant";
import { actions as appActions } from "../../pages/layout/actions";

function* getKYCCustomer({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getKYCCustomerAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getKYCCustomerSuccess(response.data));
    } else {
      yield put(actions.getKYCCustomerError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getKYCCustomerError(e));
    yield put(appActions.hideLoading());
  }
}

function* getDepartmentCompany() {
  try {
    const response = yield getDepartmentCompanyAPI();
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getDepartmentCompanySuccess(response.data));
    } else {
      yield put(actions.getDepartmentCompanyError(response));
    }
  } catch (e) {
    yield put(actions.getDepartmentCompanyError(e));
  }
}

function* createKYCRequest({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield createKYCRequestAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.createKYCRequestSuccess(response.data));
    } else {
      yield put(actions.createKYCRequestError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.createKYCRequestError(e));
    yield put(appActions.hideLoading());
  }
}

function* getCustomer({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getCustomerInformationAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getCustomerInformationSuccess(response.data));
    } else {
      yield put(actions.getCustomerInformationError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getCustomerInformationError(e));
    yield put(appActions.hideLoading());
  }
}

function* updateKYCStatus({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield updateKYCStatusAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.updateKYCStatusSuccess(response.data));
    } else {
      yield put(actions.updateKYCStatusError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.updateKYCStatusError(e));
    yield put(appActions.hideLoading());
  }
}

function* updateCustomerInformation({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield updateCustomerAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.updateCustomerInformationSuccess(response.data));
    } else {
      yield put(actions.updateCustomerInformationError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.updateCustomerInformationError(e));
    yield put(appActions.hideLoading());
  }
}

function* resendRequestKYC({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield resendRequestKYCAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.resendRequestKYCSuccess(response.data));
    } else {
      yield put(actions.resendRequestKYCError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.resendRequestKYCError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchKYCCustomer() {
  yield takeLatest(ActionTypes.GET_KYC_CUSTOMER_START, getKYCCustomer);
  yield takeLatest(ActionTypes.GET_DEPARTMENT_COMPANY_START, getDepartmentCompany);
  yield takeLatest(ActionTypes.CREATE_KYC_REQUEST_START, createKYCRequest);
  yield takeLatest(ActionTypes.GET_CUSTOMER_INFORMATION_START, getCustomer);
  yield takeLatest(ActionTypes.UPDATE_KYC_STATUS_START, updateKYCStatus);
  yield takeLatest(ActionTypes.UPDATE_CUSTOMER_INFORMATION_START, updateCustomerInformation);
  yield takeLatest(ActionTypes.RESEND_REQUEST_KYC_START, resendRequestKYC);
}
