import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PhoneInput, { parsePhoneNumber, getCountryCallingCode } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { VN, JP, GB, CN } from "country-flag-icons/react/3x2";
import { MenuItem, Select, Box, FormHelperText, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import "react-phone-number-input/style.css";
import { COUNTRY_OPTIONS, DEFAULT_COUNTRY } from "../../common/constant";

const countries = [
  {
    name: "japan",
    value: "JP",
    flag: <JP title="Japan" className="flag" />,
  },
  {
    name: "vietnam",
    value: "VN",
    flag: <VN title="United States" className="flag" />,
  },
  {
    name: "england",
    value: "GB",
    flag: <GB title="England" className="flag" />,
  },
  {
    name: "china",
    value: "CN",
    flag: <CN title="China" className="flag" />,
  },
];

const countrySelect = makeStyles((theme) => ({
  root: {
    "& .flag": {
      width: 18,
      height: 12,
      marginRight: theme.spacing(1),
    },
  },
}));

const CountrySelect = ({ value, onChange, labels = en, ...rest }) => {
  const classes = countrySelect();

  return (
    <Select
      {...rest}
      value={value}
      variant="outlined"
      onChange={(event) => onChange(event.target.value || undefined)}
      className="country-select"
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        className: classes.root,
        disableScrollLock: true,
      }}
      IconComponent={ExpandMoreIcon}
    >
      <MenuItem value="" className="hide">
        {labels["ZZ"]}
      </MenuItem>
      {countries.map(({ value, flag }) => (
        <MenuItem key={value} value={value}>
          {flag} +{getCountryCallingCode(value)}
        </MenuItem>
      ))}
    </Select>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string),
};

InputPhoneNumber.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const useStylesInputPhoneNumber = makeStyles((theme) => ({
  root: {
    display: "flex",
    border: "1px solid rgba(0, 0, 0, 0.23)",

    "& input": {
      flex: 1,
      padding: theme.spacing(1),
      border: "none",
      backgroundColor: "transparent",
      fontSize: 16,

      "&:focus": {
        outline: "none",
      },

      "&[disabled]": {
        backgroundColor: theme.overrides.colorGallery,
      },
    },

    "& .flag": {
      width: 16,
      height: 11,
      marginRight: theme.spacing(0.5),
    },

    "& .country-select": {
      backgroundColor: theme.overrides.colorWhite,

      "&:focus": {
        outline: "none",
      },
    },

    "& fieldset": {
      border: "none",
      borderRight: "1px solid rgba(0, 0, 0, 0.23)",
    },
  },
  error: {
    color: theme.overrides.colorBurntSienna,
  },
  errorContainer: {
    borderColor: theme.overrides.colorBurntSienna,
  },
}));

export default function InputPhoneNumber({
  field,
  form: { errors, touched },
  message,
  maxLength,
  inputProps,
  regionPhoneNumber,
  isDisabled = false,
  value = "",
  containerClass = "",
  onChange,
  ...props
}) {
  const classes = useStylesInputPhoneNumber();
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const isError = Boolean(message) || (field && touched[field.name] && Boolean(errors[field.name]));

  useEffect(() => {
    const phoneNumber = parsePhoneNumber(value);

    if (phoneNumber) {
      const country = phoneNumber.country;
      setCountry(country);

      // this will fix in issue https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/61
      if (phoneNumber.countryCallingCode === "44") {
        setCountry("GB");
      }
    }
  }, [value]);

  const handleChange = (value) => {
    const { onChange } = props;
    setCountry(value);
    if (onChange) {
      onChange("");
    }
  };

  return (
    <>
      <Box
        className={clsx(`${classes.root} ${containerClass}`, {
          [classes.errorContainer]: isError,
        })}
      >
        <CountrySelect value={country} onChange={handleChange} disabled={isDisabled} />
        <PhoneInput
          {...props}
          value={value}
          disabled={isDisabled}
          international
          onChange={(value) => onChange(value ?? "")}
          country={country}
          countries={COUNTRY_OPTIONS}
          maxLength={13}
        />
      </Box>
      {isError && <FormHelperText className={classes.error}>{errors[field.name]}</FormHelperText>}
    </>
  );
}
