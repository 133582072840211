import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { themeStatusConfig } from "../../utils/themeConfig";

export const useStyles = makeStyles((theme) => ({
  boxContainer: {
    maxWidth: "1140px",
    margin: `${theme.spacing(4)}px auto`,
    background: "white",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(4),
  },
  gridRoot: {
    alignItems: "flex-start",
  },
  kycTitleContent: {
    marginBottom: theme.spacing(3),
  },
  gridInfoContainer: {
    marginBottom: theme.spacing(5),
  },
  reasonContent: {
    whiteSpace: "pre-line",
  },
  photo: {
    maxWidth: "270px",
    height: "auto",
    width: "100%",
  },
  ...themeStatusConfig(theme, 117),
}));

//render kyc status DOM
export const renderStatusKYC = (classes, status, t) => {
  let value = status === "REQUESTING" ? "PENDING" : status;
  return (
    value && (
      <span
        className={clsx(classes.status, {
          [classes.statusVerify]: status === "VERIFYING",
          [classes.statusApproved]: status === "APPROVED",
          [classes.statusRejected]: status === "REJECTED",
          [classes.statusRequesting]: status === "REQUESTING",
        })}
      >
        {t(`search.text_${value?.toLowerCase()}`)}
      </span>
    )
  );
};
