import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

AutocompleteComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.array.isRequired,
  props: PropTypes.object,
  menuClass: PropTypes.string,
};

AutocompleteComponent.defaultProps = {
  field: null,
  form: null,
  props: {},
  menuClass: "",
};

function AutocompleteComponent({
  field,
  form: { touched, errors },
  options,
  menuClass,
  message,
  fullFieldWidth,
  labelField,
  ...props
}) {
  const getOptionLabel = (option) => {
    if (options.find((item) => item.key === option)?.value) {
      return options.find((item) => item.key === option).value;
    }
    return "";
  };

  return (
    <Autocomplete
      id="combo-box"
      options={options.map((option) => option.key)}
      getOptionLabel={(option) => getOptionLabel(option)}
      getOptionDisabled={(option) => option === -1 || !option}
      getOptionSelected={(option, value) => value.value === option.value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={labelField}
          variant="outlined"
          error={Boolean(message) || (touched[field.name] && Boolean(errors[field.name]))}
          helperText={message || (touched[field.name] && errors[field.name])}
        />
      )}
      fullWidth={fullFieldWidth}
      popupIcon={<ExpandMoreIcon />}
      {...props}
    />
  );
}

export default AutocompleteComponent;
