import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { Box, MenuItem, Select, TableFooter, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import clsx from "clsx";

import NODATA from "../../resources/images/no_data.png";
import { getDefaultPageSize } from "../../common/constant";

function EnhancedTableHead(props) {
  const { classes, order, orderBy, headCells = [], onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>
          headCell.isSorter ? (
            <TableCell
              key={headCell.dataIndex}
              align={headCell.align || "left"}
              sortDirection={orderBy === headCell.id ? order : false}
              width={headCell.width || "unset"}
              className={classes.tableCell}
            >
              <TableSortLabel
                active={headCell.isSorter}
                direction={orderBy === headCell.dataIndex ? order : "asc"}
                onClick={createSortHandler(headCell.dataIndex)}
                IconComponent={ImportExportIcon}
              >
                <Typography variant="subtitle2">{headCell.label}</Typography>
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              align={headCell.align || "left"}
              key={headCell.dataIndex}
              width={headCell.width || "unset"}
              className={classes.tableCell}
            >
              <Typography variant="subtitle2">{headCell.label}</Typography>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string.isRequired,
    })
  ).isRequired,
};

EnhancedTableHead.defaultProps = {
  classes: {},
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    wordBreak: "keep-all",
    padding: theme.spacing(1),
  },
  boxNoData: {
    textAlign: "center",
    padding: theme.spacing(4),
    opacity: 0.7,
    textTransform: "uppercase",
  },
  tableFooter: {
    "& .MuiTablePagination-toolbar": {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: 0,
    },

    "& .MuiTablePagination-spacer": {
      display: "none",
    },

    "& p.MuiTypography-root": {
      display: "none",
    },
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  selectRowsPerPage: {
    "& .MuiSelect-root": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  totalResults: {
    marginRight: theme.spacing(1),
  },
  paginationNumber: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { t } = useTranslation();
  const { count, page, rowsPerPage, onChangePage, onChangeRowsPerPage, rowsPerPageOptions } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleGoToPage = (event, page) => {
    onChangePage(event, page - 1);
  };

  const handleChange = (event) => {
    onChangeRowsPerPage(event);
  };

  return (
    <div className={classes.root}>
      <Typography variant="body2" component="span" className={classes.totalResults}>
        {t("component.text_total_results", { number: count })}
      </Typography>
      <Select variant="outlined" className={classes.selectRowsPerPage} value={rowsPerPage} onChange={handleChange}>
        {rowsPerPageOptions.map(({ label, value }, index) => (
          <MenuItem value={value} key={index}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <Box className={classes.paginationNumber}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <Pagination
          hidePrevButton
          hideNextButton
          count={Math.ceil(count / rowsPerPage)}
          page={page + 1}
          siblingCount={1}
          onChange={handleGoToPage}
        />
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const DEFAULT_SIZE_OPTION = getDefaultPageSize(t);

  const {
    order,
    orderBy,
    headCells,
    rows,
    rowPerPage,
    rowPerPageOptions,
    handleFilters,
    rowLength,
    currentPage,
    onChangePage,
    onChangeRowsPerPage,
  } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    handleFilters({
      order: newOrder,
      orderBy: property,
    });
  };

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    onChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow key={index} hover tabIndex={-1}>
                    {headCells.map((item, index) =>
                      index === 0 ? (
                        <TableCell
                          key={item.dataIndex}
                          component="th"
                          scope="row"
                          id={labelId}
                          align={item.align}
                          title={item.ellipsis ? row[item.dataIndex] : ""}
                          style={{
                            maxWidth: item?.width ? `${item.width}px` : "unset",
                            minWidth: item?.minWidth ? `${item.minWidth}px` : "unset",
                          }}
                          className={clsx(classes.tableCell, {
                            [classes.ellipsis]: item.ellipsis,
                          })}
                        >
                          {row[item.dataIndex] || "---"}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={item.dataIndex}
                          align={row.align || item.align}
                          title={item.ellipsis ? row[item.dataIndex] : ""}
                          style={{
                            maxWidth: item?.width ? `${item.width}px` : "unset",
                            minWidth: item?.minWidth ? `${item.minWidth}px` : "unset",
                          }}
                          className={clsx(classes.tableCell, {
                            [classes.ellipsis]: item.ellipsis,
                          })}
                        >
                          {row[item.dataIndex] || "---"}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                );
              })}
              {!rows.length && (
                <TableRow>
                  <TableCell colSpan={12} style={{ borderBottom: "none" }}>
                    <Box className={classes.boxNoData}>
                      <img src={NODATA} alt="no data" />
                      <Typography variant="subtitle1">{t("common.no_data")}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={12}
                  labelRowsPerPage=""
                  rowsPerPageOptions={[]}
                  count={rowLength || 0}
                  rowsPerPage={rowPerPage}
                  page={currentPage}
                  labelDisplayedRows={() => ""}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={(actionProps) => (
                    <TablePaginationActions
                      {...actionProps}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      rowsPerPageOptions={rowPerPageOptions?.length ? rowPerPageOptions : DEFAULT_SIZE_OPTION}
                    />
                  )}
                  className={classes.tableFooter}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
