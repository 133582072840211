export const ActionTypes = {
  POST_USER_LOGIN_START: 'POST_USER_LOGIN_START',
  POST_USER_LOGIN_SUCCESS: 'POST_USER_LOGIN_SUCCESS',
  POST_USER_LOGIN_ERROR: 'POST_USER_LOGIN_ERROR',

  CREATE_OTP_CODE_START: 'CREATE_OTP_CODE_START',
  CREATE_OTP_CODE_SUCCESS: 'CREATE_OTP_CODE_SUCCESS',
  CREATE_OTP_CODE_ERROR: 'CREATE_OTP_CODE_ERROR',

  RESEND_OTP_CODE_START: 'RESEND_OTP_CODE_START',
  RESEND_OTP_CODE_SUCCESS: 'RESEND_OTP_CODE_SUCCESS',
  RESEND_OTP_CODE_ERROR: 'RESEND_OTP_CODE_ERROR',

  VERIFY_OTP_CODE_START: 'VERIFY_OTP_CODE_START',
  VERIFY_OTP_CODE_SUCCESS: 'VERIFY_OTP_CODE_SUCCESS',
  VERIFY_OTP_CODE_ERROR: 'VERIFY_OTP_CODE_ERROR',

  USER_LOGOUT: 'USER_LOGOUT',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',

  CONFIRM_EMAIL_START: 'CONFIRM_EMAIL_START',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_ERROR: 'CONFIRM_EMAIL_ERROR',
};

export const actions = {
  confirmEmailStart: function (body) {
    return {
      type: ActionTypes.CONFIRM_EMAIL_START,
      body,
    };
  },
  userLoginStart: function (params) {
    return {
      type: ActionTypes.POST_USER_LOGIN_START,
      params,
    };
  },
  userLoginSuccess: function (data) {
    return {
      type: ActionTypes.POST_USER_LOGIN_SUCCESS,
      payload: data,
    };
  },
  userLoginError: function (error) {
    return {
      type: ActionTypes.POST_USER_LOGIN_ERROR,
      payload: error,
    };
  },

  createOTPCodeStart: function (params) {
    return {
      type: ActionTypes.CREATE_OTP_CODE_START,
      params,
    };
  },
  createOTPCodeSuccess: function (data) {
    return {
      type: ActionTypes.CREATE_OTP_CODE_SUCCESS,
      payload: data,
    };
  },
  createOTPCodeError: function (error) {
    return {
      type: ActionTypes.CREATE_OTP_CODE_ERROR,
      payload: error,
    };
  },

  resendOTPCodeStart: function (params) {
    return {
      type: ActionTypes.RESEND_OTP_CODE_START,
      params,
    };
  },
  resendOTPCodeSuccess: function (data) {
    return {
      type: ActionTypes.RESEND_OTP_CODE_SUCCESS,
      payload: data,
    };
  },
  resendOTPCodeError: function (error) {
    return {
      type: ActionTypes.RESEND_OTP_CODE_ERROR,
      payload: error,
    };
  },

  verifyOTPCodeStart: function (params) {
    return {
      type: ActionTypes.VERIFY_OTP_CODE_START,
      params,
    };
  },
  verifyOTPCodeSuccess: function (data) {
    return {
      type: ActionTypes.VERIFY_OTP_CODE_SUCCESS,
      payload: data,
    };
  },
  verifyOTPCodeError: function (error) {
    return {
      type: ActionTypes.VERIFY_OTP_CODE_ERROR,
      payload: error,
    };
  },

  userLogout: function (error) {
    return {
      type: ActionTypes.USER_LOGOUT,
      payload: error,
    };
  },

  resetTypeOfAction: function (error) {
    return {
      type: ActionTypes.RESET_TYPE_OF_ACTION,
    };
  },
};
