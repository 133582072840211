export const themeConfig = {
  palette: {
    primary: { main: "#272A31" },
    secondary: {
      main: "#323232",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 641,
      md: 1025,
      lg: 1441,
      xl: 1920,
    },
  },
  typography: {
    fontSize: 16,
    h1: {
      fontSize: 96,
      fontWeight: 300,
      letterSpacing: "-1.5px",
      color: "#181619",
    },
    h2: {
      fontSize: 60,
      fontWeight: 300,
      letterSpacing: "-0.5px",
      wordBreak: "break-word",
      color: "#181619",
    },
    h3: {
      fontSize: 48,
      fontWeight: 400,
      color: "#181619",
    },
    h4: {
      fontSize: 34,
      fontWeight: 400,
      color: "#181619",
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      color: "#181619",

      "@media (max-width:1441px)": {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.18,
      },
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      color: "#181619",
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      color: "#181619",

      "@media (max-width:1441px)": {
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: 0.4,
      },
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      color: "#181619",

      "@media (max-width:1441px)": {
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 0.4,
      },
    },
    body1: {
      fontSize: 16,

      "@media (max-width:1441px)": {
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: 0.4,
      },
    },
    body2: {
      fontSize: 14,
      wordBreak: "break-word",
      color: "#666A74",

      "@media (max-width:1441px)": {
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: 0.4,
      },
    },
    button: {
      fontSize: 14,
      letterSpacing: "1.25px",

      "@media (max-width:1441px)": {
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 1.25,
      },
    },
    caption: {
      fontSize: 12,
      letterSpacing: "1.25px",
    },
    overline: {
      fontSize: 10,
      textTransform: "uppercase",
    },
  },
  shape: {
    borderRadius: 0,
  },
  text: {
    secondary: "#fff",
  },
  overrides: {
    colorActive: "#BC8F58",
    colorBlack: "#000",
    colorWhite: "#fff",
    colorBalticSea: "#181619",
    colorShuttleGray: "#666A74",
    colorAlabaster: "#F8F8F8",
    colorSeance: "#9c27b0",
    colorOrangePeel: "#ff9900",
    colorApple: "#43A047",
    colorAzureRadiance: "#1181F1",
    colorGallery: "#ececec",
    colorDustyGray: "#959595",
    colorBurntSienna: "#EF5350",
    colorMandalay: "#ae7d1f",
    colorConcrete: "#f2f2f2",
  },
};

export const themeStatusConfig = (theme, width = 0) => ({
  status: {
    padding: theme.spacing(1, 2),
    display: "inline-block",
    textTransform: "lowercase",
    width: width ? `${width}px` : "100%",
    textAlign: "center",

    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  statusApproved: {
    backgroundColor: "rgba(67, 160, 71, 0.2)",
    color: theme.overrides.colorApple,
  },
  statusRejected: {
    backgroundColor: "rgba(239, 83, 80, 0.2)",
    color: theme.overrides.colorBurntSienna,
  },
  statusVerify: {
    backgroundColor: "rgba(255, 153, 0, 0.2)",
    color: theme.overrides.colorOrangePeel,
  },
  statusRequesting: {
    backgroundColor: "rgba(255, 153, 0, 0.2)",
    color: theme.overrides.colorOrangePeel,
  },
});

export const themeConfigLink = (theme) => ({
  link: {
    color: theme.overrides.colorAzureRadiance,
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export const themeConfigButtonSave = (theme, iconMargin = false) => ({
  buttonSave: {
    borderRadius: 0,
    background: "linear-gradient(129.66deg, #BB9043 0%, #DDA74F 64.36%, #BB9043 132.87%)",
    color: theme.overrides.colorWhite,

    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
      marginRight: iconMargin ? "5px" : 0,
    },
    "& .MuiIconButton-label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  },
});

export const themConfigModalOverflow = () => ({
  maxHeight: "calc(100vh - 30px)",
  overflow: "auto",
});
