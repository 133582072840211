import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { KEY_UPLOAD, KEY_UPLOAD_HISTORY_DETAIL, PARAMS_FILTER_DEFAULT } from "../../../common/constant";
import { TableComponent } from "../../../components";
import { columns, renderDataKycRequest, useStyles } from "./style";
import { actions } from "./actions";
import { changeTable } from "../../../utils";
import ModalDetailHistory from "../ModalDetailHistory";

function KYCEditHistoryTab() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerCode = useSelector((state) => state.customer.customer.customer_code);
  const listKYCHistory = useSelector((state) => state.kycHistory.listData);
  const dataChange = useSelector((state) => state.kycHistory.dataChange);
  const [visibleDetailKYCHistory, setVisibleDetailKYCHistory] = useState(false);
  const [filters, setFilters] = useState(PARAMS_FILTER_DEFAULT);
  const [requestDetail, setRequestDetail] = useState({});

  const toggleModalViewKYCHistory = () => {
    setVisibleDetailKYCHistory(!visibleDetailKYCHistory);
  };

  //when-click view kyc detail
  const viewKYCDetails = useCallback(
    (requestDetail) => () => {
      setRequestDetail(requestDetail);
      dispatch(actions.getDetailKYCHistoryStart(requestDetail?.kyc_id));
    },
    [dispatch]
  );

  const renderDataChange = (data) => {
    return {
      customerCode: customerCode,
      idData: data?.filter((item) => item.key === "id")[0],
      dataNormal: data?.filter((item) => !KEY_UPLOAD.includes(item.key) && item.key !== "id"),
      dataUpload: data?.filter((item) => KEY_UPLOAD.includes(item.key)),
    };
  };

  useEffect(() => {
    if (dataChange?.length >= 0) {
      setVisibleDetailKYCHistory(true);
    } else {
      setVisibleDetailKYCHistory(false);
    }
  }, [dataChange]);

  //listener change filter
  useEffect(() => {
    const { order, orderBy, page, pageSize } = filters;
    dispatch(
      actions.getListKYCHistoryStart(
        {
          ...filters,
          page: page + 1,
          page_size: pageSize,
          order_type: order,
          order_by: orderBy,
        },
        customerCode
      )
    );
  }, [dispatch, filters]);

  return (
    <>
      <TableComponent
        headCells={columns(t, classes, customerCode)}
        rows={renderDataKycRequest(listKYCHistory?.data, filters, classes, t, viewKYCDetails)}
        currentPage={filters.page}
        rowPerPage={filters.pageSize}
        order={filters.order}
        orderBy={filters.orderBy}
        rowLength={listKYCHistory?.total}
        handleFilters={changeTable(setFilters, filters).handleFilters}
        onChangePage={changeTable(setFilters, filters).handleChangePage}
        onChangeRowsPerPage={changeTable(setFilters, filters).handleChangeRowsPerPage}
      />
      <ModalDetailHistory
        toggleModal={toggleModalViewKYCHistory}
        visible={visibleDetailKYCHistory}
        classes={classes}
        dataChange={renderDataChange(dataChange)}
        requestDetail={requestDetail}
        t={t}
      />
    </>
  );
}

export default memo(KYCEditHistoryTab);
