import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.708 14.924a4.414 4.414 0 01-3.56 1.797h-2.05a1.638 1.638 0 01-1.541-1.068 5.24 5.24 0 01-.829-.324 5.575 5.575 0 01-1.547-1.132C5.218 14.84 3 17.452 3 20.575v.409C3 21.545 3.46 22 4.028 22h15.944C20.54 22 21 21.545 21 20.984v-.409c0-2.413-1.324-4.52-3.292-5.65z"
        fill="#000"
      />
      <path
        d="M7.089 12.102c.441 0 .826-.242 1.025-.6l.016.043.005.013c.467 1.216 1.376 2.235 2.575 2.68a1.64 1.64 0 011.387-.758h2.051c.205 0 .395-.057.562-.149.28-.155.533-.548.663-.757.208-.333.37-.687.513-1.072.08.144.19.27.323.369v.497c0 1.124-.924 2.038-2.06 2.038h-2.052a.698.698 0 00-.702.694c0 .384.314.695.702.695h2.051c1.91 0 3.465-1.537 3.465-3.427v-.497c.285-.212.468-.548.468-.926V8.048c0-.386-.191-.728-.485-.938C17.367 4.254 14.945 2 12 2 9.055 2 6.632 4.255 6.404 7.11c-.294.21-.486.552-.486.938v2.897c0 .64.527 1.157 1.17 1.157zM12 3.389c2.18 0 3.978 1.647 4.189 3.75a1.164 1.164 0 00-.33.402c-.662-1.71-2.14-2.9-3.86-2.9-1.754 0-3.21 1.231-3.856 2.893l-.003.007a1.164 1.164 0 00-.329-.402c.21-2.103 2.009-3.75 4.189-3.75z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
