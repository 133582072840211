import { combineReducers } from "redux";
import AuthReducer from "../pages/login/reducer";
import AppReducer from "../pages/layout/reducer";
import CustomerReducer from "../pages/home/reducer";
import KYCReducer from "../pages/listKYC/reducer";
import EmployeeReducer from "../pages/employee/reducer";
import KYCHistoryReducer from "../pages/customer/KYCeditHistory/reducer";
import DetailKYCHistoryReducer from "../pages/kycHistory/reducer";
import UserDetailReducer from "../pages/profile/reducer";
import SettingReducer from "../pages/setting/reducer";
import ProfileKYCHistoryReducer from "../pages/customer/ProfileEditHistory/reducer";

export default combineReducers({
  authentication: AuthReducer,
  app: AppReducer,
  customer: CustomerReducer,
  kyc: KYCReducer,
  employee: EmployeeReducer,
  kycHistory: KYCHistoryReducer,
  detailKYCHistory: DetailKYCHistoryReducer,
  userDetail: UserDetailReducer,
  setting: SettingReducer,
  profileHistory: ProfileKYCHistoryReducer,
});
