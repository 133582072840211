import { api } from './api';

export const loginAPI = (data) => {
  return api.post('/manage/login', data);
};

export const getDetailAPI = (data) => {
  return api.get('manage/profile/detail', data);
};

export const updateProfileAPI = (data) => {
  return api.put('manage/profile/update', data);
};

export const createVerifyCodeAPI = (body) => {
  return api.post(`/manage/password/otp-request`, body);
};

export const confirmVerifyCodeAPI = (body) => {
  return api.post(`/manage/password/otp-verify`, body);
};

export const changePasswordAPI = (body) => {
  return api.post(`/manage/password/reset-password`, body);
};



