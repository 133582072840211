export const ActionTypes = {
  GET_COMPANY_DETAIL_START: "GET_COMPANY_DETAIL_START",
  GET_COMPANY_DETAIL_SUCCESS: "GET_COMPANY_DETAIL_SUCCESS",
  GET_COMPANY_DETAIL_ERROR: "GET_COMPANY_DETAIL_ERROR",

  UPDATE_COMPANY_SETTING_START: "UPDATE_COMPANY_SETTING_START",
  UPDATE_COMPANY_SETTING_SUCCESS: "UPDATE_COMPANY_SETTING_SUCCESS",
  UPDATE_COMPANY_SETTING_ERROR: "UPDATE_COMPANY_SETTING_ERROR",

  RESET_TYPE_OF_ACTION: "RESET_TYPE_OF_ACTION",
};

export const actions = {
  getCompanyDetailStart: function (params) {
    return {
      type: ActionTypes.GET_COMPANY_DETAIL_START,
      params,
    };
  },
  getCompanyDetailSuccess: function (data) {
    return {
      type: ActionTypes.GET_COMPANY_DETAIL_SUCCESS,
      payload: data,
    };
  },
  getCompanyDetailError: function (error) {
    return {
      type: ActionTypes.GET_COMPANY_DETAIL_ERROR,
      payload: error,
    };
  },

  updateCompanySettingStart: function (body) {
    return {
      type: ActionTypes.UPDATE_COMPANY_SETTING_START,
      params: body,
    };
  },
  updateCompanySettingSuccess: function (data) {
    return {
      type: ActionTypes.UPDATE_COMPANY_SETTING_SUCCESS,
      payload: data,
    };
  },
  updateCompanySettingError: function (error) {
    return {
      type: ActionTypes.UPDATE_COMPANY_SETTING_ERROR,
      payload: error,
    };
  },

  resetTypeOfAction: function () {
    return {
      type: ActionTypes.RESET_TYPE_OF_ACTION,
    };
  },
};
