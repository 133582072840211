import { put, takeLatest } from "redux-saga/effects";
import { actions, ActionTypes } from "./actions";
import { RESPONSE_STATUS } from "../../common/constant";
import { actions as appActions } from "../layout/actions";
import notify from "../../components/Toast";
import {
  getDetailAPI,
  updateProfileAPI,
  createVerifyCodeAPI,
  confirmVerifyCodeAPI,
  changePasswordAPI,
} from "../../services/AuthRequest";
import i18n from "../../i18n/i18n";

function* getUserDetail() {
  try {
    yield put(appActions.showLoading());
    const response = yield getDetailAPI();
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getUserDetailSuccess(response.data));
    } else {
      yield put(actions.getUserDetailError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getUserDetailError(e));
    yield put(appActions.hideLoading());
  }
}

function* updateProfile({ body }) {
  try {
    yield put(appActions.showLoading());
    const response = yield updateProfileAPI(body);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.updateProfileSuccess(response.data));
      notify.success(i18n.t("message.MSG_28"));
    } else {
      yield put(actions.updateProfileError(response));
      notify.error(i18n.t("message.MSG_29"));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.updateProfileError(e));
    yield put(appActions.hideLoading());
    notify.error(i18n.t("message.MSG_29"));
  }
}

function* createVerifyCode({ body }) {
  try {
    yield put(appActions.showLoading());
    const response = yield createVerifyCodeAPI(body);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.createVerifyCodeSuccess(response.data));
    } else {
      yield put(actions.createVerifyCodeError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.createVerifyCodeError(e));
    yield put(appActions.hideLoading());
  }
}

function* confirmVerifyCode({ body }) {
  try {
    yield put(appActions.showLoading());
    const response = yield confirmVerifyCodeAPI(body);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.confirmVerifyCodeSuccess(response.data));
    } else {
      yield put(actions.confirmVerifyCodeError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.confirmVerifyCodeError(e));
    yield put(appActions.hideLoading());
  }
}

function* changePassword({ body }) {
  try {
    yield put(appActions.showLoading());
    const response = yield changePasswordAPI(body);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.changePasswordSuccess());
      notify.success(i18n.t("message.MSG_28"));
    } else {
      yield put(actions.changePasswordError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    notify.error(i18n.t("message.MSG_29"));
    yield put(actions.changePasswordError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchUserDetail() {
  yield takeLatest(ActionTypes.GET_USER_DETAIL_START, getUserDetail);
  yield takeLatest(ActionTypes.UPDATE_PROFILE_START, updateProfile);
  yield takeLatest(ActionTypes.CREATE_VERIFY_CODE_START, createVerifyCode);
  yield takeLatest(ActionTypes.CONFIRM_VERIFY_CODE_START, confirmVerifyCode);
  yield takeLatest(ActionTypes.CHANGE_PASSWORD_START, changePassword);
}
