import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Router, Route, Switch } from "react-router-dom";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { jaJP, enUS, zhCN } from "@material-ui/core/locale";
import moment from "moment";

import { browserHistory } from "./utils/history";
import PrivateLayout, { PublicLayout, SimpleLayout } from "./pages/layout";
import { themeConfig } from "./utils/themeConfig";
import { privateRoutes, publicRoutes, simpleRoutes } from "./Routes";

import "./App.css";
import "./sass/_app.scss";
import NotFound from "./pages/notFound";
import { Backdrop, CircularProgress, CssBaseline } from "@material-ui/core";
import { useSelector } from "react-redux";
import { SnackbarProvider } from "notistack";
import { useTranslation } from "react-i18next";
import { CHART_EMPTY } from "./common/constant";

const language = localStorage.getItem("language");

const getCurrentLanguage = (language) => {
  switch (language) {
    case "en":
      return enUS;
    case "jp":
      return jaJP;
    case "scn":
      return zhCN;
    case "tcn":
      return zhCN;
    default:
      break;
  }
};

let theme = createMuiTheme(themeConfig, getCurrentLanguage(language));

moment.updateLocale(moment.locale(), { invalidDate: CHART_EMPTY });

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 99,
    color: "#fff",
  },
}));

function App() {
  const classes = useStyles();
  const isLoading = useSelector((state) => state.app.loading);

  const { i18n, t } = useTranslation();

  const updateLanguage = (language) => {
    theme = createMuiTheme(themeConfig, getCurrentLanguage(language));
  };

  useEffect(() => {
    i18n.on("languageChanged", updateLanguage);
    return () => {
      i18n.off("languageChanged", updateLanguage);
    };
  }, [i18n]);

  return (
    <div className="App bg-app">
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          preventDuplicate
        >
          <Router history={browserHistory}>
            <Switch>
              <Route path={publicRoutes.map((route) => route.path)} component={PublicLayout} />
              <Route path={simpleRoutes.map((route) => route.path)} component={SimpleLayout} />
              <Route path={privateRoutes(t).map((route) => route.path)} component={PrivateLayout} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default withTranslation()(App);
