import React from "react";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { Button, makeStyles, Divider, InputAdornment, Box } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

import { AccordionComponent, InputComponent, MultiCheckboxComponent } from "../../../components";
import {
  KYC_STATUS_OPTIONS,
  MAX_LENGTH_INPUT,
  getCustomerTypeOptions,
  getDocumentTypeOptions,
  getAddDocumentTypesOptions,
} from "../../../common/constant";
import CustomerModel from "../model";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  boxCheckbox: {
    height: 200,
    overflow: "hidden auto",
    border: `1px solid ${theme.overrides.colorBlack}`,
    padding: theme.spacing(1),
    borderTop: "none",

    "& .MuiTypography-body2": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: 100,
    },

    "& label": {
      width: "100%",
    },
  },
  inputSearch: {
    "& > div": {
      border: `1px solid ${theme.overrides.colorBlack}`,
    },
  },
}));

function SearchComponent(props) {
  const {
    onSearch,
    listDepartments = [],
    onSearchChange,
    listEmployee = [],
    initialValues = {},
    handleResetSearch,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { field } = CustomerModel;
  const { searchText, departmentID, customerType, picID, documents, status, addDocTypes } = field;

  const listKYCStatus = KYC_STATUS_OPTIONS(t);
  const CUSTOMER_TYPE_OPTIONS = getCustomerTypeOptions(t);
  const DOCUMENT_TYPE_OPTIONS = getDocumentTypeOptions(t);
  const ADD_DOCUMENT_TYPE_OPTIONS = getAddDocumentTypesOptions(t);

  const onChange = (setFieldValue, fieldName) => (value) => {
    setFieldValue(fieldName, value);
    onSearchChange({ key: fieldName, value });
  };

  const onSearchText = (setFieldValue) => (event) => {
    const { value, name } = event.target;
    setFieldValue(name, value);
    onSearchChange({ key: name, value });
  };

  const handleSearch = (searchText, value) => {
    return value?.toUpperCase().indexOf(`${searchText.trim()}`.toUpperCase()) > -1;
  };

  const onBlur = (setFieldTouched, fieldName) => () => {
    setFieldTouched(fieldName);
  };

  const onReset = (handleReset) => () => {
    handleResetSearch(handleReset);
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSearch}>
      {({ values, setFieldValue, setFieldTouched, handleReset }) => (
        <Form>
          <Field
            name={searchText}
            placeholder={t("search.placeholder_search_customer")}
            value={values[searchText]}
            onChange={onSearchText(setFieldValue)}
            className={classes.inputSearch}
            maxLength={MAX_LENGTH_INPUT}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            component={InputComponent}
          />
          <AccordionComponent title={t("search.label_kyc_status")}>
            <Field
              name={status}
              options={listKYCStatus}
              fieldValue={values[status]}
              fullFieldWidth
              onChange={onChange(setFieldValue, status)}
              onBlur={onBlur(setFieldTouched, status)}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>
          <Divider light className={classes.divider} />
          <AccordionComponent title={t("search.label_person_in_charge")}>
            <Field
              name="searchPIC"
              placeholder={t("search.placeholder_search_field_employee")}
              className={classes.inputSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              component={InputComponent}
            />
            <Box className={classes.boxCheckbox}>
              <Field
                name={picID}
                options={listEmployee.filter((employee) => handleSearch(values.searchPIC, employee.value))}
                fieldValue={values[picID]}
                fullFieldWidth
                onChange={onChange(setFieldValue, picID)}
                onBlur={onBlur(setFieldTouched, picID)}
                allOption={!values.searchPIC}
                component={MultiCheckboxComponent}
              />
            </Box>
          </AccordionComponent>
          <Divider light className={classes.divider} />
          <AccordionComponent title={t("search.label_department")}>
            <Field
              name={departmentID}
              options={listDepartments}
              fieldValue={values[departmentID]}
              fullFieldWidth
              onChange={onChange(setFieldValue, departmentID)}
              onBlur={onBlur(setFieldTouched, departmentID)}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>

          <Divider light className={classes.divider} />
          <AccordionComponent title={t("search.label_customer_type")}>
            <Field
              name={customerType}
              options={CUSTOMER_TYPE_OPTIONS}
              fieldValue={values[customerType]}
              fullFieldWidth
              onChange={onChange(setFieldValue, customerType)}
              onBlur={onBlur(setFieldTouched, customerType)}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>
          <Divider light className={classes.divider} />
          <AccordionComponent title={t("search.label_identity_document")}>
            <Field
              name={documents}
              options={DOCUMENT_TYPE_OPTIONS}
              fieldValue={values[documents]}
              fullFieldWidth
              onChange={onChange(setFieldValue, documents)}
              onBlur={onBlur(setFieldTouched, documents)}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>
          <Divider light className={classes.divider} />
          <AccordionComponent title={t("search.add_document")}>
            <Field
              name={addDocTypes}
              options={ADD_DOCUMENT_TYPE_OPTIONS}
              fieldValue={values[addDocTypes]}
              fieldName={addDocTypes}
              fullFieldWidth
              onChange={onChange(setFieldValue, addDocTypes)}
              onBlur={onBlur(setFieldTouched, addDocTypes)}
              component={MultiCheckboxComponent}
            />
          </AccordionComponent>

          <Button type="submit" className={`button button-gradient ${classes.button}`} fullWidth>
            {t("component.button_search")}
          </Button>
          <Button
            className={`button button-transparent-border ${classes.button}`}
            fullWidth
            onClick={onReset(handleReset)}
          >
            {t("component.button_reset")}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default SearchComponent;
